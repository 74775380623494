import Centrifuge from 'centrifuge'
import config from '../../config'
import debug from '../../utils/debug'
import { EventEmitter } from 'events'

export const getNamespace = channel => channel.split(':')[0] || ''

const getChannelId = channel => channel.split(':')[1] || ''

class CentrifugeSubscriber extends EventEmitter {
  constructor() {
    super()
    this.centrifuge = new Centrifuge(config.GAME_SERVER_WS, {
      refreshAttempts: 0
    })
    this.connected = false
  }

  connect(token) {
    if (this.connected) {
      throw new Error('failed due second connection attempt')
    }

    this.centrifuge.setToken(token)

    this.centrifuge.on('publish', ({ data, channel }) => {
      debug.api.info('cMESSAGE > data', data)

      try {
        this.emit(`publish:${getNamespace(channel)}`, data)
        this.emit(
          `publish:${getNamespace(channel)}:${data.method}`,
          data.params
        )
        this.emit(
          `publish:${getChannelId(channel)}:${data.method}`,
          data.params
        )
      } catch (e) {
        debug.error('Catched in handleCentrifugeMessage', e)
      }
    })

    this.centrifuge.on('subscribe', ({ channel }) => {
      debug.api.subscribe('subscribe', channel)
      this.emit(`subscribe:${getNamespace(channel)}`, channel)
      this.emit(`subscribe:${getChannelId(channel)}`, channel)
    })

    this.centrifuge.connect()
    this.connected = true
  }

  disconnect() {
    this.centrifuge.disconnect()
    this.connected = false
  }

  republishFromHistory(channel, getRestorationPoint = null) {
    let reinitOffset = null
    this.centrifuge
      .history(channel, { limit: -1 })
      .then(({ publications }) => {
        debug.api.history('history', publications)
        const lastChangeStateIndex = getRestorationPoint
          ? getRestorationPoint(publications)
          : publications
        debug.api.history(
          'history > changeState > lastChangeStateIndex',
          lastChangeStateIndex
        )

        if (
          lastChangeStateIndex !== -1 &&
          reinitOffset !== publications[lastChangeStateIndex].offset
        ) {
          for (let i = lastChangeStateIndex; i < publications.length; i++) {
            this.emit(`publish:${getNamespace(channel)}`, publications[i].data)
            this.emit(
              `publish:${getNamespace(channel)}:${publications[i].data.method}`,
              publications[i].data.params
            )
          }
          reinitOffset = publications[lastChangeStateIndex].offset
        }
      })
      .catch(err => {
        debug.warn('Couldn`t get history', err)
      })
  }
}

export default CentrifugeSubscriber
