<template>
  <div
    v-if="result"
    class="table-row"
    :class="{ 'table-row--highlighted': result.currentTeam }"
    :style="result.currentTeam && backgroundColorStyle(result.color)"
  >
    <div
      v-if="result.place && result.color && result.color.length == 3"
      class="table-row__item table-row__number"
      :style="!result.currentTeam && backgroundColorStyle(result.color)"
    >
      {{ result.place }}
    </div>
    <div class="table-row__item table-row__name-team">
      {{ result.teamName }}
    </div>
    <div class="table-row__item table-row__sum">
      {{ currency }} {{ result.totalSum }}
    </div>
    <div v-if="isInfoConsoleGame" class="table-row__item">
      <a class="table-row__item-link" :href="getTeamUrl()" target="_blank">
        {{ $lui('team-link.open') }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    result: {}
  },

  methods: {
    backgroundColorStyle([red, green, blue]) {
      return {
        'background-color': `rgb(${red}, ${green}, ${blue})`
      }
    },

    getTeamUrl() {
      return `${window.location.origin}/?hash=${this.result.teamInfoConsoleToken}&team_id=${this.result.teamId}&type=4`
    }
  },

  computed: mapGetters({
    isInfoConsoleGame: 'session/isInfoConsoleGame',
    teams: 'session/teams',
    currency: 'session/currency'
  })
}
</script>

<style lang="scss" scoped>
.table-row {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  text-shadow: -1px 1px 0px rgba(255, 255, 255, 0.4);
  border: 2px solid transparent;
  border-radius: 5px;
  margin-left: 4px;
  margin-right: 4px;

  &:nth-child(even) {
    background-color: #e3edf5;
    margin-left: 0px;
    margin-right: 0px;
    padding: 6px 16px;
    border-radius: 0;
  }

  &__item {
    margin-right: auto;
    margin-left: 0;

    &:last-child {
      margin-left: auto;
      margin-right: 20px;
    }
  }

  &__item-link {
    text-decoration: none;
    color: black;
  }

  &--highlighted {
    border-color: black;

    &:nth-child(even) {
      padding: 6px 12px;
      border-radius: 5px;
      margin-left: 4px;
      margin-right: 4px;
    }
  }

  &__number {
    width: 24px;
    height: 24px;
    color: #fff;
    margin-right: 24px;
    border: 2px solid black;
    border-radius: 4px;
    text-align: center;
    line-height: 120%;
    margin-left: 0;
    margin-right: 0;
  }

  &__name-team {
    margin-right: 0;
    margin-left: 23px;
    width: 140px;
  }
}

.current-table-row {
  position: relative;
  display: flex;
  align-items: center;
  width: 441px;
  height: 34px;
  margin: 0 auto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 250%;
  color: #ffffff;
  text-shadow: -1px 1px 0px rgba(0, 0, 0, 0.4);
  background: #27ae60;
  border: 1px solid #000000;
  border-radius: 2px;

  &__number {
    width: 24px;
    height: 24px;
    margin-top: 0;
    margin-bottom: 1px;
    margin-left: 14px;
    color: #ffffff;
    background-image: url(../../assets/frame-number.png);
    background-repeat: no-repeat;
    margin-right: 29px;
  }

  &__current-number {
    position: absolute;
    top: -2px;
    left: 20px;

    .table-row__color-number {
      display: none;
    }
  }
}
</style>
