<template>
  <div class="container">
    <div class="line" v-for="(turn, i) in getNewNews" :key="i">
      <div class="subline"></div>
      <div class="turn-container">
        {{ $lui('news.step') + ' ' + (turn.id + 1) }}
      </div>
      <div class="buttons-container">
        <div
          class="button"
          :class="{ button_available: turn.info && turn.info.length > 0 }"
          @click="handleSelectBlock(turn.id * 2, turn.info)"
        >
          {{ $lui('news.info') }}
        </div>
        <div
          class="button"
          @click="handleSelectBlock(turn.id * 2 + 1, turn.results)"
          :class="{ button_available: turn.results && turn.results.length > 0 }"
        >
          {{ $lui('news.results') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DescPopUp',
  data() {
    return {
      hidden: false,
      available: false
    }
  },

  methods: {
    handleSelectBlock(id, scrollAvailable) {
      if (scrollAvailable) {
        this.$emit('block-select', id)
      }
    }
  },

  computed: mapGetters({
    getNewNews: 'session/getNewNews'
  })
}
</script>

<style lang="scss" scoped>
.buttons-container {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.subline {
  position: absolute;
  bottom: 0;
  height: 1px;
  background: #e0c5a7;
  width: calc(100% - 28px);
}

.line {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0px 14px;
}

.turn-container {
  color: white;
  text-shadow: -1px 1px 0px #000000;
  font-size: 16px;
}

.container {
  position: absolute;
  background: #d5b18a;
  border: 2px solid #000000;
  box-shadow: 0 5px 0 rgba(0, 0, 0, 0.15),
    inset 3px 8px 0 rgba(255, 255, 255, 0.25), inset 0px -24px 0px #b3926c;
  z-index: 10;
}

.button {
  height: 28px;
  margin-left: 11px;
  padding: 3px 10px 3px 10px;
  background: #d5b18a;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  opacity: 0.3;

  &_available {
    cursor: pointer;
    background: #e0c5a7;
    color: #000000;
    opacity: 1;
  }
}
</style>
