<template>
  <div class="quick-jump">
    <div class="round-btn" @click="onClickQuickJump">
      <img class="round-btn__icon" src="../assets/news/QJ-Rund-btn.svg" />
    </div>
    <DescPopUp
      v-if="hiddenStepsTable"
      class="dropdown"
      @block-select="handleSelectBlock"
    />
  </div>
</template>

<script>
import DescPopUp from './DescPopUp.vue'

export default {
  name: 'RoundQuickJump',
  components: {
    DescPopUp
  },

  data() {
    return {
      hiddenDescStepsTable: false,
      hiddenStepsTable: false,
      roundBtn: false
    }
  },

  methods: {
    handleSelectBlock(id) {
      this.hiddenStepsTable = false
      this.$emit('block-select', id)
    },

    onClickQuickJump() {
      this.hiddenStepsTable = !this.hiddenStepsTable
    },

    getNews() {
      return this.getNewNews
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  position: absolute;
  top: 0px;
  left: inherit;
  right: 70px;
}

.round-quick-jump {
  position: fixed;
  z-index: 9;
  cursor: pointer;
}

.quick-jump {
  position: relative;

  &__btn {
    display: block;
    width: 46px;
    height: 46px;
    cursor: pointer;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      width: 46px;
      height: 46px;
    }

    @media screen and (max-width: 1024px) {
      width: 42px;
      height: 42px;
    }
  }

  &__btn-content {
    display: flex;
    justify-content: center;
  }
}

.desc-quick-jump {
  width: 278px;
  @media screen and (max-width: 1551px) {
    width: 14.648vw;
  }
}

.arrow-down {
  margin-left: 8px;
}

.round-btn {
  width: 46px;
  height: 46px;
  position: absolute;
  right: 15px;
  cursor: pointer;

  @media screen and (min-width: 1024px) and (max-width: 1551px) {
    width: 46px;
    height: 46px;
  }

  @media screen and (max-width: 1024px) {
    width: 40px;
    height: 40px;
  }

  &__text {
    margin-right: 0.644vw;
  }

  &__icon {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
</style>
