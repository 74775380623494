import localStorageService from './localStorage'
import jwtDecode from 'jwt-decode'

const TOKENS_STORE_KEY = 'tokens'
const garbageCollectorForTokensStore = () => {
  const result = {}

  const tokensByHash = localStorageService.getObject(TOKENS_STORE_KEY) || {}
  Object.keys(tokensByHash).forEach(hash => {
    try {
      const decoded = jwtDecode(tokensByHash[hash])

      if (Date.now() < decoded.exp * 1000) {
        result[hash] = tokensByHash[hash]
      }
    } catch (e) {
      // do nothing, invalid token ignored
    }
  })

  localStorageService.setObject(TOKENS_STORE_KEY, result)
}

class TokenStorageService {
  getTokenForHash(hash) {
    const tokensByHash = localStorageService.getObject(TOKENS_STORE_KEY)

    garbageCollectorForTokensStore()

    return tokensByHash?.[hash]
  }

  saveTokenForHash(hash, token) {
    const tokensByHash = localStorageService.getObject(TOKENS_STORE_KEY) || {}
    tokensByHash[hash] = token

    localStorageService.setObject(TOKENS_STORE_KEY, tokensByHash)
  }

  dropTokenFromTokensStore(hash) {
    const tokensByHash = localStorageService.getObject(TOKENS_STORE_KEY) || {}
    const result = Object.keys(tokensByHash)
      .filter(key => key !== hash)
      .reduce(
        (acc, cur) => Object.assign(acc, { [cur]: tokensByHash[cur] }),
        {}
      )

    localStorageService.setObject(TOKENS_STORE_KEY, result)
  }
}

export default new TokenStorageService()
