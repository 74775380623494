<template>
  <div class="select">
    <div class="select__wrapper" @click="toggleSelect">
      <div class="select__lang" v-html="currentLocale"></div>
      <div class="select__arrow"></div>
    </div>
    <div ref="langs" class="select__popup" :class="{ show }">
      <p
        v-for="(slug, i) in availableLocales"
        :key="i"
        class="select__popup-lang"
        :class="{ chosen: slug === currentLocale }"
        @click="chooseLang(slug)"
        v-html="slug"
      ></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      show: false
    }
  },

  computed: mapGetters({
    availableLocales: 'localizations/availableLocales',
    currentLocale: 'localizations/currentLocale',
    localeDirection: 'localizations/localeDirection'
  }),

  methods: {
    toggleSelect() {
      this.show = !this.show
    },

    chooseLang(lang) {
      this.$store.dispatch('localizations/setLocale', lang)
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.select {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-image: url('../assets/selectStand.svg');
  position: relative;

  &__wrapper {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__arrow {
    position: absolute;
    width: 23px;
    height: 14px;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
    transition: all 500ms ease;
    background-image: url('../assets/selectArrow.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &:hover {
      transform: translateY(-40%);
    }
  }

  &__lang {
    width: 64px;
    height: 37px;
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    background-image: url('../assets/selectBg.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    font-family: Arial;
    font-weight: Bold;
    font-size: 22px;
    line-height: 1.14;
    padding-top: 6px;
    padding-left: 15px;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  &__popup {
    position: absolute;
    top: 50px;
    opacity: 0;
    visibility: hidden;
    transition: all 500ms ease;
    width: 100px;
    background-color: #b47758;
    border: 1px solid black;
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;

    &-lang {
      display: block;
      font-family: Arial;
      font-weight: 700;
      font-size: 22px;
      line-height: 1.14;
      margin-bottom: 6px;
      color: #000000;
      opacity: 0.5;
      padding: 3px 12px;
      cursor: pointer;

      &:hover {
        background-color: #9e6a50;
      }

      &:first-letter {
        text-transform: capitalize;
      }

      &.chosen {
        opacity: 1;
      }
    }
  }

  &__popup.show {
    opacity: 1;
    visibility: visible;
  }
}
</style>
