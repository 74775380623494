const BY_LINK = '1'
const BY_EMAIL = '2'
const SELF_REGISTRATION = '3'
const INFO_CONSOLE_TEAM = '4'
const INFO_CONSOLE_GAME = '5'

export default {
  BY_LINK,
  BY_EMAIL,
  SELF_REGISTRATION,
  INFO_CONSOLE_TEAM,
  INFO_CONSOLE_GAME
}
