<template>
  <div class="menu">
    <div class="menu__icon-wrap" @contextmenu.prevent>
      <div
        class="menu__line"
        :class="{ selectedStyle: currentSubPage == 'main' }"
        @click="SelectItem('main')"
        @contextmenu.prevent
      >
        <div class="selectedImg"></div>
        <div v-if="notificationPoints.main" class="notification_point"></div>
        <img
          class="menu__icon"
          width="17"
          height="21"
          src="../assets/navigation/industry-icon.png"
          alt=""
        />
        <span class="menu__nav-title" ref="industry">
          {{ $lui('navigation.board') }}
        </span>
      </div>
      <div
        class="menu__line"
        :class="{ selectedStyle: currentSubPage == 'cards' }"
        @click="SelectItem('cards')"
      >
        <div class="selectedImg"></div>
        <div v-if="notificationPoints.cards" class="notification_point"></div>
        <img
          class="menu__icon"
          width="14"
          height="18"
          src="../assets/navigation/cards-icon.png"
          alt=""
        />
        <span class="menu__nav-title" ref="cards">
          {{ $lui('navigation.cards') }}
        </span>
      </div>
      <div
        class="menu__line"
        :class="{ selectedStyle: currentSubPage == 'news' }"
        @click="SelectItem('news')"
      >
        <div class="selectedImg"></div>
        <div v-if="notificationPoints.news" class="notification_point"></div>
        <img
          class="menu__icon"
          width="23"
          height="23"
          src="../assets/navigation/news-icon.png"
          alt=""
        />
        <span class="menu__nav-title" ref="news">
          {{ $lui('navigation.news') }}
        </span>
      </div>
      <div
        class="menu__line"
        :class="{ selectedStyle: currentSubPage == 'stats' }"
        @click="SelectItem('stats')"
      >
        <div class="selectedImg"></div>
        <div v-if="notificationPoints.stats" class="notification_point"></div>
        <img
          class="menu__icon"
          width="19"
          height="17"
          src="../assets/navigation/results-icon.png"
          alt=""
        />
        <span class="menu__nav-title" ref="results">
          {{ $lui('navigation.results') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: mapGetters({
    currentSubPage: 'session/currentSubPage',
    notificationPoints: 'session/notificationPoints'
  }),

  methods: {
    SelectItem(key) {
      this.$store.commit('session/SET_CURRENT_SUB_PAGE', key)
    }
  }
}
</script>

<style lang="scss">
.notification_point {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  background-image: url('../assets/navigation/results-update.svg');
  background-repeat: no-repeat;
  background-size: 16px 16px;
  z-index: 4;
}

.menu {
  @media screen and (max-width: 1024px) {
    transform: translateX(-12px);
  }

  &__line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
    z-index: 2;
    position: relative;

    @media screen and (max-width: 1024px) {
      display: block;
      height: 88px;

      &:not(:last-child) {
        border-bottom: 1px solid black;
      }
    }
  }

  &__link-update:hover {
    position: relative;
    padding-bottom: 28px;
    background-image: url('../assets/navigation/results-icon.png');
    background-size: 47px 44px;
    background-repeat: no-repeat;
    margin-top: -41%;
    margin-left: -10px;
    width: 47px;
    height: 44px;
    text-decoration: none;

    .menu__icon {
      display: none;
    }

    .menu__nav-title {
      color: #fff;

      @media screen and (max-width: 1024px) {
        color: #000000;
      }
    }
  }

  &__icon {
    z-index: 3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    object-position: center;

    @media screen and (max-width: 1024px) {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-left: 0;
      width: 25px;
      height: 27px;
    }
  }

  &__icon-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40px;
    height: 160px;
    background: #4b647c;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: inset 1px 1px 0px 2px #40556a;
    border-radius: 5px;

    @media screen and (max-width: 1024px) {
      width: 112px;
      height: 352px;
      border-radius: 11px;
    }
  }

  &__title-list {
    margin-left: 13px;
    margin-top: 13px;
    list-style: none;
  }

  &__nav-title {
    font-style: normal;
    font-weight: bolder;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #000000;
    transition: color 0.25s ease-in;
    position: absolute;
    top: 50%;
    left: 130%;
    transform: translateY(-50%);

    @media screen and (max-width: 1024px) {
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.selectedStyle .menu__nav-title {
  @media screen and (min-width: 1552px) {
    color: #fff;
    transition: color 0.25s ease-in;
  }
}

.selectedStyle .selectedImg,
.selectedStyle .lastSelectedImg {
  opacity: 1;
  transition: opacity 0.25s ease-in;
}

.selectedImg {
  position: absolute;
  left: -1.5px;
  top: -4px;
  width: 43px;
  height: 44px;
  margin-top: 2px;
  background-image: url(../assets/navigation/active-nav-icon.png);
  background-repeat: no-repeat;
  background-size: 43px 44px;
  opacity: 0;
  transition: opacity 0.25s ease-in;

  @media screen and (max-width: 1024px) {
    width: 117px;
    height: 100px;
    background-size: 117px 100px;
    margin-top: 0;
    background-image: url(../assets/navigation/nav-act-icon-tablet.png);
  }
}

.lastSelectedImg {
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -25%;
    width: 15px;
    height: 15px;
    transform: translate(-50%, -50%);
    background-image: url('../assets/ellipseResult.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    @media screen and (max-width: 1024px) {
      width: 30px;
      height: 30px;
      right: -21%;
    }
  }
}
</style>
