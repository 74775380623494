<template>
  <div class="statistics-block">
    <h2 class="statistics-block-title">
      {{ title }}
    </h2>

    <div
      class="statistics-block-charts"
      :class="{ 'statistics-block-charts--double': doubleMode }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    doubleMode: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss">
.statistics-block {
  min-height: 70vh;
  padding: 30px;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  background: #354657;
  border: 1px solid #000000;
  box-shadow: 2px 2px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.statistics-block-title {
  font-size: 20px;
}

.statistics-block-charts {
  flex: 1;

  &--double {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
