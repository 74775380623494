import LOCALES, {
  SAVED_LOCALE_KEY,
  DEFAULT_LOCALE
} from '../../constants/locales'
import { getFromObjectByKey, renderParameterizedString } from '../../utils/base'
import rtlDetect from 'rtl-detect'
import debug from './../../utils/debug'

export default {
  namespaced: true,

  state: {
    uiLocale: DEFAULT_LOCALE.slug,
    contentLocale: DEFAULT_LOCALE.slug,
    availableLocales: [DEFAULT_LOCALE.slug],
    localeDirection: 'ltr'
  },
  getters: {
    availableLocales(state) {
      return state.availableLocales
    },
    currentLocale(state) {
      return state.contentLocale
    },
    localeDirection(state) {
      return state.localeDirection
    },
    localizeContent:
      ({ contentLocale }) =>
      content => {
        if (!content) {
          return ''
        }

        if (typeof content === 'string') {
          debug.localizations.warn(
            'Expected localized content but get string',
            content
          )
          return content
        }

        const result = content[contentLocale]
        if (typeof result !== 'string') {
          debug.localizations.error(
            'Broken content',
            content,
            contentLocale,
            result
          )
          return '' /// fixme? maybe show undefined
        }

        return result
      },

    localizeUI:
      ({ uiLocale }) =>
      (key, parameters) => {
        const currentLocale = LOCALES[uiLocale]
        let textTemplate

        if (
          currentLocale &&
          currentLocale.uiTexts &&
          uiLocale !== DEFAULT_LOCALE
        ) {
          /// fixme maybe drop currentLocale check, cause its guaranteed at SET_LOCALE
          textTemplate = getFromObjectByKey(currentLocale.uiTexts, key)
          if (!textTemplate) {
            debug.localizations.warn(
              `Unknown $lui key ${key} for ${currentLocale.slug} locale`
            )
            textTemplate = getFromObjectByKey(DEFAULT_LOCALE.uiTexts, key)
          }
        } else {
          textTemplate = getFromObjectByKey(DEFAULT_LOCALE.uiTexts, key)
        }

        if (!textTemplate) {
          debug.localizations.error(
            `Unknown $lui key ${key} for ${DEFAULT_LOCALE.slug} locale`
          )
          return ''
        }

        return renderParameterizedString(textTemplate, parameters)
      },

    checkUILocalizationForKey: () => key => {
      const textTemplate = getFromObjectByKey(DEFAULT_LOCALE.uiTexts, key)
      return !!textTemplate
    }
  },

  actions: {
    setAvailableLocales({ commit }, value) {
      commit('SET_AVAILABLE_LOCALES', value)
    },

    setLocale({ commit }, value) {
      commit('SET_LOCALE', value)
    }
  },
  mutations: {
    SET_LOCALE(state, value) {
      debug.localizations.storage('SET_LOCALE', value)
      if (!state.availableLocales.includes(value)) {
        debug.localizations.error(
          `try to set unavailable locale ${value}, ignore`
        )
        return
      }

      state.contentLocale = value

      if (LOCALES[value] && LOCALES[value].uiTexts) {
        state.uiLocale = value
      } else {
        debug.localizations.warn(
          `unknown UI locale ${value}, swith UI locale do default - ${DEFAULT_LOCALE.slug}`
        )
        state.uiLocale = DEFAULT_LOCALE.slug
      }

      state.localeDirection = rtlDetect.getLangDir(value)
      localStorage.setItem(SAVED_LOCALE_KEY, value)
    },

    SET_AVAILABLE_LOCALES(state, value) {
      debug.localizations.storage('SET_AVAILABLE_LOCALES', value)
      state.availableLocales = value
    }
  }
}
