<template>
  <div class="results" :style="getScaleStyle()">
    <div class="results__content" dir="ltr">
      <h2 class="results__title" v-html="$lui('navigation.results')"></h2>
      <ResultComponent ref="resultComponent" />
    </div>
  </div>
</template>

<script>
import ResultComponent from './ResultBlockComponent.vue'
import scaleTable from '../../mixins/scaleTable'

export default {
  components: {
    ResultComponent
  },

  mixins: [scaleTable],
  data() {
    return {
      scaleTable: 1,
      scaleTableByXPercent: 43
    }
  },

  methods: {
    rebuildDiagram() {
      this.$refs.resultComponent.rebuildDiagram()
    },

    setBlockScale() {
      let heightFooterAndHeader = 70 + 67
      let neededHeight = 750

      let resultHeight = window.innerHeight - heightFooterAndHeader
      let resultScaleH = resultHeight / neededHeight

      let indentX = 0.99
      let neededWidth = 1210

      let resultWidth = window.innerWidth * indentX
      let resultScaleW = resultWidth / neededWidth

      this.scaleTable = Math.min(resultScaleH, resultScaleW)
    }
  }
}
</script>

<style lang="scss">
.results {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 190%;
    color: #ffffff;
    text-shadow: -1px 1px 0px #000000;
  }
}
</style>
