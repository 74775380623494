<template>
  <div>
    <div class="game-audio-chat">
      <AudioChatComponent
        v-show="expanded"
        v-slot="{ seed, peers }"
        :endpoint="endpoint"
        :sid="room"
        :mute="muted"
        :enabled="!!(enabled && room)"
        :uid="dataCurrentSession.playerId"
        :as-subscriber="!canPublish"
        :token="token"
        :use-persistent-tags="!singleTeamChatMode"
        :use-relay="useRelay"
        :ice-servers="iceServers"
        @publish-error="handlePublishError"
        @token-error="handleTokenError"
        @connected="handleConnected"
        @disconnected="handleDisconnected"
      >
        <template v-if="enabled && room">
          <div v-if="singleTeamChatMode" class="audio-chat">
            <div class="audio-chat__items-wrapper">
              <div
                class="audio-chat__item"
                :class="{
                  'audio-chat__item--captain': checkIfCaptain(seed.id)
                }"
              >
                <div class="audio-chat__captain-icon"></div>
                <div
                  class="audio-chat__item-name audio-chat__item-name--current"
                  :title="dataCurrentSession.currentPlayerNickname"
                  :class="{ 'audio-chat__item--speaker': seed.speaking }"
                >
                  {{ dataCurrentSession.currentPlayerNickname }}
                </div>
              </div>

              <div
                v-for="item in getPlayerListFromPeers(peers)"
                :key="item.id"
                class="audio-chat__item"
                :class="{
                  'audio-chat__item--captain': item.captain,
                  'audio-chat__item--trainer': item.trainer,
                  'audio-chat__item--assistant': item.assistant,
                  'audio-chat__item--speaker': item.speaking,
                  'audio-chat__item--muted': item.muted
                }"
              >
                <div class="audio-chat__captain-icon"></div>
                <div class="audio-chat__item-name" :title="item.name">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>

          <div v-else class="audio-chat__role-items">
            <div
              v-for="item in getRoleItemsFromPeers(peers)"
              :key="item.id"
              class="audio-chat__role-item"
              :class="{
                'audio-chat__role-item--trainer': item.trainer,
                'audio-chat__role-item--assistant': item.assistant
              }"
            >
              <div class="audio-chat__role-item-container">
                <div class="audio-chat__role-item-icon"></div>
                <div>{{ item.name }}</div>
              </div>
              <div
                class="audio-chat__role-item-microphone-icon"
                :class="{
                  'audio-chat__role-item-microphone-icon--active': item.speaking
                }"
              ></div>
            </div>
          </div>
        </template>
      </AudioChatComponent>
    </div>

    <div
      v-if="room && enabled"
      class="audio-chat__btn"
      :class="{
        'audio-chat__btn--expanded': expanded
      }"
      @click="expanded = !expanded"
    >
      <div class="audio-chat__btn_border">
        <div
          v-if="expanded"
          class="audio-chat__btn_arrow audio-chat__btn_arrow_right"
        ></div>
        <div v-if="!expanded">
          {{ $lui('chat.show') }}
        </div>
        <div
          v-if="!expanded"
          class="audio-chat__btn_arrow audio-chat__btn_arrow_left"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AudioChatComponent from 'kips-audio-chat/src/components/AudioChat'
import config from '../config'
import { arrayFindLastIndex } from '../utils/base'

const CHAT_SWITCH = 'chat_admin.switchActiveChannel'
const TRAINER_UID = 'TRAINER'
const ASSISTANT_UID = 'ASSISTANT'
const TRAINER_DISPLAY_NAME = 'Trainer'
const ASSISTANT_DISPLAY_NAME = 'Assistant'

export default {
  name: 'AudioChat',

  components: {
    AudioChatComponent
  },

  inject: ['centrifugeSubscriber', 'chatSubscriber', 'gameServerClient'],

  data() {
    return {
      endpoint: config.ION_ENDPOINT,
      iceServers: config.ION_ICE_SERVERS,
      gameToken: '',
      teamToken: '',
      expanded: false
    }
  },

  computed: {
    ...mapGetters({
      dataCurrentSession: 'session/dataCurrentSession',
      team: 'session/team',
      room: 'audioChat/currentRoom',
      canPublish: 'audioChat/canPublish',
      muted: 'audioChat/muted',
      enabled: 'audioChat/enabled',
      useRelay: 'audioChat/useRelay'
    }),

    players() {
      return Array.isArray(this.team.players)
        ? this.team.players
        : Object.values(this.team.players)
    },

    singleTeamChatMode() {
      return this.room === this.team.id
    },

    token() {
      return this.singleTeamChatMode ? this.teamToken : this.gameToken
    }
  },

  created() {
    this.connect()
  },

  methods: {
    ...mapActions({
      addGameId: 'session/addGameId'
    }),

    ...mapActions('audioChat', ['joinTeamChat', 'joinGameChat']),

    joinTeam() {
      this.joinTeamChat()
    },

    joinAll() {
      this.joinGameChat()
    },

    async connect(retry) {
      try {
        const {
          data: {
            jwt_console_chat: { token, game_channel_id: gameChannel },
            jwt_game_audio_chat: gameToken,
            jwt_team_audio_chat: teamToken
          } = {}
        } = await this.gameServerClient.connectToChat()
        this.gameToken = gameToken
        this.teamToken = teamToken
        this.addGameId(gameChannel)

        if (retry) {
          this.singleTeamChatMode ? this.joinTeamChat() : this.joinGameChat()
        } else {
          this.joinTeamChat()
        }

        this.chatSubscriber
          .on(`publish:${gameChannel}:${CHAT_SWITCH}`, ([chatType]) =>
            chatType === 'game' ? this.joinGameChat() : this.joinTeamChat()
          )
          .on(`subscribe:${gameChannel}`, channel =>
            this.chatSubscriber.republishFromHistory(channel, publications =>
              arrayFindLastIndex(
                publications,
                p => p.data.method === CHAT_SWITCH
              )
            )
          )
          .connect(token)
      } catch (e) {
        return
      }
    },

    checkIfCaptain(id) {
      const player = this.players.find(player => player.id === id)
      return player && player.is_captain
    },

    checkIfTrainer(id) {
      return id?.startsWith(TRAINER_UID)
    },

    checkIfAssistant(id) {
      return id?.startsWith(ASSISTANT_UID)
    },

    getTrainerItem(peers) {
      return peers.find(peer => this.checkIfTrainer(peer.id))
    },

    getPlayerListFromPeers(peers) {
      const items = peers.map(peer => {
        const trainer = this.checkIfTrainer(peer.id)
        if (trainer) {
          return {
            name: TRAINER_DISPLAY_NAME,
            captain: false,
            trainer,
            speaking: peer.speaking,
            muted: peer.muted
          }
        }

        const assistant = this.checkIfAssistant(peer.id)
        if (assistant) {
          return {
            name: ASSISTANT_DISPLAY_NAME,
            captain: false,
            trainer: false,
            assistant,
            speaking: peer.speaking,
            muted: peer.muted
          }
        }

        const player = this.players.find(({ id }) => id === peer.id)
        return {
          name: player?.nickname,
          captain: this.checkIfCaptain(player?.id),
          trainer: false,
          assistant: false,
          speaking: peer.speaking,
          muted: peer.muted
        }
      })

      // trainer first, then assistants, then players
      return items.sort(
        (a, b) => b.trainer - a.trainer || b.assistant - a.assistant
      )
    },

    getRoleItemsFromPeers(peers) {
      return [...this.getPlayerListFromPeers(peers)].filter(
        item => item.assistant || item.trainer
      )
    },

    handleTokenError() {
      this.chatSubscriber.disconnect()
      this.connect(true)
    },

    handlePublishError() {
      this.$store.commit('audioChat/SET_CAN_PUBLISH', false)
      this.$store.commit('audioChat/SET_MUTED', false)
    },

    handleDisconnected() {
      this.expanded = false
      this.$store.commit('audioChat/SET_CONNECTED', false)
    },

    handleConnected() {
      this.expanded = true
      this.$store.commit('audioChat/SET_CONNECTED', true)
    }
  }
}
</script>

<style lang="scss">
.audio-chat {
  background: rgba(61, 83, 113, 0.7);
  border-radius: 6px;
  position: fixed;
  z-index: 280;
  bottom: 140px;
  left: 16px;
  font-size: 14px;
  color: white;
  max-width: 150px;
  min-width: 100px;
  overflow: hidden;
  display: flex;
  max-height: calc(100% - 450px);

  @media screen and (max-width: 1024px) {
    left: 4px;
    font-size: 13px;
    max-width: 130px;
    min-width: 90px;
    max-height: calc(100% - 628px);
  }
}

.audio-chat__items-wrapper {
  overflow-y: auto;
  width: 100%;
  padding: 8px;

  scrollbar-color: #5d7083 #465772;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #465772;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #5d7083;
  }
}

.audio-chat__item {
  display: flex;
  align-items: center;
  transition: color 75ms ease-in-out;
}

.audio-chat__item--trainer {
  color: #6df18a;
}

.audio-chat__item--assistant {
  color: rgb(83, 155, 255);
}

.audio-chat__item--speaker {
  color: rgb(248, 53, 53);
}

.audio-chat__item--muted {
  color: black;
}

.audio-chat__item--captain .audio-chat__captain-icon {
  visibility: visible;
}

.audio-chat__captain-icon {
  visibility: hidden;
  width: 11px;
  flex-shrink: 0;
  height: 8px;
  margin-right: 6px;
  background-image: url(../assets/audio-chat-captain.svg);
}

.audio-chat__role-items {
  position: fixed;
  left: 16px;
  bottom: 140px;
  width: 130px;
  z-index: 200;

  @media screen and (max-width: 1024px) {
    left: 4px;
    width: 112px;
  }
}

.audio-chat__role-item {
  border-radius: 6px;
  color: white;
  width: 120px;
  padding: 8px 10px;
  margin-bottom: 8px;
}

.audio-chat__role-item--trainer {
  background: #5c8665;
}

.audio-chat__role-item--assistant {
  background: rgba(82, 121, 175, 0.7);
}

.audio-chat__role-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
}

.audio-chat__role-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.audio-chat__role-item-icon {
  width: 11px;
  height: 10px;
  margin-bottom: 2px;
  margin-right: 6px;
  background-image: url(../assets/audio-chat-trainer.svg);
}

.audio-chat__item-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  opacity: 0.9;
}

.audio-chat__item-name--current {
  font-weight: 900;
  opacity: 1;
}

.audio-chat__role-item-microphone-icon {
  width: 10px;
  height: 12px;
  margin-left: 8px;
  transition: opacity 75ms ease-in-out;
  background-image: url(../assets/audio-chat-trainer-microphone.svg);
  opacity: 0.3;
}

.audio-chat__role-item-microphone-icon--active {
  opacity: 1;
}

.audio-chat__btn {
  position: fixed;
  z-index: 280;
  width: 120px;
  height: 55px;
  bottom: 70px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140%;
  color: black;
  transition: opacity 0.5s ease;
  background: #e2b38a;
  border: 2px solid #000000;
  border-radius: 50px;
  padding: 3px;
  opacity: 1;
  cursor: pointer;
  left: 16px;

  &--expanded {
    width: 55px;

    .audio-chat__btn_arrow_right {
      margin-left: 4px;
    }
  }

  &:hover,
  &:focus {
    opacity: 0.8;
    transition: opacity 0.5s ease;
  }

  &_arrow {
    width: 11px;
    height: 15px;

    &_left {
      background: url(../assets/audio-chat-arrow-right.svg) no-repeat center;
      margin-left: 6px;
    }

    &_right {
      background: url(../assets/audio-chat-arrow-left.svg) no-repeat center;
      margin-right: 6px;
    }
  }

  &_border {
    background: #e2b38a;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 1px solid #000000;
    border-radius: 50px;
    background: #e2b38a;
    box-shadow: inset 5px 6px 0px #e9c6a7, inset -5px -5px 0px #c1946b;
  }
}
</style>
