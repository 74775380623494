<template>
  <div class="green-cards">
    <div
      class="green-cards__container"
      v-for="(item, i) in getPlayedCards[2]"
      v-bind:key="i"
    >
      <Card :from="'table'" :item="item" />
    </div>
  </div>
</template>

<script>
import Card from './GameCard.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Card
  },

  computed: mapGetters({
    getPlayedCards: 'cards/UICardsUsed'
  })
}
</script>

<style lang="scss" scoped>
.green-cards {
  padding-right: 64px;
  display: flex;
  justify-content: space-between;

  &__container {
    width: 85px;
    height: 121px;
  }
}
</style>
