export const BRANDING_TYPES = {
  DEFAULT: 'DEFAULT',
  CO_BRANDING: 'CO_BRANDING',
  WHITE_LABEL: 'WHITE_LABEL'
}

export default {
  1: 'DEFAULT',
  2: 'CO_BRANDING',
  3: 'WHITE_LABEL'
}
