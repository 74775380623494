import lodashTemplate from 'lodash.template'

/** example: [[ VARIABLE ]] */
const DELIMITERS_REGEX = /\[\[([\s\S]+?)\]\]/g

const INTERPOLATION_VARIABLES = {
  YEAR: new Date().getFullYear()
}

export const interpolateText = text => {
  const options = {
    interpolate: DELIMITERS_REGEX
  }

  return lodashTemplate(text, options)(INTERPOLATION_VARIABLES)
}
