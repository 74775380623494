import debug from 'debug'

const debugApp = debug('app')
const debugApi = debug('api')
const debugGame = debug('game')
const debugTable = debug('table')
const debugSlider = debug('slider')
const debugLocalizations = debug('localizations')
const debugSession = debug('session')

export default {
  app: {
    info: debugApp.extend('info')
  },
  api: {
    info: debugApi.extend('info'),
    subscribe: debugApi.extend('subscribe'),
    history: debugApi.extend('history'),
    team: {
      vote: debugApi.extend('team:vote'),
      move: debugApi.extend('team:move'),
      changeName: debugApi.extend('team:changeName')
    }
  },
  game: {
    action: debugGame.extend('action'),
    info: debugGame.extend('info'),
    storage: debugGame.extend('storage')
  },
  table: {
    data: debugTable.extend('data')
  },
  slider: {
    info: debugSlider.extend('info')
  },
  localizations: {
    warn: debugLocalizations.extend('warn'),
    error: debugLocalizations.extend('error'),
    storage: debugLocalizations.extend('storage')
  },
  session: {
    info: debugSession.extend('info'),
    warn: debugSession.extend('warn'),
    update: debugSession.extend('update'),
    storage: debugSession.extend('storage')
  },
  system: debugApi.extend('system'),
  info: debug('info'),
  error: debug('error'),
  warn: debug('warn')
}
