var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"game-card__wrapper",class:{ 'game-card__wrapper--selected': _vm.item.hide === true }},[(_vm.item)?_c('div',{staticClass:"game-card",class:{
      locked: _vm.item.block,
      'game-card-shadow': _vm.item.id,
      clickable: _vm.item.id,
      'card-theme': _vm.item.type != 'one_time',
      'info-card': _vm.item.type === 'info',
      'card-full': _vm.inZoom === true || _vm.inZoomInHand === true,
      'card-full__in-hand': _vm.inZoomInHand === true,
      'from-table': _vm.from === 'table',
      'from-table__next-empty': _vm.nextCardInTableIsEmpty,
      'close-green': _vm.item.cardEmpty && _vm.item.cardColor == 'green',
      'close-lightGreen': _vm.item.cardEmpty && _vm.item.cardColor == 'yellow',
      'empty-card': false,
      'decisions-card': _vm.newsCard
    },on:{"click":function($event){return _vm.openCardFull(_vm.item)}}},[(_vm.item.new)?_c('div',{staticClass:"new-mark"},[_c('img',{attrs:{"src":require("../../assets/cards/mark-new.svg")}})]):_vm._e(),_c('div',{staticClass:"game-card__info"},[(_vm.item.cardNumber)?_c('p',{staticClass:"game-card__info-num"},[_c('NumCard',{class:{ 'decisions-card__num': _vm.newsCard },attrs:{"item":_vm.item}})],1):_vm._e(),_c('div',{staticClass:"game-card__info-inner-wrapper"},[_c('div',[(_vm.item.title)?_c('h2',{staticClass:"game-card__info-header"},[_vm._v(" "+_vm._s(_vm.localizedTitle)+" ")]):_vm._e(),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.cardOpened),expression:"cardOpened"}],staticClass:"game-card__info-desc",style:({ fontSize: _vm.cardDescriptionTextSize })},[_vm._v(" "+_vm._s(_vm.localizedDescription)+" ")])]),(_vm.imageVisible)?_c('div',{staticClass:"game-card__info-image"},[_c('img',{attrs:{"src":_vm.baseUrl + '/' + _vm.item.image,"draggable":"false","dir":"ltr"},on:{"dragstart":_vm.returnFalse}})]):_vm._e()]),(_vm.item.cost || _vm.item.timeUnit)?_c('div',{staticClass:"game-card__info-cost"},[_c('p',{staticClass:"info-cost"},[_vm._v(" "+_vm._s(_vm.item.cost)+" ")]),_c('p',{staticClass:"info-timeCost"},[_vm._v(" "+_vm._s(_vm.item.timeUnit)+" ")])]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }