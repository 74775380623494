import axios from 'axios'
import debounce from 'lodash.debounce'
import config from '../config'

function RemoteClient(baseURL) {
  const axiosClient = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json'
    }
  })

  let token = null
  const getToken = () => token
  const setToken = _token => {
    token = _token
  }

  const getHeadersWithToken = () => ({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token
  })
  //////////////////////////////////////////////////////////////////////////////

  const connect = async body => {
    return await axiosClient.post('/v1/console/connect', body)
  }

  const reconnect = async () => {
    return await axiosClient.post('/v1/console/reconnect', null, {
      headers: getHeadersWithToken()
    })
  }

  const setTeamName = async name => {
    return await axiosClient.put(
      '/v1/console/team',
      { name },
      { headers: getHeadersWithToken() }
    )
  }

  const setPlayerNickname = nickname => {
    return axiosClient.put(
      '/v1/console/team/player',
      { nickname },
      { headers: getHeadersWithToken() }
    )
  }

  const playCards = async (cards, choiceIsMade) => {
    return await axiosClient.post(
      '/v1/console/game/play',
      {
        cards,
        choice_is_made: choiceIsMade
      },
      { headers: getHeadersWithToken() }
    )
  }

  const sendVote = async (x, y) => {
    return await axiosClient.put(
      '/v1/console/game/vote',
      { vote: { x, y } },
      { headers: getHeadersWithToken() }
    )
  }

  const connectToChat = () => {
    return axiosClient.post(
      '/v1/chat/console/connect',
      {},
      { headers: getHeadersWithToken() }
    )
  }

  return {
    getToken,
    setToken,
    connect,
    reconnect,
    setTeamName,
    setPlayerNickname,
    playCards,
    connectToChat,
    playCardsDebounced: debounce(playCards, 600),
    sendVote: debounce(sendVote, 600)
  }
}

export default RemoteClient(config.GAME_SERVER_HTTP)
