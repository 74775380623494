<template>
  <span>{{ titleText }}</span>
</template>

<script>
import { mapGetters } from 'vuex'
import { BRANDING_TYPES } from '../constants/brandingType'

export default {
  computed: {
    ...mapGetters({
      branding: 'session/branding'
    }),

    titleText() {
      const titleByBrandingType = {
        [BRANDING_TYPES.DEFAULT]: this.$lui('intro-screen.self-name'),
        [BRANDING_TYPES.CO_BRANDING]: this.$lc(this.branding.company_title),
        [BRANDING_TYPES.WHITE_LABEL]: null
      }

      return titleByBrandingType[this.branding.type]
    }
  }
}
</script>
