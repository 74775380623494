import localStorageService from './localStorage'

const NOTIFICATIONS_PIN_RESET_LOG = 'notificstions_pin_reset_log'

export const clear = () => {
  localStorageService.removeItem(NOTIFICATIONS_PIN_RESET_LOG)
}

let state = {}

export const initializeFromCache = () => {
  state = localStorageService.getObject(NOTIFICATIONS_PIN_RESET_LOG) || {}
}

const keyForStore = (subPage, step, gameState) =>
  `${subPage}_${step}_${gameState}`

export const resetPin = (subPage, step, gameState) => {
  const key = keyForStore(subPage, step, gameState)

  state[key] = 1
  localStorageService.setObject(NOTIFICATIONS_PIN_RESET_LOG, state)
}

export const wasPinResetted = (subPage, step, gameState) => {
  const key = keyForStore(subPage, step, gameState)
  return !!state[key]
}
