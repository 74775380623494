<template>
  <div class="prestart-game">
    <div class="prestart-game__content-container" :style="getScaleStyle()">
      <div class="prestart-game__content">
        <div
          class="prestart-game__info"
          :class="{ [`prestart-game__info_${httpCodeOfError}`]: true }"
        >
          <div>{{ $lui(errorUILocalizationKey) }}</div>
        </div>
        <div v-if="showRefresh" class="refresh-btn" @click="refresh">
          {{ $lui('connection-button.refresh') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import scaleTable from '../mixins/scaleTable'

export default {
  name: 'ConnectionErrorScreen',
  mixins: [scaleTable],

  props: {
    httpCodeOfError: { type: [Number, String] },
    // eslint-disable-next-line vue/no-boolean-default
    showRefresh: { type: Boolean, default: true }
  },

  computed: {
    errorUILocalizationKey() {
      const tryKey = `connection-errors.${this.httpCodeOfError}`
      if (
        this.$store.getters['localizations/checkUILocalizationForKey'](tryKey)
      ) {
        return tryKey
      }

      return 'connection-errors.default'
    }
  },

  methods: {
    setBlockScale() {
      let resultHeight = (window.innerHeight - 70 - 67) * 0.97
      let resultScaleH = resultHeight / 750

      let resultWidth = window.innerWidth * 0.85
      let resultScaleW = resultWidth / 550

      this.scaleTable = Math.min(resultScaleH, resultScaleW)
    },

    refresh() {
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.refresh-btn {
  width: 179px;
  height: 54px;
  padding: 0;
  padding-bottom: 5px;
  border: none;
  background: transparent;
  background-image: url(../assets/start-game-btn.png);
  background-repeat: no-repeat;
  background-size: cover;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 48px;
  opacity: 0.8;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  position: relative;
  padding: initial;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 50px;

  &:hover {
    opacity: 1;
  }
}
.prestart-game {
  width: 100%;
  height: 100vh;
  background-color: #19232c;
  z-index: 400;
  &__content-container {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 300px;
  }
  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 300px;
    background-image: url(../assets/pre-start-game-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 111;
  }
  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 335px;
    padding: 5px 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    vertical-align: middle;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 0 1px 0 #000000;
    background-image: url(../assets/prestart-info-bg-center-short.png);
    background-size: 100%;

    &:before {
      display: block;
      position: absolute;
      content: '';
      width: 100%;
      height: 20px;
      z-index: -1;
      background-image: url(../assets/prestart-info-bg.png);
      background-repeat: no-repeat;
      background-size: 100%;
      top: -10px;
    }

    &:after {
      display: block;
      position: absolute;
      content: '';
      width: 100%;
      height: 20px;
      z-index: -1;
      background-image: url(../assets/prestart-info-bg.png);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 0% 0%;
      bottom: -10px;
      transform: rotate(180deg) rotateY(180deg);
    }

    &_204 {
      top: 49%;
    }
  }
}
</style>
