export default {
  data: () => ({
    scaleTable: 1,
    scaleTableByXPercent: 50,
    scaleTableByYPercent: 50
  }),

  methods: {
    getScaleStyle() {
      const { scaleTable, scaleTableByXPercent, scaleTableByYPercent } = this

      return {
        transform: `scale(${scaleTable}) translate(-${
          scaleTableByXPercent / scaleTable
        }%, -${scaleTableByYPercent / scaleTable}%)`
      }
    },

    setBlockScale() {
      throw new Error('Method setBlockScale must be implemented')
    }
  },

  created() {
    window.addEventListener('resize', this.setBlockScale)
  },

  mounted() {
    this.setBlockScale()
  },

  destroyed() {
    window.removeEventListener('resize', this.setBlockScale)
  }
}
