var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"game-audio-chat"},[_c('AudioChatComponent',{directives:[{name:"show",rawName:"v-show",value:(_vm.expanded),expression:"expanded"}],attrs:{"endpoint":_vm.endpoint,"sid":_vm.room,"mute":_vm.muted,"enabled":!!(_vm.enabled && _vm.room),"uid":_vm.dataCurrentSession.playerId,"as-subscriber":!_vm.canPublish,"token":_vm.token,"use-persistent-tags":!_vm.singleTeamChatMode,"use-relay":_vm.useRelay,"ice-servers":_vm.iceServers},on:{"publish-error":_vm.handlePublishError,"token-error":_vm.handleTokenError,"connected":_vm.handleConnected,"disconnected":_vm.handleDisconnected},scopedSlots:_vm._u([{key:"default",fn:function({ seed, peers }){return [(_vm.enabled && _vm.room)?[(_vm.singleTeamChatMode)?_c('div',{staticClass:"audio-chat"},[_c('div',{staticClass:"audio-chat__items-wrapper"},[_c('div',{staticClass:"audio-chat__item",class:{
                'audio-chat__item--captain': _vm.checkIfCaptain(seed.id)
              }},[_c('div',{staticClass:"audio-chat__captain-icon"}),_c('div',{staticClass:"audio-chat__item-name audio-chat__item-name--current",class:{ 'audio-chat__item--speaker': seed.speaking },attrs:{"title":_vm.dataCurrentSession.currentPlayerNickname}},[_vm._v(" "+_vm._s(_vm.dataCurrentSession.currentPlayerNickname)+" ")])]),_vm._l((_vm.getPlayerListFromPeers(peers)),function(item){return _c('div',{key:item.id,staticClass:"audio-chat__item",class:{
                'audio-chat__item--captain': item.captain,
                'audio-chat__item--trainer': item.trainer,
                'audio-chat__item--assistant': item.assistant,
                'audio-chat__item--speaker': item.speaking,
                'audio-chat__item--muted': item.muted
              }},[_c('div',{staticClass:"audio-chat__captain-icon"}),_c('div',{staticClass:"audio-chat__item-name",attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])])})],2)]):_c('div',{staticClass:"audio-chat__role-items"},_vm._l((_vm.getRoleItemsFromPeers(peers)),function(item){return _c('div',{key:item.id,staticClass:"audio-chat__role-item",class:{
              'audio-chat__role-item--trainer': item.trainer,
              'audio-chat__role-item--assistant': item.assistant
            }},[_c('div',{staticClass:"audio-chat__role-item-container"},[_c('div',{staticClass:"audio-chat__role-item-icon"}),_c('div',[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"audio-chat__role-item-microphone-icon",class:{
                'audio-chat__role-item-microphone-icon--active': item.speaking
              }})])}),0)]:_vm._e()]}}])})],1),(_vm.room && _vm.enabled)?_c('div',{staticClass:"audio-chat__btn",class:{
      'audio-chat__btn--expanded': _vm.expanded
    },on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_c('div',{staticClass:"audio-chat__btn_border"},[(_vm.expanded)?_c('div',{staticClass:"audio-chat__btn_arrow audio-chat__btn_arrow_right"}):_vm._e(),(!_vm.expanded)?_c('div',[_vm._v(" "+_vm._s(_vm.$lui('chat.show'))+" ")]):_vm._e(),(!_vm.expanded)?_c('div',{staticClass:"audio-chat__btn_arrow audio-chat__btn_arrow_left"}):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }