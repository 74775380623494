<template>
  <div class="diagram">
    <div class="diagram__wrap">
      <div class="diagram__wrap-y">
        <div class="diagram__wrap-y-points">
          <span class="y-points_start">{{ currency }}{{ lineValues[0] }}</span>
          <span class="y-points_before-start">
            {{ currency }}{{ lineValues[1] }}
          </span>
          <span>{{ currency }}{{ lineValues[2] }}</span>
          <span>{{ currency }}{{ lineValues[3] }}</span>
          <span class="y-points_end">{{ currency }}{{ lineValues[4] }}</span>
        </div>
      </div>
      <div class="diagram__results">
        <div>
          <canvas ref="mainCanvas" width="435" height="435"></canvas>
        </div>
        <div
          class="diagram__results-info"
          v-for="(item, i) in dots"
          v-bind:key="i"
          :style="`top: ${item.dY}px; left: ${item.dX}px`"
        >
          <div
            class="diagram__results-dots"
            ref="dots"
            :style="`background-color:${item.color}`"
          ></div>
          <div class="diagram__results-popup">
            <h3
              v-for="teamName in item.teams"
              :key="teamName"
              class="diagram__results-popup__team"
            >
              {{ teamName }}
            </h3>
            <p class="diagram__results-popup__step">
              {{ $lui('results.turn') + ' ' + item.turn }}
            </p>
            <p class="diagram__results-popup__money">
              {{ currency }}{{ item.resultMil }}
            </p>
          </div>
        </div>
      </div>
      <div class="diagram__wrap-x">
        <div class="diagram__wrap-x-points">
          <span class="x-points_start">{{ $lui('results.turn') }}</span>
          <span>1</span>
          <span>2</span>
          <span>3</span>
          <span>4</span>
          <span class="x-points_before-end">5</span>
          <span class="x-points_end">{{ $lui('results.bonus') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import debug from './../../utils/debug'

var maxValues = null
var minValues = 0
var valuesDif = null

const LINE_STYLES = {
  best: {
    color: 'rgb(141, 190, 160)',
    width: 4
  },

  current: {
    color: 'rgb(251, 148, 191)',
    width: 4
  },

  worst: {
    color: 'rgb(188,29,67)',
    width: 3
  },

  others: {
    color: 'rgb(180, 180, 180)',
    width: 1
  }
}

export default {
  name: 'ResultDiagram',

  data() {
    return {
      dots: {},
      lineValues: [0, 0, 0, 0, 0]
    }
  },

  computed: mapGetters({
    resultsData: 'session/getResults',
    currency: 'session/currency'
  }),

  methods: {
    clearDots() {
      this.dots = {}
    },

    addDot(dot) {
      const { dX, dY, resultMil, turn, teamName, style } = dot
      const key = `${dX}_${dY}`

      if (!this.dots[key]) {
        this.dots[key] = {
          dX,
          dY,
          resultMil,
          turn,
          style,
          color: LINE_STYLES[style]?.color || 'rgb(180, 180, 180)',
          teams: []
        }
      }

      this.dots[key].teams.push(teamName)

      if (this.dots[key].style !== 'best' && this.dots[key].style !== 'worst') {
        this.dots[key].style = style
        this.dots[key].color = LINE_STYLES[style]?.color || 'rgb(180, 180, 180)'
      }
    },

    createDiagramNow() {
      this.createDiagram(this.resultsData)
    },

    formatSumValue(value) {
      let addSymbol = ' '
      if (value < 0) {
        addSymbol = '-'
        value = -value
      }

      if (value < 1000) {
        return addSymbol + value + ''
      } else if (value < 99999) {
        return addSymbol + Math.floor(value / 100) / 10 + 'K'
      } else if (value < 999999) {
        return addSymbol + Math.floor(value / 1000) + 'K'
      } else {
        return addSymbol + Math.floor(value / 10000) / 100 + 'M'
      }
    },

    createDiagram(data) {
      this.clearDots()
      let results = data.diagramData
      maxValues = null
      minValues = 0

      for (let r = 0; r < results.length; r++) {
        let moneyPerStep = results[r].steps
        moneyPerStep.forEach(money => {
          if (maxValues) {
            maxValues = Math.max(maxValues, money)
          } else {
            maxValues = money
          }

          minValues = Math.min(minValues, money)
        })
      }

      valuesDif = maxValues - minValues

      if (results.length > 0) {
        let resultsTeamsWinners = []
        for (let r = 0; r < results.length; r++) {
          let moneyPerStep = results[r].steps
          let teamWinner = {
            teamName: results[r].teamName,
            steps: [],
            style: results[r].style
          }

          moneyPerStep.forEach(money => {
            let percentValue = 0
            if (valuesDif <= 0) {
              percentValue = 0.5
            } else {
              percentValue = (money - minValues) / valuesDif
            }
            let calcMoney = 1 - percentValue
            teamWinner.steps.push(calcMoney)
          })
          resultsTeamsWinners.push(teamWinner)
        }

        let canvas = this.$refs.mainCanvas
        debug.info('canvas', this, canvas)
        if (canvas) {
          let ctx = canvas.getContext('2d')
          ctx.clearRect(0, 0, canvas.width, canvas.height)
          for (var team = 0; team < resultsTeamsWinners.length; team++) {
            const iTeam = resultsTeamsWinners[team]

            ctx.lineWidth = LINE_STYLES[iTeam.style]?.width || 1

            ctx.beginPath()
            let colorLine =
              LINE_STYLES[iTeam.style]?.color || 'rgb(180, 180, 180)'
            ctx.strokeStyle = colorLine

            let numOfSteps = 6
            let height = 435
            let width = 435

            ctx.moveTo(0, iTeam.steps[0] * height)

            for (var i = 0; i < iTeam.steps.length; i++) {
              let dX
              let dY

              dX = (i * width) / numOfSteps
              dY = iTeam.steps[i] * height

              let resultMil = this.formatSumValue(
                minValues + (1 - iTeam.steps[i]) * valuesDif
              )
              let turn = i
              let teamName = iTeam.teamName
              if (i > 0) {
                this.addDot({
                  dX,
                  dY,
                  resultMil,
                  turn,
                  teamName,
                  style: iTeam.style
                })
              }

              ctx.lineTo(dX, dY)
            }
            ctx.stroke()
          }
        }
        this.lineValues = []
        for (let line = 0; line < 5; line++) {
          let result = minValues + valuesDif * (line / (5 - 1))
          this.lineValues.push(this.formatSumValue(result))
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.diagram__wrap {
  width: 435px;
  height: 435px;
  position: relative;

  &-y {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 12px;
    height: 100%;
    background-image: url(../../assets/lineY.png);
    background-position: center;
    background-size: 12px 435px;
    background-repeat: no-repeat;

    &-points {
      color: white;
      position: absolute;
      width: 50px;
      top: -8px;
      right: 20px;
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
      font-size: 13px;

      & span {
        margin-bottom: 91px;
      }

      & .y-points_before-start {
        margin-bottom: 80px;
      }

      & .y-points_start {
        margin-bottom: 0;
      }

      & .y-points_end {
        margin-bottom: 93px;
      }
    }
  }

  &-x {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 12px;
    width: 100%;
    background-image: url(../../assets/lineX.png);
    background-position: center;
    background-size: 435px 12px;
    background-repeat: no-repeat;

    &-points {
      color: white;
      position: absolute;
      height: 10px;
      bottom: -10px;
      left: 0;
      display: flex;
      flex-direction: row;
      font-size: 13px;

      span {
        margin-right: 65px;
        text-transform: uppercase;
      }

      .x-points_start {
        margin-right: 33px;
      }

      .x-points_before-end {
        margin-right: 29px;
      }
      .x-points_end {
        margin-right: 0;
      }
    }
  }
}

.diagram__results {
  position: relative;
}

.diagram__results-info {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  left: 0;

  .diagram__results-dots {
    position: absolute;
    background-color: red;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    border: 2px solid #344656;
    z-index: 10;
  }

  .diagram__results-popup {
    opacity: 0;
    pointer-events: none;
    text-align: center;
    font-size: 15px;
    border-radius: 5px;
    border: 1px solid black;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.4);
    min-width: 98px;
    min-height: 92px;
    transform: translate(-47%, 20px);
    background-color: white;
    transition: all ease-in 0.1s;
    position: absolute;
    z-index: 12;

    &__team {
      border-bottom: 1px solid black;
      padding: 0 5px;
    }

    &__step {
      margin-top: 6px;
      opacity: 0.5;
      text-transform: uppercase;
    }

    &__money {
      font-weight: 600;
    }
  }

  &:hover {
    .diagram__results-dots {
      width: 15px;
      height: 15px;
    }

    .diagram__results-popup {
      opacity: 1;
    }
  }
}
</style>
