<template>
  <div class="news-card" :v-if="item">
    <div v-if="item.type == 'newsWithoutTitle'" class="news-without-title">
      <div class="news-without-title__content">
        <p class="news-without-title__text">{{ formattedText }}</p>
        <img
          src="../../assets/news/breaking-news-img.png"
          alt=""
          class="news-without-title__img"
          dir="ltr"
        />
      </div>
    </div>
    <div
      v-if="item.type == 'newsWithPictureAndTitleRight'"
      class="news-with-picture-right"
    >
      <p class="news-with-picture-right__title">{{ $lc(item.newsTitle) }}</p>
      <img
        class="news-with-picture-right__line"
        src="../../assets/news/news-card-line.png"
        alt=""
      />
      <div class="news-with-picture-right__data-box">
        <p class="news-with-picture-right__text">{{ formattedText }}</p>
        <img :src="item.newsImg" alt="" class="news-with-picture-right__img" />
      </div>
    </div>
    <div
      v-if="item.type == 'newsWithPictureAndTitle'"
      class="news-with-picture"
    >
      <p class="news-with-picture__title">{{ $lc(item.newsTitle) }}</p>

      <img
        class="news-with-picture__line"
        src="../../assets/news/news-card-line.png"
        alt=""
      />
      <p class="news-with-picture__text">{{ formattedText }}</p>
      <img
        :src="item.newsImg"
        alt=""
        class="news-with-picture__img"
        dir="ltr"
      />
    </div>
    <div v-if="item.type == 'report'" class="report">
      <div class="report__title-content">
        <p class="report__title">{{ $lc(item.newsTitle) }}</p>
      </div>

      <img
        class="report__line"
        src="../../assets/news/news-card-line.png"
        alt=""
      />
      <p class="report__text">{{ formattedText }}</p>
    </div>
    <div v-if="item.type === 'official_notification'" class="report">
      <div class="report__title-content">
        <p class="report__title">{{ $lc(item.newsTitle) }}</p>
      </div>

      <p class="report__text-parent-title">{{ formattedText }}</p>
      <ul class="report__text-children-title" v-if="item.children">
        <li v-for="(message, index) in item.children" :key="index">
          <div class="report__text">
            {{ interpolateText($lc(message.newsText)) }}
          </div>
        </li>
      </ul>
      <img
        class="report__line"
        src="../../assets/news/news-card-line.png"
        alt=""
      />
    </div>
    <div v-if="item.type == 'decisions'" class="decisions">
      <div class="decisions__info">
        <div class="decisions__info-block">
          <p class="decisions__title">{{ $lc(item.newsTitle) }}</p>
          <p class="decisions__text">{{ formattedText }}</p>
        </div>

        <img
          class="decisions__line"
          src="../../assets/news/news-card-line.png"
          alt=""
        />
      </div>
      <div class="decisions__cards-container">
        <div
          v-for="(card, i) in item.cardsOnStep"
          :key="i"
          class="decisions__cards-item"
        >
          <Card :item="card" class="card" :newsCard="true" />
        </div>
      </div>
    </div>
    <div v-if="item.type == 'notification'" class="notification">
      <div class="notification__content" id="turn1-res">
        <div class="notification__title-content _simple-head" dir="ltr">
          <img
            class="notification__icon _simple-icon"
            src="../../assets/news/notification-cards-icon.png"
            alt=""
          />
          <p class="notification__title">{{ $lc(item.newsTitle) }}</p>
        </div>
        <p class="notification__text _simple-text">{{ formattedText }}</p>
      </div>
    </div>
    <div v-if="item.type == 'letterFromSupport'" class="mail">
      <div class="mail__container">
        <div class="mail__background"></div>
        <!-- FIXME wat to do with title\headline ???? -->
        <div class="mail__headline">
          {{
            item.newsTitle && item.newsTitle != ''
              ? item.newsTitle
              : $lui('news.mail.headline')
          }}
          <div class="mail__headline__line"></div>
        </div>

        <div
          v-for="{ header, value } in getEmailAcceptableHeaders(item.headers)"
          :key="header"
          class="mail__subheadline"
        >
          <div class="mail__subheadline__type">
            {{ $lui(`news.mail.${header}`) }}
          </div>
          <div class="mail__subheadline__text">{{ value }}</div>
          <div class="mail__subheadline__line"></div>
        </div>

        <div class="mail__text">{{ $lc(item.body) }}</div>
      </div>
      <img class="mail__end-mail" src="../../assets/news/mail_down.svg" />
    </div>
    <div
      v-if="item.type == 'positiveNotification'"
      class="positive-notification"
    >
      <div class="positive-notification__content">
        <div
          class="positive-notification__title-content _simple-head"
          dir="ltr"
        >
          <img
            class="positive-notification__icon _simple-icon"
            src="../../assets/news/positive-notification-cards-icon.png"
            alt=""
          />
          <p class="positive-notification__title">{{ $lc(item.newsTitle) }}</p>
        </div>
        <p class="positive-notification__text _simple-text">
          {{ formattedText }}
        </p>
      </div>
    </div>

    <div v-if="item.type == 'newsWithVideo'" class="news-with-video">
      <p class="news-with-video__title">{{ $lc(item.newsTitle) }}</p>
      <p class="news-with-video__text">{{ formattedText }}</p>
      <div class="news-with-video__video-wrap" @click.prevent="playVideo">
        <video
          :src="item.newsVideo"
          controls
          ref="video"
          @ended="onVideoEnds"
        ></video>
        <button v-if="visibleBtnPlay" class="news-with-video__video-btn">
          <img
            width="87"
            height="87"
            src="../../assets/news/play-video-btn.png"
            alt=""
          />
        </button>
        <button v-if="visibleBtnPause" class="news-with-video__video-btn">
          <img
            width="87"
            height="87"
            src="../../assets/news/pause-video-btn.png"
            alt=""
          />
        </button>
      </div>
    </div>
    <div v-if="item.type == 'urgentNotification'" class="urgent-notification">
      <div class="urgent-notification__content">
        <div class="urgent-notification__title-content _simple-head" dir="ltr">
          <img
            class="urgent-notification__icon _simple-icon"
            src="../../assets/news/urgent-notification-cards-icon.png"
            alt=""
          />
          <p class="urgent-notification__title">{{ $lc(item.newsTitle) }}</p>
        </div>
        <p class="urgent-notification__text _simple-text">
          {{ formattedText }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { interpolateText } from '../../services/interpolation'
import Card from '../Cards/GameCard.vue'

export default {
  components: {
    Card
  },

  props: {
    item: {}
  },

  data() {
    return {
      visibleBtnPlay: true,
      visibleBtnPause: false,
      videoStatus: false
    }
  },

  computed: {
    formattedText() {
      const text = this.$lc(this.item.newsText)
      return interpolateText(text)
    }
  },

  methods: {
    interpolateText,

    getEmailAcceptableHeaders(headers) {
      return headers.filter(({ header }) => {
        return this.$store.getters['localizations/checkUILocalizationForKey'](
          `news.mail.${header}`
        )
      })
    },

    playVideo: async function () {
      if (this.videoStatus === false) {
        this.visibleBtnPause = false
        this.visibleBtnPlay = false
        this.videoStatus = true
        this.$refs.video.play()
      } else if (this.videoStatus === true) {
        this.visibleBtnPause = false ///true;   /// fixme drop pause button?
        this.visibleBtnPlay = true
        this.videoStatus = false
        this.$refs.video.pause()
      }
    },

    onVideoEnds() {
      this.visibleBtnPlay = true
      this.videoStatus = false
    }
  }
}
</script>

<style lang="scss" scoped>
.mail {
  position: relative;
  width: 100%;
  color: black;

  &__container {
    position: relative;
    width: 100%;
  }

  &__background {
    z-index: 1;
    width: auto;
    height: auto;
    bottom: 66px;
    position: absolute;
    top: 0px;
    left: 1px;
    right: 0px;
    background: #fef9f4;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0.129vw 0.129vw 0px 0.129vw rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  &__headline {
    position: relative;
    z-index: 3;
    font-style: normal;
    font-weight: bold;
    font-size: 1.804vw;
    line-height: 39px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    padding: 19px 31px 19px 32px;
    margin-bottom: 14px;

    &__line {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      opacity: 0.2;
      background: black;
      height: 1px;
    }
  }

  &__subheadline {
    position: relative;
    z-index: 3;
    padding: 3px 32px;
    display: flex;

    &__type {
      width: 117px;
      left: 0px;
      top: 0px;
      font-style: normal;
      font-weight: bold;
      font-size: 1.16vw;
      line-height: 1.74vw;

      @media screen and (min-width: 1024px) and (max-width: 1551px) {
        font-size: 1.406vw;
        line-height: 2.148vw;
      }
    }

    &__text {
      left: 0px;
      top: 0px;
      font-style: normal;
      font-weight: normal;
      font-size: 1.16vw;
      line-height: 1.74vw;

      @media screen and (min-width: 1024px) and (max-width: 1551px) {
        font-size: 1.406vw;
        line-height: 2.148vw;
      }
    }

    &__line {
      position: absolute;
      bottom: 0px;
      height: 1px;
      width: 100%;
      width: 93%;
      left: 50%;
      transform: translate(-50%, 0px);
      opacity: 0.1;
      background: black;
    }
  }

  &__text {
    position: relative;
    z-index: 3;
    padding: 13px 32px 24px 32px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 1.16vw;
    line-height: 1.74vw;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      width: 56.232vw;
      font-size: 1.406vw;
      line-height: 2.148vw;
    }
  }

  &__end-mail {
    position: relative;
    margin-top: -100px;
    z-index: 2;
    width: 100%;
  }
}

.news-without-title {
  margin-bottom: 8px;
  width: 100%;
  padding: 1.804vw 1.418vw 1.546vw 2.062vw;
  background: #fef9f4;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 2px 2px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__text {
    width: 39.046vw;
    font-style: normal;
    font-weight: normal;
    font-size: 1.16vw;
    line-height: 1.74vw;
    color: #000000;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      width: 47.266vw;
      font-size: 1.406vw;
      line-height: 2.148vw;
    }
  }

  &__img {
    width: 11.211vw;
    height: 8.376vw;
    border: 1px solid #000000;
    border-radius: 4px;

    &[dir='ltr'] {
      transform: scaleX(1);
    }

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      width: 13.574vw;
      height: 10.156vw;
    }
  }
}

.news-with-picture {
  margin-bottom: 0.515vw;
  //width: 56.443vw;
  background: #fef9f4;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0.129vw 0.129vw 0px 0.129vw rgba(0, 0, 0, 0.25);
  border-radius: 0.258vw;

  &__title {
    padding: 1.224vw 2.062vw 0.902vw;
    font-style: normal;
    font-weight: bold;
    font-size: 1.804vw;
    line-height: 2.526vw;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
  }

  &__line {
    width: 100%;
  }

  &__text {
    width: 47.358vw;
    margin: 1.224vw 2.062vw 2.126vw;
    font-style: normal;
    font-weight: normal;
    font-size: 1.16vw;
    line-height: 1.74vw;
    color: #000000;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      width: 56.232vw;
      font-size: 1.406vw;
      line-height: 2.148vw;
    }
  }

  &__img {
    margin: 0 1.675vw 1.997vw 2.062vw;
    width: 94%;
    height: 25.58vw;
    object-fit: cover;
    object-position: center;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0.129vw 0.129vw 0px 0.129vw rgba(0, 0, 0, 0.25);
    border-radius: 0.258vw;

    &[dir='ltr'] {
      transform: scaleX(1);
    }
  }
}

.news-with-picture-right {
  margin-bottom: 0.515vw;
  width: 100%;
  background: #fef9f4;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 2px 2px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  &__data-box {
    display: flex;
  }

  &__title {
    padding: 1.224vw 0 0.902vw 2.062vw;
    font-style: normal;
    font-weight: bold;
    font-size: 1.804vw;
    line-height: 2.513vw;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      font-size: 2.188vw;
      line-height: 3.027vw;
    }
  }

  &__line {
    width: 28.157vw;
  }

  &__text {
    width: 47.358vw;
    margin: 1.224vw 0 2.126vw 2.062vw;
    font-style: normal;
    font-weight: normal;
    font-size: 1.16vw;
    line-height: 1.74vw;
    color: #000000;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      width: 35.358vw;
      font-size: 1.406vw;
      line-height: 2.148vw;
    }
  }

  &__img {
    margin: 0 1.095vw 31px 1.16vw;
    width: 94%;
    height: 21.843vw;
    object-fit: cover;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 2px 2px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      width: 27.966vw;
      height: 23.843vw;
    }
  }
}

.report {
  margin-bottom: 0.515vw;
  width: 100%;
  background: #fef9f4;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 2px 2px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  &__title-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    padding: 19px 31px 19px 32px;
    font-style: normal;
    font-weight: bold;
    font-size: 1.804vw;
    line-height: 2.513vw;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      font-size: 2.188vw;
      line-height: 3.027vw;
    }
  }

  &__line {
    width: 100%;
  }

  &__text {
    width: 45.232vw;
    margin: 19px 0 33px 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 1.16vw;
    line-height: 1.74vw;
    color: #000000;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      font-size: 1.406vw;
      line-height: 2.148vw;
    }
  }

  &__text-parent-title {
    width: 45.232vw;
    margin: 19px 0 33px 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 1.16vw;
    line-height: 1.74vw;
    color: #000000;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      font-size: 1.406vw;
      line-height: 2.148vw;
    }
  }

  &__text-children-title {
    width: 45.232vw;
    font-style: normal;
    font-weight: normal;
    font-size: 1.16vw;
    line-height: 1.74vw;
    color: #000000;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      font-size: 1.406vw;
      line-height: 2.148vw;
    }
  }

  &__icon {
    margin-right: 15px;
  }
}

.decisions {
  display: block;
  position: relative;
  margin-bottom: 0.515vw;
  width: 100%;
  min-height: 385px;
  background: #fef9f4;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 2px 2px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  @media screen and (min-width: 1024px) and (max-width: 1551px) {
    min-height: 290px;
  }

  &__info-block {
    display: flex;
    align-items: center;
    margin: 1.16vw 0 0.838vw 2.062vw;
  }

  &__title-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    margin-right: 32px;
    font-style: normal;
    font-weight: bold;
    font-size: 1.804vw;
    line-height: 39px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      font-size: 2.188vw;
      line-height: 3.027vw;
    }
  }

  &__line {
    width: 100%;
  }

  &__text {
    width: 45.232vw;
    font-style: normal;
    font-weight: normal;
    font-size: 1.16vw;
    line-height: 1.74vw;
    color: #000000;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      font-size: 1.406vw;
      line-height: 2.148vw;
    }
  }

  &__cards-container {
    margin-left: 2.062vw;
    margin-top: 1.675vw;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      top: 24%;
    }
  }

  &__cards-item {
    &:not(:last-child) {
      margin-right: 1.031vw;
    }
  }

  &__card {
    width: 7.796vw;
    height: 10.954vw;
    object-fit: cover;
  }
}

.notification {
  background: #fef9f4;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 1px 1px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-bottom: 0.515vw;

  &__title-content {
    background: #fdd957;
  }

  &__title {
    margin-top: 7px;
    margin-bottom: 7px;
    color: #000;
  }
}

.letter-from-support {
  width: 56.508vw;
  height: 31.572vw;
  margin-bottom: 2.062vw;
  background-image: url(../../assets/news/letter-bg.png);
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (min-width: 1024px) and (max-width: 1551px) {
    width: 68.232vw;
    height: 38.572vw;
  }

  &__title {
    padding: 1.224vw 0 0.902vw 2.062vw;
    font-style: normal;
    font-weight: bold;
    font-size: 1.804vw;
    line-height: 39px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      font-size: 2.188vw;
      line-height: 3.027vw;
    }
  }

  &__line {
    width: 36.856vw;
  }

  &__letter-text-wrap {
    display: flex;
    margin-top: 0.902vw;
    margin-left: 2.062vw;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      margin-top: 1.6vw;
    }
  }

  &__bold-text-wrap {
    margin-right: 2.899vw;
  }

  &__bold-text {
    font-style: normal;
    font-weight: bold;
    font-size: 1.16vw;
    line-height: 2.448vw;
    color: #000000;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      font-size: 1.78vw;
      line-height: 3.027vw;
    }
  }

  &__light-text {
    font-style: normal;
    font-weight: normal;
    font-size: 1.16vw;
    line-height: 2.448vw;
    color: #000000;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      font-size: 1.78vw;
      line-height: 3.027vw;
    }
  }

  &__text {
    width: 45.554vw;
    margin: 1.224vw 0 2.126vw 2.062vw;
    font-style: normal;
    font-weight: normal;
    font-size: 1.16vw;
    line-height: 1.74vw;
    color: #000000;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      width: 60.554vw;
      font-size: 1.406vw;
      line-height: 2.148vw;
    }
  }
}

.urgent-notification {
  background: #fef9f4;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 1px 1px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-bottom: 8px;

  &__title-content {
    background: #f51d21;
  }

  &__title {
    //line-height: 17px;
    margin-top: 7px;
    margin-bottom: 7px;
    color: #ffffff;
  }
}

.positive-notification {
  background: #fef9f4;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 1px 1px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-bottom: 8px;

  &__title-content {
    background: #1f9d4d;
  }

  &__title {
    color: #ffffff;
    margin-top: 7px;
    margin-bottom: 7px;
  }
}

.news-with-video {
  background: #fef9f4;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 2px 2px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-bottom: 0.515vw;

  &__title {
    padding: 1.224vw 0 0 2.062vw;
    margin-bottom: 2.126vw;
    font-style: normal;
    font-weight: bold;
    font-size: 1.804vw;
    line-height: 39px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      font-size: 2.188vw;
      line-height: 3.027vw;
    }
  }

  &__text {
    width: 47.358vw;
    margin: 0 0 2.126vw 2.062vw;
    font-style: normal;
    font-weight: normal;
    font-size: 1.16vw;
    line-height: 1.74vw;
    color: #000000;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      font-size: 1.406vw;
      line-height: 2.148vw;
    }
  }

  &__video-wrap {
    position: relative;
    width: 52.577vw;
    height: 29.67vw;
    margin: 0 auto 2.126vw;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 2px 2px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      width: 64.577vw;
      height: 36.45vw;
    }

    video {
      width: 100%;
      height: 100%;
    }
  }

  &__video-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
  }

  &__hide-video-btn {
    display: none;
  }
}

.arrow-down {
  margin-left: 0.515vw;
}

.margin-bottom-box {
  width: 100%;
  height: 100px;
}

.card {
  cursor: auto;
}

._simple {
  &-head {
    min-height: 55px;
    display: flex;
    align-items: center;
    width: 55.412vw;
    margin: 1.095vw auto;
    font-style: normal;
    font-weight: bold;
    font-size: 1.804vw;
    line-height: 39px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
    box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: 1px solid #000000;
    padding-right: 1.224vw;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      width: 67.412vw;
      font-size: 2.188vw;
      line-height: 3.027vw;
    }

    &[dir='ltr'] {
      img {
        transform: scaleX(1);
      }
    }
  }

  &-text {
    width: 45.232vw;
    margin: 1.224vw 1.5vw 2.126vw 2.062vw;
    font-style: normal;
    font-weight: normal;
    font-size: 1.16vw;
    line-height: 1.74vw;
    color: #000000;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      width: 56.232vw;
      font-size: 1.406vw;
      line-height: 2.148vw;
    }
  }

  &-icon {
    width: 2.835vw;
    height: 2.899vw;
    margin-right: 1.16vw;
    margin-left: 1.224vw;
  }
}
</style>
