<template>
  <div
    class="layout"
    :class="{
      'all-game': gameState !== 'rewarding',
      'finish-game': gameState === 'rewarding'
    }"
  >
    <HeaderComponent star-status :transparent="transparent" />

    <div
      :class="{
        nav: allGameLayout,
        'finish-nav': !allGameLayout
      }"
    >
      <NavMenuComponent
        ref="navigation"
        class="navigation"
        v-if="
          !isInfoConsoleGame &&
          gameState != 'finished' &&
          gameState != 'none' &&
          gameState !== 'new'
        "
      />
    </div>
    <FooterComponent
      v-if="!isInfoConsoleGame"
      ref="footerComp"
      :transparent="transparent"
      class="footer"
    />
  </div>
</template>

<script>
import HeaderComponent from './Header/HeaderComponent.vue'
import NavMenuComponent from './NavMenuComponent.vue'
import FooterComponent from './FooterComponent.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'LayoutComponent',
  data() {
    return {}
  },

  props: {
    // eslint-disable-next-line vue/no-boolean-default
    allGameLayout: { type: Boolean, default: true },
    transparent: { type: Boolean, default: false }
  },

  computed: mapGetters({
    gameState: 'session/gameState',
    isInfoConsoleGame: 'session/isInfoConsoleGame'
  }),

  components: {
    HeaderComponent,
    NavMenuComponent,
    FooterComponent
  }
}
</script>

<style lang="scss" scoped>
.layout {
  height: 100vh;
}

.all-game {
  background-color: #4b647c;
}

.finish-game {
  background: linear-gradient(
    90deg,
    #549485 10.6%,
    #82b29e 57.07%,
    #9fc99e 98.07%
  );
}

.finish-nav {
  display: none;
}

.btn-zoom {
  width: 44px;
  height: 44px;
  padding: 11px 0 0 18px;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  transition: opacity 0.8s linear;
  cursor: pointer;

  &__icon {
    width: 44px;
    height: 44px;
  }

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.header-btn-star-icon {
  position: absolute;
  top: 42%;
  left: 42%;
  transform: translate(-50%, -47%);
}

.header-btn-volume-icon {
  position: absolute;
  top: 43%;
  left: 45%;
  transform: translate(-50%, -47%);
}

.nav {
  position: fixed;
  top: 128px;
  left: 16px;
  z-index: 100;
}

.footer {
  position: fixed;
  bottom: 0;
}
</style>
