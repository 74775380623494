<template>
  <div class="header-wrap" :class="{ 'header-wrap--transparent': transparent }">
    <div class="header">
      <div class="header__short-part">
        <img
          width="312"
          height="71"
          src="../../assets/header/header-short-part.png"
          alt=""
        />
        <TimerComponent class="header__short-part__timer" />
        <HeaderInfoBlockComponent class="header__short-part__info-block">
          <template #infoData>
            {{ getStepText() }}
          </template>
          <template #infoTitle>
            {{ getPhaseText() }}
          </template>
        </HeaderInfoBlockComponent>
      </div>
      <img
        class="header__wires"
        width="52"
        height="56"
        src="../../assets/header/wires.png"
        alt=""
      />
      <div class="header__long-part">
        <div v-if="!isInfoConsoleGame" class="header__long-part-images">
          <img class="first" src="../../assets/header/left-long-part.png" />
          <img class="middle" src="../../assets/header/middle-long-part.png" />
          <img class="last" src="../../assets/header/right-long-part.png" />
          <img class="tail" src="../../assets/header/tail-long-part.png" />
        </div>
        <div v-else class="header__long-part-images">
          <img class="first" src="../../assets/header/left-long-part-big.png" />
          <img class="middle" src="../../assets/header/middle-long-part.png" />
        </div>

        <div v-if="!isInfoConsoleGame" class="header__long-part__data">
          <HeaderInfoBlockComponent>
            <template #infoData>
              {{ formattedCurrency }} {{ getSessionProfit() }}
            </template>
            <template #infoTitle>
              {{ $lui('header.step-income') }}
            </template>
          </HeaderInfoBlockComponent>
          <HeaderInfoBlockComponent :data-frame="false">
            <template #infoData>
              {{ formattedCurrency }} {{ getSessionProfitTotal() }}
            </template>
            <template #infoTitle>
              {{ $lui('header.total-income') }}
            </template>
          </HeaderInfoBlockComponent>
        </div>
        <div
          class="header__long-part__team-data"
          :class="{ 'header__infoconsole-game': isInfoConsoleGame }"
        >
          <span v-if="!isInfoConsoleGame && dataCurrentSession.id > 0">
            #{{ dataCurrentSession.id }}
          </span>
          <span>{{ dataCurrentSession.teamName }}</span>
        </div>
      </div>
      <div v-if="!isInfoConsoleGame" class="header__btn-wrap">
        <div v-if="room && chatEnabled" class="header__audio-chat-switches">
          <div class="header__audio-chat-switch-container">
            <div
              :class="{
                'header__audio-chat-inactive-item': !singleTeamChatMode
              }"
            >
              {{ $lui('chat.with-team') }}
            </div>

            <div
              class="header__audio-chat-switch"
              :class="{
                'header__audio-chat-switch--alternate': !singleTeamChatMode
              }"
              @click="switchAudioChatMode"
            ></div>
            <div
              :class="{
                'header__audio-chat-inactive-item': singleTeamChatMode
              }"
            >
              {{ $lui('chat.with-trainer') }}
            </div>
          </div>

          <div
            class="header__audio-chat-switch-container header__audio-chat-switch-container--relay"
          >
            <div class="header__audio-chat-switch-container--relay-switch">
              <div
                class="header__audio-chat-switch"
                :class="{
                  'header__audio-chat-switch--alternate': useRelay
                }"
                @click="toggleUseRelay"
              ></div>
              <div
                :class="{
                  'header__audio-chat-inactive-item': !useRelay
                }"
              >
                {{ $lui('chat.use-relay') }}
              </div>
            </div>
          </div>
        </div>

        <div class="header__status-wrap" @click="openTeamList">
          <div
            class="header__status-wrap-icon header__status-wrap-icon--people"
          ></div>
        </div>

        <div
          class="header__status-wrap"
          :class="{ captainStatus: isCaptain }"
          @click="openTeamProfilePopup"
        >
          <div
            class="header__status-wrap-icon"
            :class="{ captainStatus: isCaptain }"
          ></div>
        </div>
      </div>
    </div>
    <div v-if="!isInfoConsoleGame" class="header__phase-info__container">
      <PhaseChangePopupComponent
        v-for="(info, idX) in phaseInfoList"
        :key="`${idX}-${info.phase}-${info.phase.phaseStart}`"
        :info="info"
      />

      <AppToast ref="captainToast" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PhaseChangePopupComponent from './PhaseChangePopupComponent.vue'
import HeaderInfoBlockComponent from './HeaderInfoBlockComponent.vue'
import TimerComponent from './TimerComponent.vue'
import teamPlayerProfileContext from '../../constants/teamPlayerProfileContext'
import AppToast from '../AppToast.vue'

export default {
  name: 'HeaderComponent',

  components: {
    PhaseChangePopupComponent,
    HeaderInfoBlockComponent,
    TimerComponent,
    AppToast
  },

  props: {
    transparent: { type: Boolean, default: false }
  },

  computed: {
    ...mapGetters({
      gameState: 'session/gameState',
      gameId: 'session/gameId',
      isCaptain: 'session/getIsCaptain',
      captainId: 'session/captainId',
      headerCurrencyHidden: 'session/headerCurrencyHidden',
      phaseInfoList: 'session/getPhaseInfoList',
      dataCurrentSession: 'session/dataCurrentSession',
      isInfoConsoleGame: 'session/isInfoConsoleGame',
      room: 'audioChat/currentRoom',
      chatEnabled: 'audioChat/enabled',
      team: 'session/team',
      useRelay: 'audioChat/useRelay',
      currency: 'session/currency'
    }),

    singleTeamChatMode() {
      return this.room !== this.gameId
    },

    formattedCurrency() {
      return !this.headerCurrencyHidden ? this.currency : ''
    }
  },

  watch: {
    captainId(_newValue, oldValue) {
      if (oldValue !== null) {
        this.toastNewCaptainAssignment()
      }
    }
  },

  methods: {
    ...mapActions('audioChat', ['joinTeamChat', 'joinGameChat']),

    toastNewCaptainAssignment() {
      if (this.isCaptain) {
        this.$refs.captainToast.toast({ title: this.$lui('captain.you') })
      } else {
        const captain = this.team.players.find(player => player.is_captain)
        const message = `${this.$lui('captain.other')} — ${captain.nickname}`
        this.$refs.captainToast.toast({ title: message })
      }
    },

    getStepText() {
      const { gameState, dataCurrentSession, $lui } = this

      if (gameState === 'none') {
        return ''
      }
      if (gameState === 'new') {
        return $lui('header.game')
      }

      return `${$lui('header.step')} ${dataCurrentSession.step}`
    },

    getPhaseText() {
      if (this.gameState === 'none') {
        return ''
      }

      let text = this.$lui(`header.phases.${this.gameState}`)
      if (text) {
        return text
      } else {
        return ''
      }
    },

    formatSumValue(value) {
      if (value < 1000) {
        return value + ''
      } else if (value < 99999) {
        return Math.floor(value / 10) / 100 + 'K'
      } else {
        return Math.floor(value / 100) / 10 + 'K'
      }
    },

    getSessionProfit() {
      return this.formatSumValue(this.dataCurrentSession.profitCurrentStep)
    },

    getSessionProfitTotal() {
      return this.formatSumValue(this.dataCurrentSession.totalProfitGame)
    },

    openTeamList() {
      this.$store.commit('session/SET_MODAL', { name: 'teamList' })
    },

    openTeamProfilePopup() {
      this.$store.commit('session/SET_MODAL', {
        name: 'teamPlayerProfile',
        settings: { context: teamPlayerProfileContext.EDIT }
      })
    },

    switchAudioChatMode() {
      if (this.singleTeamChatMode) {
        this.joinGameChat()
      } else {
        this.joinTeamChat()
      }

      this.$store.commit('audioChat/SET_MUTED', false)
    },

    toggleUseRelay() {
      this.$store.dispatch('audioChat/toggleUseRelay')
    }
  }
}
</script>

<style lang="scss">
.header__phase-info__container {
  position: absolute;
  left: 16px;
}

.non-clickable {
  cursor: default;
}

.header-wrap {
  width: 100%;
  position: fixed;
  z-index: 200;

  @media screen and (max-width: 1551px) {
    height: 91px;
    background: linear-gradient(180deg, #000000 7.87%, rgba(0, 0, 0, 0) 100%);
  }
}

.header-wrap--transparent {
  background: none;

  .header__wires,
  .header__short-part,
  .header__long-part,
  .header__status-wrap {
    display: none;
  }
}

.first,
.middle,
.last,
.tail {
  height: 70px;
}
.first {
  justify-content: start;
  width: 352px;
}
.middle {
  width: 95%;

  @media screen and (max-width: 1280px) {
    width: 40%;
  }
}
.last {
  justify-content: end;
  width: 720px;
}

.tail {
  width: 100%;
}

.header {
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 70px;

  &__wires {
    margin: -12px 0 0 -21px;
  }

  &__status-wrap {
    position: relative;
    width: 47px;
    height: 47px;
    background-image: url(../../assets/header/header-active-btn.png);
    background-size: 44px 44px;
    background-repeat: no-repeat;

    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-58%, -71%);
      width: 20px;
      height: 20px;
      background-image: url(../../assets/header/human-icon.svg);
      background-repeat: no-repeat;
    }

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }

  &__disabled-btn {
    position: relative;
    width: 47px;
    height: 47px;
    border: none;
    font: inherit;
    cursor: pointer;
    color: inherit;
    background-color: transparent;
    background-image: url(../../assets/header/header-disabled-btn.png);
    background-size: 44px 44px;
    background-repeat: no-repeat;
  }

  &__btn-wrap {
    position: absolute;
    top: 7px;
    right: 0px;
    margin-right: 6px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__audio-chat-icon {
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      margin-top: -5px;
      margin-left: -4px;
    }
  }

  &__audio-chat-switches {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 28px;

    @media screen and (max-width: 1280px) {
      position: absolute;
      top: 70px;
      right: -14px;
    }
  }

  &__audio-chat-switch-container {
    display: flex;
    flex-shrink: 0;
    white-space: nowrap;
    text-transform: uppercase;
    align-items: center;
    font-size: 12px;
    line-height: 140%;
    font-weight: bold;

    &-start {
      cursor: pointer;
      color: #800;
    }

    &--relay {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    &--relay-switch {
      display: flex;
      align-items: center;
    }
  }

  &__audio-chat-switch {
    width: 38px;
    flex-shrink: 0;
    height: 23px;
    cursor: pointer;
    margin: 0 9px;
    background: url(../../assets/header/audio-chat-switch-alternate.svg)
      no-repeat center;

    &--alternate {
      background: url(../../assets/header/audio-chat-switch.svg) no-repeat
        center;
    }
  }

  &__audio-chat-inactive-item {
    opacity: 0.4;
  }

  &__short-part {
    position: relative;
    height: 70px;

    &__info-block {
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translate(-130%, -62%);
      height: 80px;
      text-transform: uppercase;
    }

    &__timer {
      position: absolute !important;
      top: 30%;
      left: 5%;
      transform: translateY(-32%);
    }
  }

  &__long-part {
    display: flex;
    position: relative;
    height: 70px;
    left: -25px;
    margin-right: -25px;
    width: 100%;

    &__data {
      position: absolute;
      top: 48%;
      left: 50px;
      transform: translate(-1%, -62%);
      display: flex;
      justify-content: space-between;
      width: 248px;
    }

    &__team-data {
      position: absolute;
      left: 353px;
      top: 14%;
      display: flex;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 30px;
      color: #000000;
      text-shadow: -1px 1px 0px rgba(255, 255, 255, 0.4);

      & span {
        margin-right: 10px;
      }
    }
  }

  &__infoconsole-game {
    left: 78px;
  }

  &__long-part-images {
    display: flex;
    width: 100%;
  }
}

.header__status-wrap-icon {
  &.captainStatus {
    transform: translate(-64%, -57%);
    background-image: url(../../assets/header/crown.svg);
  }

  &--people {
    background-image: url(../../assets/header/people.svg);
    transform: translate(-63%, -58%);
    width: 25px;
    height: 20px;
  }
}
.header-right {
  width: 79.381vw;
}
</style>
