<template>
  <div class="finish-game" dir="ltr">
    <div class="certificate">
      <div>
        <TransitionGroup name="approximation">
          <div
            v-show="certificateNotFound"
            key="certificateNotFound"
            class="certificate-local"
          >
            <div class="certificate-local__caption">
              {{ $lui('certificates.title') }}
            </div>
            <div class="certificate-local__team">
              {{ team.name }}
            </div>
            <div class="certificate-local__desc">
              {{ $lui('certificates.content') }}
            </div>

            <AppBranding />

            <div class="certificate-local__date">
              {{ localCertificateDate }}
            </div>
            <img
              class="certificate-local__bg"
              :src="certificateImageLink"
              dir="ltr"
              alt=""
              @error="onError"
              @load="onLoadCertificate"
            />
          </div>
          <img
            v-show="!certificateNotFound"
            key="certificateFound"
            class="approximation"
            :class="{ '_none-animation': certificateNotFound }"
            :src="certificateImageLink"
            dir="ltr"
            alt=""
            @error="onError"
            @load="onLoadCertificate"
          />
        </TransitionGroup>
      </div>
      <div class="finish-game__cards"></div>
      <div class="finish-game__info-wrap">
        <p class="finish-game__info-text">
          {{ $lui('certificates.description') }}
        </p>

        <div v-if="!certificateNotFound" class="finish-game__social-networks">
          <ShareNetwork
            v-for="network in availableNetworks"
            :key="network.network"
            class="finish-game__network"
            :network="network.network"
            :style="{ backgroundColor: network.color }"
            :url="certificateLink"
            title="KIPS Certificate"
          >
            <i :class="network.icon">
              <img
                class="finish-game__network-icon"
                width="30"
                height="30"
                :src="network.iconSrc"
                alt=""
                dir="ltr"
              />
            </i>
          </ShareNetwork>
        </div>

        <a
          v-if="!certificateNotFound"
          class="finish-game__load-link"
          download="KIPS-Certificate.png"
          :href="certificateImageLink"
        >
          {{ $lui('certificate.download') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import VueSocialSharing from 'vue-social-sharing'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import AppBranding from './AppBranding.vue'

Vue.use(VueSocialSharing)

export default {
  components: {
    AppBranding
  },

  data() {
    return {
      networks: [
        {
          network: 'twitter',
          name: 'Twitter',
          icon: 'fab fah fa-lg fa-twitter',
          iconSrc: require('../assets/twitter.png')
        },
        {
          network: 'facebook',
          name: 'Facebook',
          icon: 'fab fah fa-lg fa-facebook-f',
          iconSrc: require('../assets/facebook.png')
        },
        {
          network: 'vk',
          name: 'Vk',
          icon: 'fab fah fa-lg fa-vk',
          iconSrc: require('../assets/vk.png')
        },
        {
          network: 'linkedin',
          name: 'LinkedIn',
          icon: 'fab fah fa-lg fa-linkedin',
          iconSrc: require('../assets/in.png')
        }
      ],

      certificateNotFound: false,
      certificateLoaded: false
    }
  },

  computed: {
    ...mapGetters({
      team: 'session/team',
      currentLocale: 'localizations/currentLocale',
      sharingSocApps: 'session/sharingSocApps',
      branding: 'session/branding'
    }),

    certificateLink() {
      const { team, currentLocale } = this

      return `${window.location.origin}/certificates/${currentLocale}/${team.id}`
    },

    certificateImageLink() {
      return this.certificateLink + '.png'
    },

    availableNetworks() {
      return this.networks.filter(({ network }) =>
        this.sharingSocApps.includes(network)
      )
    },

    localCertificateDate() {
      const date = new Date()
      const month = date.toLocaleString(this.currentLocale, {
        month: 'short'
      })
      return `${month} ${date.getDate()}, ${date.getFullYear()}`
    }
  },

  methods: {
    onError(e) {
      e.srcElement.src = require('@/assets/certificate-template.png')
      this.certificateNotFound = true
    },

    onLoadCertificate() {
      this.certificateLoaded = true
    }
  }
}
</script>

<style lang="scss" scoped>
$b: '.finish-game';
$c: '.certificate';

#{$b} {
  background: linear-gradient(
    90deg,
    #549485 10.6%,
    #82b29e 57.07%,
    #9fc99e 98.07%
  );
  position: absolute;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 190;

  &__info {
    &-wrap {
      position: absolute;
      top: 2%;
      left: 110%;
      width: 494px;
    }

    &-text {
      margin-top: 100px;
      margin-bottom: 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 128%;
      color: #000000;
    }
  }

  &__social-networks {
    display: flex;
    margin-bottom: 16px;
  }

  &__network:not(:last-child) {
    margin-right: 13px;
  }

  &__load-link {
    padding: 0;
    background: transparent;
    border: none;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 177%;
    color: #000000;
    cursor: pointer;
    text-decoration: underline;
  }

  &__cards {
    position: absolute;
    bottom: -16%;
    left: 84%;
    width: 477px;
    height: 400px;
    background-image: url(../assets/finish-cards-img.png);
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
  }

  &__network-icon {
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}

#{$c} {
  position: fixed;
  bottom: 0;
  width: 563px;
  height: 791px;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (min-height: 700px) and (max-height: 800px) {
    transform: scale(0.8) translateY(98px);
  }

  @media screen and (min-height: 600px) and (max-height: 700px) {
    transform: scale(0.7) translateY(168px);
  }

  @media screen and (min-height: 500px) and (max-height: 600px) {
    transform: scale(0.6) translateY(263px);
  }

  @media screen and (max-height: 500px) {
    transform: scale(0.5) translateY(395px);
  }

  img {
    &[dir='ltr'] {
      transform: scaleX(1);
    }

    &._none-animation {
      animation: none !important;
    }
  }

  &-local {
    width: 565px;
    height: 793px;
    position: absolute;
    z-index: 1;

    &__caption {
      text-transform: uppercase;
      text-align: center;
      margin-top: 140px;
      font-size: 28px;
      font-weight: bold;
    }

    &__team {
      text-align: center;
      font-weight: bold;
      margin-top: 40px;
      font-size: 24px;
    }

    &__desc {
      text-align: center;
      padding: 0 130px;
      margin-top: 200px;
    }

    &__date {
      position: absolute;
      bottom: 50px;
      left: calc(50% + 16px);
      transform: translateX(-50%);
      text-transform: capitalize;
    }

    &__bg {
      position: absolute;
      top: 0;
      z-index: -1;
    }
  }

  .approximation,
  .approximation-enter-active,
  .approximation-leave-active {
    animation: approximation-in 3s;
  }
}

@keyframes approximation-in {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
