<template>
  <div class="light-cards">
    <div
      v-for="(item, i) in getPlayedCards[0]"
      v-bind:key="i"
      class="light-cards__container"
      :style="`margin-top:-${
        i % 2 == 1 ? (i / 2 - 0.5) * cardsMarginTop : (i / 2) * cardsMarginTop
      }px`"
    >
      <Card
        :from="'table'"
        :item="item"
        :next-card-in-table-is-empty="
          !item.cardEmpty &&
          getPlayedCards[0][i + 2] &&
          getPlayedCards[0][i + 2].cardEmpty
        "
      />
    </div>
  </div>
</template>

<script>
import Card from './GameCard.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Card
  },

  data: () => ({
    cardsMarginTop: 63
  }),

  computed: mapGetters({
    getPlayedCards: 'cards/UICardsUsed'
  })
}
</script>

<style lang="scss" scoped>
$b: '.light-cards';

#{$b} {
  display: grid;
  grid-template-columns: repeat(2, 85px);
  grid-template-rows: repeat(12, 121px);
  column-gap: 25px;

  &__container {
    margin: 0;
    width: 85px;
    height: 121px;
  }
}
</style>
