<template>
  <div
    v-if="statistic.length && this.team.num !== undefined"
    class="statistics-wrapper"
  >
    <div class="statistics-inner-wrapper">
      <div class="statistics">
        <h2 class="statistics__title" v-html="$lui('statistics')"></h2>

        <ChartOptionsWrapper dark-mode>
          <div>
            <template v-for="(block, i) in blocks">
              <StatisticsBlock
                :key="i"
                :double-mode="!!statisticsMeta.secondStatObject"
                :title="block.title"
              >
                <StatisticsBlockContent
                  :block="block"
                  :statistics-object="statisticsMeta.firstStatObject"
                  :title="statisticsMeta.firstTitle"
                />

                <StatisticsBlockContent
                  v-if="statisticsMeta.secondStatObject"
                  :block="block"
                  :statistics-object="statisticsMeta.secondStatObject"
                  :title="statisticsMeta.secondTitle"
                />
              </StatisticsBlock>

              <div
                v-if="i !== blocks.length - 1"
                :key="`${i}-sep`"
                class="statistics-separator"
              ></div>
            </template>
          </div>
        </ChartOptionsWrapper>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ChartOverallRating,
  ChartStrategy,
  ChartBonuses,
  ChartAttackResistance,
  ChartAttackCounteraction,
  ChartOptionsWrapper
} from 'kips-game-statistic-frontend'
import StatisticsBlock from './StatisticsBlock.vue'
import StatisticsBlockContent from './StatisticsBlockContent.vue'
import { mapGetters } from 'vuex'
import { STATISTICS_DATA_KEYS } from '../constants/statistics'

export default {
  components: {
    ChartOptionsWrapper,
    StatisticsBlock,
    StatisticsBlockContent
  },

  computed: {
    ...mapGetters({
      statistic: 'session/statistic',
      team: 'session/team'
    }),

    firstStatResponseObject() {
      return this.statistic[0]
    },

    secondStatResponseObject() {
      return this.statistic[1]
    },

    statisticsMeta() {
      // check if `team` key exists.
      // if it is: show team + game data
      // if not: show game data + game to compare data (if second stat object exists)
      const teamAndGameStatsMode =
        !!this.firstStatResponseObject?.overallRating[0]?.team

      return teamAndGameStatsMode
        ? {
            firstTitle: this.$lui('statistics.teamResults'),
            secondTitle: this.$lui('statistics.currentGame'),
            firstStatObject: this.constructedTeamStatData,
            secondStatObject: this.firstStatResponseObject
          }
        : {
            firstTitle: this.$lui('statistics.currentGame'),
            secondTitle: this.$lui('statistics.gameToCompare'),
            firstStatObject: this.firstStatResponseObject,
            secondStatObject: this.secondStatResponseObject
          }
    },

    constructedTeamStatData() {
      const findTeamValue = teams =>
        teams.find(team => team.num === this.team.num).value

      const { bonuses, uselessBudgetWaste, overallRating, ...otherKeys } =
        this.firstStatResponseObject

      const otherKeysValues = Object.fromEntries(
        Object.entries(otherKeys).map(([statsValueKey, value]) => [
          statsValueKey,
          value.map(item => ({
            name: item.name,
            value: findTeamValue(item.team)
          }))
        ])
      )

      return {
        bonuses,

        uselessBudgetWaste: {
          value: findTeamValue(uselessBudgetWaste.team)
        },

        overallRating: overallRating.map(x => ({
          gameAverage: findTeamValue(x.team)
        })),

        ...otherKeysValues
      }
    },

    blocks() {
      return [
        {
          component: ChartOverallRating,
          title: this.$lui('statistics.overall'),
          data: statObject => statObject.overallRating,
          max: () => this.getMax(STATISTICS_DATA_KEYS.OVERALL_RATING),
          locale: {
            idealScenario: this.$lui('statistics.idealScenario'),
            industryAverage: this.$lui('statistics.industryAverage'),
            gameAverage: this.$lui('statistics.gameAverage'),
            worstScenario: this.$lui('statistics.worstScenario'),
            turn: this.$lui('statistics.turn'),
            turns: this.$lui('statistics.turns'),
            income: this.$lui('statistics.income')
          }
        },
        {
          component: ChartAttackCounteraction,
          title: this.$lui('statistics.counter'),
          data: statObject => statObject.attackCounter,
          max: () => this.getMax(STATISTICS_DATA_KEYS.ATTACK_COUNTER),
          locale: {
            ideal: this.$lui('statistics.ideal'),
            acceptable: this.$lui('statistics.acceptable'),
            weak: this.$lui('statistics.weak'),
            worst: this.$lui('statistics.worst'),
            attack: this.$lui('statistics.attack'),
            attacks: this.$lui('statistics.attacks'),
            teamCount: this.$lui('statistics.teamCount')
          }
        },
        {
          component: ChartAttackResistance,
          title: this.$lui('statistics.resistance'),
          data: statObject => statObject.attackResistance,
          locale: {
            attack: this.$lui('statistics.attack'),
            attacks: this.$lui('statistics.attacks'),
            resistance: this.$lui('statistics.resistance')
          }
        },
        {
          component: ChartStrategy,
          title: this.$lui('statistics.strategy'),
          data: statObject => statObject.strategy,
          max: () => this.getMax(STATISTICS_DATA_KEYS.STRATEGY),
          textData: true,
          locale: {
            proactive: this.$lui('statistics.proactive'),
            reactive: this.$lui('statistics.reactive'),
            useless: this.$lui('statistics.useless'),
            attack: this.$lui('statistics.attack'),
            attacks: this.$lui('statistics.attacks'),
            cardsPlayedPercent: this.$lui('statistics.cardsPlayedPercent')
          }
        },
        {
          component: ChartBonuses,
          title: this.$lui('statistics.bonuses'),
          data: statObject => statObject.bonuses,
          max: () => this.getMax(STATISTICS_DATA_KEYS.BONUSES),
          locale: {
            bonuses: this.$lui('statistics.bonuses'),
            team: this.$lui('statistics.team')
          }
        }
      ]
    }
  },

  methods: {
    getMax(key) {
      const { firstStatObject, secondStatObject } = this.statisticsMeta

      if (!secondStatObject) {
        return
      }

      if (key === STATISTICS_DATA_KEYS.BONUSES) {
        const number = Math.max(
          ...firstStatObject[key],
          ...secondStatObject[key]
        )
        return Math.ceil(number / 10) * 10
      }

      const _getFlat = arr => {
        return arr
          .map(item => {
            switch (typeof item.value) {
              case 'number':
                return item.value
              case 'object':
                return Object.values(item.value)
              case 'undefined':
                return Object.values(item).filter(
                  value => typeof value === 'number'
                )
            }
          })
          .flat()
      }

      const firstMax = Math.max(..._getFlat(firstStatObject[key]))
      const secondMax = Math.max(..._getFlat(secondStatObject[key]))

      return Math.ceil(Math.max(firstMax, secondMax) / 10) * 10
    }
  }
}
</script>

<style lang="scss">
.statistics-wrapper {
  overflow-x: hidden;
  position: fixed;
  top: 0;
  z-index: 100;
  left: 0;
  background-color: #4b647c;
  z-index: 100;
  left: 0;
  width: 100%;
  overflow-y: auto;
  height: 100%;
}

.statistics-inner-wrapper {
  position: absolute;
  top: 20%;
  left: 50%;
  width: 80%;
  padding-bottom: 100px;
}

.statistics {
  color: white !important;
  border-radius: 30px;
  padding-bottom: 60px;
  background: #3c5063;
  border: 1px solid rgba(0, 0, 0, 0.6);
  transform: translateX(-50%);

  &::before,
  &::after {
    width: 5px;
    position: absolute;
    height: 5px;
    display: block;
    content: '';
    background: #6b91b6;
    border: 1px solid #000000;
    box-shadow: 1px 1px 0px 2px #3c5063;
    border-radius: 3px;
  }

  &::before {
    top: 0;
    left: 0;
  }

  &::after {
    top: 0;
    right: 0;
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    margin-top: 12px;
    margin-left: 20px;
    margin-bottom: 12px;
    line-height: 190%;
    color: #ffffff;
    text-shadow: -1px 1px 0px #000000;
  }

  @media screen and (max-width: 1280px) {
    width: 95%;
  }
}

.statistics-separator {
  border-top: dashed 3px;
  margin: 50px 0;
}
</style>
