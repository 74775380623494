<template>
  <div class="num" v-if="item" dir="ltr">
    <img
      v-for="(src, index) in getImgUrl()"
      :key="index"
      :src="src"
      alt=""
      dir="ltr"
    />
  </div>
</template>

<script>
export default {
  props: {
    item: {}
  },

  methods: {
    getImgUrl() {
      let color = 'L'
      let num

      switch (this.item.type) {
        case 'info':
          color = 'B'
          break
        case 'one_time':
          color = 'G'
          break
        case 'reusable':
          color = 'O'
          break
      }

      let arrWithSrc = []
      if (this.item.cardNumber) {
        if (this.item.cardNumber / 10 < 1) {
          num = this.item.cardNumber
          let src = require(`../../assets/atlas/${color}${num}.png`)
          arrWithSrc.push(src)
        } else {
          let arrNums = Array.from(this.item.cardNumber.toString()).map(Number)
          for (let i = 0; i < arrNums.length; i++) {
            num = arrNums[i]
            let src = require(`../../assets/atlas/${color}${num}.png`)
            arrWithSrc.push(src)
          }
        }
        return arrWithSrc
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.num {
  width: 30px;
  height: 13px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    height: 100%;

    &[dir='ltr'] {
      transform: scaleX(1);
    }
  }
}
</style>
