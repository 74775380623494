import tokenStorage from './tokenStorage'
import AUTH_TYPES from '../constants/authTypes'
import authTypes from '../constants/authTypes'

export const AuthQueryInterface = {
  type: null,
  hash: null,
  team_id: null,
  game_id: null
}

export default class AuthorizationService {
  constructor(
    gameServerClient,
    { hash, team_id: teamId, game_id: gameId, type = authTypes.BY_LINK }
  ) {
    this.gameServerClient = gameServerClient
    this.gameInvitationHash = hash
    this.teamId = teamId
    this.gameId = gameId
    this.authType = type
  }

  canEstablishConnection() {
    return !!this.gameInvitationHash
  }

  async connect(credentials = null) {
    let body = null

    switch (this.authType) {
      case AUTH_TYPES.BY_LINK:
      case AUTH_TYPES.BY_EMAIL:
        body = { team_id: this.gameInvitationHash, ...credentials }
        break

      case AUTH_TYPES.SELF_REGISTRATION:
        body = { game_id: this.gameInvitationHash }
        break

      case AUTH_TYPES.INFO_CONSOLE_TEAM:
        body = { team_id: this.teamId, info_token: this.gameInvitationHash }
        break

      case AUTH_TYPES.INFO_CONSOLE_GAME:
        body = { game_id: this.gameId, info_token: this.gameInvitationHash }
        break

      default:
        return null
    }

    const {
      data: { jwt: token, game, team, player } = {},
      headers: { date } = {}
    } = await this.gameServerClient.connect(body)

    this.gameServerClient.setToken(token)
    tokenStorage.saveTokenForHash(this.gameInvitationHash, token)

    return { game, team, player, date }
  }

  async attemptReconnect() {
    if (!this.gameInvitationHash) {
      return null
    }

    const tokenForHash = tokenStorage.getTokenForHash(this.gameInvitationHash)

    if (!tokenForHash) {
      return null
    }

    this.gameServerClient.setToken(tokenForHash)

    try {
      const { data: { game, team, player } = {}, headers: { date } = {} } =
        await this.gameServerClient.reconnect()

      return { game, team, player, date }
    } catch (e) {
      tokenStorage.dropTokenFromTokensStore(this.gameInvitationHash)
      this.gameServerClient.setToken(null)
      return null
    }
  }

  getAuthType() {
    return this.authType
  }
}
