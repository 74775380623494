export const GameStatesMap = {
  None: 'none',
  New: 'new',
  GameIntro: 'game_intro',
  PhaseNews: 'phase_news',
  PhaseActionBegin: 'phase_action_begin',
  PhaseAction: 'phase_action',
  PhaseActionEnd: 'phase_action_end',
  PhaseReporting: 'phase_reporting',
  AfterBonus: 'after_bonus_slides',
  AfterBonusResult: 'after_bonus_result',
  AfterUnveil: 'after_unveil',
  AfterLessonBegin: 'after_lesson_begin',
  AfterLesson: 'after_lesson',
  AfterStatistic: 'after_statistic',
  AfterIdeal: 'after_ideal',
  AfterPoll: 'after_poll',
  Finished: 'finished'
}

const GameStates = {
  New: 1,
  GameIntro: 2,
  PhaseNews: 3,
  PhaseActionBegin: 4,
  PhaseAction: 5,
  PhaseActionEnd: 6,
  PhaseReporting: 7,
  AfterBonus: 8,
  AfterBonusResult: 9,
  AfterUnveil: 10,
  AfterLessonBegin: 11,
  AfterLesson: 12,
  AfterStatistic: 13,
  AfterIdeal: 14,
  AfterPoll: 15,
  Finished: 16,
  0: GameStatesMap.None,
  1: GameStatesMap.New,
  2: GameStatesMap.GameIntro,
  3: GameStatesMap.PhaseNews,
  4: GameStatesMap.PhaseActionBegin,
  5: GameStatesMap.PhaseAction,
  6: GameStatesMap.PhaseActionEnd,
  7: GameStatesMap.PhaseReporting,
  8: GameStatesMap.AfterBonus,
  9: GameStatesMap.AfterBonusResult,
  10: GameStatesMap.AfterUnveil,
  11: GameStatesMap.AfterLessonBegin,
  12: GameStatesMap.AfterLesson,
  13: GameStatesMap.AfterStatistic,
  14: GameStatesMap.AfterIdeal,
  15: GameStatesMap.AfterPoll,
  16: GameStatesMap.Finished
}

export default GameStates
