<template>
  <div class="cards__modal" :class="{ show: activeModal }">
    <div class="cards__modal_background" @click="closeModal"></div>
    <div class="cards_container">
      <div
        ref="cardsSlider"
        class="cards-inner-wrapper cards-slider"
        :class="{ 'cards-slider--in-drag': inDrag }"
        :style="cardSliderStyles"
        @mouseup="endDrag"
        @mousedown="startDrag"
        @mouseleave="endDrag"
        @mousemove="moveMouseHandler"
      >
        <div
          v-for="card in getCardFromHand"
          :key="card.id"
          class="cards__modal-act"
        >
          <div
            v-if="isCaptain && !choiceIsDone"
            ref="actBtn"
            class="cards__modal-act__btn"
            @click="removeCard(card.id)"
          ></div>
          <GameCard
            class="cards__modal-item"
            :item="card"
            :inZoomInHand="true"
          />
        </div>
      </div>
    </div>

    <div v-if="isCardsSliderEnabled" class="cards-slider__buttons" dir="ltr">
      <div
        class="cards-slider__button cards-slider__button--left"
        @click="changeCardsLeftShift(+cardsLeftShiftStep)"
      ></div>
      <div
        class="cards-slider__button cards-slider__button--right"
        @click="changeCardsLeftShift(-cardsLeftShiftStep)"
      ></div>
    </div>
  </div>
</template>

<script>
import GameCard from '../Cards/GameCard.vue'
import { mapGetters, mapActions } from 'vuex'
import debug from './../../utils/debug'

const CARD_WIDTH = 330

export default {
  name: 'PopupGameInHand',
  components: {
    GameCard
  },

  props: {
    dataActiveCard: {}
  },

  data() {
    return {
      activeModal: false,
      modalButton: true,
      cardsLeftShift: 0,
      cardsLeftShiftStep: 600,
      inDrag: false,
      prevMousePageX: 0
    }
  },

  computed: {
    ...mapGetters({
      isCaptain: 'session/getIsCaptain',
      getCardFromHand: 'cards/UICardsInHand',
      choiceIsDone: 'cards/choiceIsDone'
    }),

    isCardsSliderEnabled() {
      return this.getCardFromHand.length * CARD_WIDTH >= window.innerWidth
    },

    cardSliderStyles() {
      const { isCardsSliderEnabled, cardsLeftShift } = this

      return Object.assign(
        {},
        isCardsSliderEnabled && { left: `${cardsLeftShift}px` }
      )
    }
  },

  watch: {
    activeModal(active) {
      if (active) {
        setTimeout(() => {
          this.checkCardsLeftShiftEdgePosition()
        }, 100)
      }
    },

    getCardFromHand() {
      setTimeout(() => {
        this.checkCardsLeftShiftEdgePosition()
      }, 150)
    }
  },

  methods: {
    ...mapActions(['cards/removeCardFromHand']),
    show() {
      this.activeModal = true
    },

    closeModal: function () {
      this.activeModal = false
    },

    removeCard: function (id) {
      if (this.isCaptain && !this.choiceIsDone) {
        if (this.getCardFromHand.length <= 1) {
          this.closeModal()
        }
        this['cards/removeCardFromHand'](id)
      }
    },

    changeCardsLeftShift(delta) {
      this.cardsLeftShift = this.cardsLeftShift + delta
      this.checkCardsLeftShiftEdgePosition()
    },

    checkCardsLeftShiftEdgePosition() {
      const cardsLineWidth = this.$refs.cardsSlider.clientWidth
      const screenWidth = window.innerWidth
      const cardsLineActiveWidth = cardsLineWidth - screenWidth + CARD_WIDTH

      const min = -cardsLineActiveWidth / 2
      const max = cardsLineActiveWidth / 2

      this.cardsLeftShift = Math.max(Math.min(this.cardsLeftShift, max), min)
    },

    startDrag(event) {
      if (!this.isCardsSliderEnabled) {
        return
      }

      this.prevMousePageX = event.pageX

      this.inDrag = true
      debug.game.action('drag:start')
    },

    endDrag() {
      if (!this.isCardsSliderEnabled) {
        return
      }

      this.inDrag = false
      debug.game.action('drag:end')
      this.checkCardsLeftShiftEdgePosition()
    },

    moveMouseHandler(event) {
      if (this.inDrag && this.isCardsSliderEnabled) {
        const deltaX = event.pageX - this.prevMousePageX
        this.changeCardsLeftShift(deltaX)

        this.prevMousePageX = event.pageX
      }
    }
  },

  mounted() {
    this.$root.cardModalInHand = this
  }
}
</script>

<style lang="scss" scoped>
$b: '.cards';

#{$b} {
  &_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 305;
  }

  &-inner-wrapper {
    display: flex;
    width: fit-content;
    position: relative;
  }

  &__modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: none;
    overflow: hidden;

    &_background {
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.989233193277311) 0%,
        rgba(0, 0, 0, 0.1516981792717087) 100%
      );
      z-index: 180;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &.show {
      display: flex;
    }

    &-btn {
      width: 209px;
      height: 67px;
      border: none;
      background-color: transparent;
      color: white;
      background-image: url(../../assets/header/info-block-bg-green.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      transform: translate(-50%, -50%);
      bottom: 12%;
      left: 50%;

      &:hover {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }

    &-item {
      top: 50%;
      left: 50%;
      position: absolute;
      z-index: 215;
    }

    &-act {
      z-index: 300;
      position: relative;
      width: 302.5px;
      height: 425px;
      overflow: hidden;
      margin-right: 25px;

      &__btn {
        width: 55px;
        height: 55px;
        border-bottom-left-radius: 95%;
        background-color: rgba($color: #000000, $alpha: 0.5);
        position: absolute;
        top: -1px;
        right: 1px;
        z-index: 310;
        background-image: url(../../assets/cards/basket.svg);
        background-size: 22px;
        background-position: 23px 12px;
        background-repeat: no-repeat;
        cursor: pointer;
        transition: all 0.1s ease-in;

        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.7);
        }
      }
    }
  }
}

.cards-slider {
  &:not(&--in-drag) {
    transition: left 0.35s;
  }

  &__buttons {
    position: absolute;
    top: 80%;
    left: 50%;
    z-index: 200;
    display: flex;
  }

  &__button {
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-size: contain;

    &--left {
      margin-right: 10px;
      background-image: url(../../assets/arrow-left-white.svg);
    }
    &--right {
      background-image: url(../../assets/arrow-right-white.svg);
    }
  }
}

.cards-slider__buttons {
}

.from-hand {
  #{$b}__modal-act__btn {
    background-image: url(../../assets/cards/basket.svg);
  }
}
</style>
