<template>
  <div class="statistics-useless-budget-waste">
    <span class="statistics-useless-budget-waste-value">
      {{ value }}%&nbsp;
    </span>
    <span>{{ $lui('statistics.uselessBudget') }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Number, required: true }
  }
}
</script>

<style>
.statistics-useless-budget-waste {
  margin-top: 12px;
}

.statistics-useless-budget-waste-value {
  font-size: 24px;
}
</style>
