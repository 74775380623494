<template>
  <div>
    <Transition name="fade">
      <div
        v-if="!isInfoConsoleGame && currentSubPage == 'main'"
        class="content__block selectedBlock"
      >
        <GameTable class="GameTable" />
      </div>
    </Transition>
    <Transition name="fade">
      <div
        v-if="currentSubPage == 'cards'"
        class="content__block selectedBlock"
      >
        <GameCards />
      </div>
    </Transition>
    <Transition name="fade">
      <div v-if="currentSubPage == 'news'" class="content__block selectedBlock">
        <NewNews ref="newsBlock" />
      </div>
    </Transition>
    <Transition name="fade">
      <div
        class="content__block"
        :class="{ selectedBlock: currentSubPage == 'stats' }"
      >
        <ResultComponent ref="resultComponent" />
      </div>
    </Transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import GameTable from '../components/Cards/GameTable.vue'
import GameCards from '../components/Cards/GameCards.vue'
import NewNews from '../components/News/NewNews.vue'
import ResultComponent from '../components/Results/ResultsComponent.vue'

export default {
  name: 'NavigationLogicComponent',
  components: {
    ResultComponent,
    NewNews,
    GameCards,
    GameTable
  },

  data() {
    return {
      selectedId: null,
      visibility: true
    }
  },

  computed: mapGetters({
    currentSubPage: 'session/currentSubPage',
    isInfoConsoleGame: 'session/isInfoConsoleGame'
  }),

  methods: {
    rebuildDiagram() {
      this.$refs.resultComponent.rebuildDiagram()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  &__block {
    transition: all ease-in 0.1s;
    display: none;
  }
}

.selectedBlock {
  display: block;
}

.active {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
