<template>
  <div v-if="statisticsObject" class="statistics-block-content">
    <Component
      :is="block.component"
      :data="block.data(statisticsObject)"
      :max="block.max && block.max()"
      :locale="block.locale"
      :title="title"
    />
    <StatisticsUselessWaste
      v-if="block.textData"
      :value="statisticsObject.uselessBudgetWaste?.value"
    />
  </div>
</template>

<script>
import StatisticsUselessWaste from './StatisticsUselessWaste.vue'

export default {
  components: {
    StatisticsUselessWaste
  },

  props: {
    block: { type: Object, required: true },
    statisticsObject: { type: Object, required: true },
    title: { type: String, required: true }
  }
}
</script>

<style lang="scss" scoped>
.statistics-block-content {
  display: flex;
  flex-direction: column;

  .chart-wrapper {
    flex: 1;
  }
}
</style>
