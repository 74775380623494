<template>
  <div class="start-news" :style="getScaleStyle()">
    <h2 class="start-news__title">
      {{ $lui('intro-screen.title') }}
    </h2>

    <div class="start-news__info">
      <h3 class="start-news__info-title">
        <CompanyTitleText />
      </h3>

      <p class="start-news__corporate-title">
        {{ sessionName }}
      </p>

      <img
        class="start-news__line"
        src="../../assets/news/start-news-line.svg"
        alt=""
      />

      <div
        dir="ltr"
        :style="{ transform: `scale(calc(1 / ${scaleTable} / 1.5))` }"
      >
        <AppBranding :kasperskyLogoWidth="95">
          <template #before-default-logo>
            <p class="start-news__dev-info">
              {{ $lui('intro-screen.developed-by') }}
            </p>
          </template>
        </AppBranding>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import scaleTable from '../../mixins/scaleTable'
import AppBranding from '../AppBranding.vue'
import CompanyTitleText from '../CompanyTitleText.vue'

export default {
  components: {
    AppBranding,
    CompanyTitleText
  },

  mixins: [scaleTable],

  computed: mapGetters({
    sessionName: 'session/sessionName',
    branding: 'session/branding'
  }),

  methods: {
    setBlockScale() {
      let resultHeight = (window.innerHeight - 70 - 67) * 0.97
      let resultScaleH = resultHeight / 750

      let resultWidth = window.innerWidth * 0.85
      let resultScaleW = resultWidth / 906

      this.scaleTable = Math.min(resultScaleH, resultScaleW)
    }
  }
}
</script>

<style lang="scss" scoped>
.start-news {
  width: 908px;
  height: 716px;
  background-image: url(../../assets/news/start-news-bg.png);
  background-repeat: no-repeat;
  background-size: cover;

  &__title {
    margin: 5px 42px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 190%;
    color: #ffffff;
    text-shadow: -1px 1px 0px #000000;
  }

  &__info {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5);
  }

  &__info-title {
    margin-bottom: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 116%;
    text-align: center;
    letter-spacing: 0.03em;
    color: #006c5b;
  }

  &__corporate-title {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 51px;
    line-height: 100%;
    text-align: center;
    color: #3c3c3b;
  }

  &__line {
    margin: 0 auto 40px;
    width: 273px;
  }

  &__dev-info {
    font-style: normal;
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 16px;
    line-height: 231%;
    text-align: center;
    color: #006c5b;
  }

  &__logos {
    display: flex;
    align-items: center;
    justify-content: space-around;

    img {
      margin: 0;
    }
  }

  &__white-label-logo {
    margin: 0 auto;
  }

  &__logo {
    margin: 0 auto;
    width: 96px;
    height: 18px;
  }

  &__image[dir='ltr'] {
    transform: scaleX(1);
  }
}
</style>
