<template>
  <div class="cards" :style="getScaleStyle()">
    <div class="cards__wrapper">
      <template v-for="(item, i) in getCards">
        <Transition name="fade" :key="i">
          <div v-if="!item.hide" class="cards__item">
            <GameCard class="cards__container" :item="item" />
          </div>
        </Transition>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GameCard from './GameCard.vue'
import scaleTable from '../../mixins/scaleTable'

export default {
  name: 'GameCards',
  components: {
    GameCard
  },

  mixins: [scaleTable],
  data() {
    return {
      scaleTableByXPercent: 45,
      scaleTableByYPercent: 42
    }
  },

  computed: mapGetters({
    getCards: 'cards/UICardsAvailable'
  }),

  methods: {
    setBlockScale() {
      let heightFooterAndHeader = 70 + 67
      let indentY = 0.99
      let neededHeight = 620

      let resultHeigth = (window.innerHeight - heightFooterAndHeader) * indentY
      let resultScaleH = resultHeigth / neededHeight

      let indentX = 0.9
      let neededWidth = 1210

      let resultWidth = window.innerWidth * indentX
      let resultScaleW = resultWidth / neededWidth

      this.scaleTable = Math.min(resultScaleH, resultScaleW)
    }
  }
}
</script>

<style lang="scss" scoped>
$b: '.cards';

#{$b} {
  z-index: 14;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 1210px;
  height: 690px;
  padding: 0 0 80px;
  -ms-overflow-style: none;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
  }

  &__item {
    display: flex;
    justify-content: center;
    width: calc(100% / 8);
    margin-bottom: 15px;
  }

  .fade-leave-active {
    transform: translateY(10px);
    opacity: 0;
    transition: all 0.5s ease-in;
  }

  .fade-enter {
    transform: translateY(10px);
  }
}
</style>
