import {
  differenceInMilliseconds,
  addMilliseconds,
  differenceInSeconds
} from 'date-fns'
import debug from './debug'

let timeDeltaInMilliseconds = 0
export const syncWithServerTime = serverDate => {
  timeDeltaInMilliseconds = differenceInMilliseconds(new Date(), serverDate)
  debug.system('timeDeltaInMilliseconds', timeDeltaInMilliseconds)
}

export const getRemainingSeconds = before => {
  const beforeSynced = addMilliseconds(
    new Date(before),
    timeDeltaInMilliseconds
  )

  return differenceInSeconds(beforeSynced, new Date())
}
