<template>
  <div class="timer">
    <img
      class="timer__low-part"
      src="../../assets/header/timer-low-part.png"
      alt=""
    />
    <div class="timer__time">
      <span class="timer__min">{{ timeFromate().minutes }}</span>
      <span class="timer__dots">:</span>
      <span class="timer__sec">{{ timeFromate().seconds }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: mapGetters({
    timerTime: 'session/timerTime'
  }),

  methods: {
    timeFromate() {
      let timeInSec = this.timerTime
      let min = Math.floor(timeInSec / 60)
      let sec = timeInSec - min * 60

      return {
        minutes: min >= 10 ? min : '0' + min,
        seconds: sec >= 10 ? sec : '0' + sec
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.timer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 54px;
  background-image: url(../../assets/header/timer-bg.png);
  background-repeat: no-repeat;
  background-size: contain;

  &__low-part {
    position: absolute;
    top: 45%;
    left: 4px;
    width: 122px;
  }

  &__time {
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 44px;
    color: #000000;
    text-shadow: -1px 1px 0px #fbdf95;
    display: flex;
    justify-content: center;
    width: 98px;
  }
}
</style>
