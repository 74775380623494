<template>
  <div class="teamlist">
    <div class="teamlist__content">
      <div class="teamlist__close-btn" @click="closePopup"></div>
      <div class="teamlist__header">{{ $lui('team-list.title') }}</div>
      <div class="teamlist__players">
        <div
          v-for="(player, index) in getTeamPlayers()"
          :key="index"
          class="teamlist__player"
        >
          <div class="teamlist__player-nickname">
            {{ index + 1 }}. {{ player.nickname }}
          </div>
          <div v-if="player.is_captain" class="teamlist__captain"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TeamList',
  data() {
    return {}
  },

  computed: {
    ...mapGetters({
      isCaptain: 'session/getIsCaptain',
      team: 'session/team'
    })
  },

  methods: {
    closePopup() {
      this.$store.commit('session/HIDE_MODAL')
    },

    getTeamPlayers() {
      return Object.values(this.team.players).map(player => {
        return {
          nickname: player.nickname,
          is_captain: player.is_captain
        }
      })
    }
  },

  mounted() {}
}
</script>

<style lang="scss" scoped>
.teamlist {
  width: 100%;
  height: 100vh;
  background-color: rgba(25, 35, 44, 0.7);

  &__header {
    margin: 0 auto 29px;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #542720;
  }

  &__players {
    overflow-y: scroll;
    scrollbar-color: #b99166 #eab183;
    scrollbar-width: thin;
    height: calc(100% - 50px);

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: #eab183;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #b99166;
    }
  }

  &__player {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(84, 39, 32, 0.2);
    word-break: break-all;
  }

  &__player-nickname {
    width: 80%;
  }

  &__captain {
    background-image: url(../../assets/header/crown.svg);
    width: 18px;
    height: 14px;
  }

  &__content {
    width: 25%;
    height: 60vh;
    padding: 40px 60px;
    background-image: url(../../assets/teamlist/teamlist-popup-center.png);
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    &:before {
      content: '';
      display: block;
      background-image: url(../../assets/teamlist/teamlist-popup-top.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
      height: 20px;
    }

    &:after {
      content: '';
      display: block;
      background-image: url(../../assets/teamlist/teamlist-popup-bottom.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 100%;
      height: 20px;
    }
  }

  &__close-btn {
    width: 31px;
    height: 31px;
    position: absolute;
    right: 1.5%;
    top: -16px;
    cursor: pointer;
    background-image: url(../../assets/close-btn.svg);
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
