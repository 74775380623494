<template>
  <div class="in-hand">
    <div
      v-for="(item, i) in getCardFromHand"
      :key="i"
      class="in-hand__card"
      :style="`position:relative; z-index: -${i * 1}; margin-left: ${
        i > 0 ? -65 : 0
      }px;`"
    >
      <Card :from="'table'" :item="item" />
    </div>
    <div class="open-modal-area pointer" @click="openModal"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Card from './GameCard.vue'

export default {
  components: {
    Card
  },

  methods: {
    openModal() {
      this.$root.cardModalInHand.show()
    }
  },

  computed: mapGetters({
    getCardFromHand: 'cards/UICardsInHand'
  })
}
</script>

<style lang="scss" scoped>
.open-modal-area {
  position: absolute;
  width: 100%;
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.in-hand {
  display: flex;
  transition: all 0.05s ease-in;

  &:hover {
    transform: translateY(-15px);
  }

  &__card {
    position: absolute;
    top: 0;
    left: 0;
    animation: bounce-in 1s;
  }
}

@keyframes bounce-in {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  25% {
    transform: translateY(-10px);
    opacity: 0;
  }
  50% {
    transform: translateY(-10px);
    opacity: 0;
  }
  50% {
    transform: translateY(-10px);
    opacity: 0;
  }
  60% {
    transform: translateY(-8px);
    opacity: 0.3;
  }
  70% {
    transform: translateY(-6px);
    opacity: 0.5;
  }
  80% {
    transform: translateY(-4px);
    opacity: 0.6;
  }
  90% {
    transform: translateY(-2px);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
