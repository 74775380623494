<template>
  <div class="legal-notices" v-if="opened">
    <div class="legal-notices__overlay" @click="emitClose"></div>

    <div class="legal-notices__wrap" :style="getScaleStyle()" dir="ltr">
      <div class="legal-notices__wrap-decor" @click="emitClose">
        <div></div>
      </div>

      <div class="legal-notices__content-wrapper">
        <pre class="legal-notices__content">{{ legalNoticesText }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import scaleTable from '../mixins/scaleTable'
import legalNoticesText from '../assets/legal_notices.txt'

export default {
  mixins: [scaleTable],

  props: {
    opened: { type: Boolean, default: false }
  },

  data() {
    return {
      legalNoticesText
    }
  },

  methods: {
    setBlockScale() {
      let sizeW = 1280
      let sizeH = 720
      let winH = window.innerHeight
      let winW = window.innerWidth

      let margin = 60

      let factorW = 1
      let factorH = 1
      if (winW - sizeW < margin) {
        factorW = (winW - margin) / sizeW
      }
      if (winH - sizeH < margin) {
        factorH = (winH - margin) / sizeH
      }

      this.scaleTable = Math.min(factorH, factorW)
    },

    emitClose() {
      this.$emit('update:opened', false)
    }
  }
}
</script>

<style lang="scss">
.legal-notices__content-wrapper {
  width: calc(100% - 16px);
  position: absolute;
  height: calc(100% - 16px);
  background: white;
  z-index: 100;
  top: 8px;
  border: 1px solid #000000;
  left: 8px;
  display: flex;
  flex-direction: column;
}

.legal-notices__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.legal-notices__content {
  white-space: pre-wrap;
  overflow-y: auto;
  flex-grow: 1;
  padding: 100px;
  user-select: text;
  margin: 0;

  scrollbar-color: #b99166 #eab183;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #eab183;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b99166;
  }
}

.legal-notices {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  &__wrap {
    background: linear-gradient(180deg, #eab183 49.99%, #b99166 50%);
    border: 2px solid #000000;
    border-radius: 2px;
    padding: 8px;
    position: absolute;
    width: 1280px;
    height: 720px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 30;

    &-decor {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 15;

      div {
        width: 100%;
        height: 100%;
        position: relative;

        &:before,
        &:after {
          position: absolute;
          content: '';
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          top: 0;
        }

        &:before {
          background-image: url(../assets/wires_left.svg);
          background-position: center left;
          left: -55.5%;
        }

        &:after {
          background-position: center right;
          background-image: url(../assets/wires_right.svg);
          right: -54.2%;
        }
      }
    }
  }
}
</style>
