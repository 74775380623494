<template>
  <div v-if="isShowTrainerView" class="trainer-view">
    <div class="trainer-view__wrap" :style="getScaleStyle()" dir="ltr">
      <div class="trainer-view__wrap-decor">
        <div></div>
      </div>
      <div class="trainer-view__wrap-substrate">
        <div class="trainer-view__wrap-substrate-loader">
          <LoaderIndicator />
        </div>
      </div>

      <template v-if="isBonuses">
        <div
          v-if="hasBonuses"
          class="bonus_result__container content_container"
        >
          <div class="bonus_result__header">
            <div class="bonus_result__header_headline">
              {{ $lui('bonus-results.main-headline') }}
            </div>
            <div class="bonus_result__header_headline_sub">
              {{ $lui('bonus-results.amount-headline') }}
            </div>
          </div>
          <div
            class="bonus_result__line_visual bonus_result__line_visual_relative"
          ></div>
          <div
            v-for="(bonusLine, i) in bonusLines"
            :key="i"
            class="bonus_result__line"
          >
            <div
              v-if="trainerWindow.type == 'bonus_slides'"
              class="bonus_result__line__header"
            >
              {{
                $lc(bonusMessages[bonusLine.bonusMessageId].description) || ''
              }}
            </div>
            <div
              v-else-if="trainerWindow.type == 'bonus_results'"
              class="bonus_result__line__header"
            >
              {{ $lc(bonusMessages[bonusLine.bonusMessageId].message) || '' }}
            </div>

            <div class="bonus_result__line__money" dir="ltr">
              {{ currency }} {{ numberWithSpaces(bonusLine.revenue) }}
            </div>
            <div class="bonus_result__line_visual"></div>
          </div>
        </div>

        <div
          v-else
          class="bonus_result__container content_container bonus_result__container_no_bonus_case"
        >
          {{ $lui('bonus-results.empty-bonuses') }}
        </div>
      </template>

      <Slider
        v-if="
          trainerWindow.type == 'slides' &&
          currentSlide &&
          (currentSlide.image_url || currentSlide.video_url)
        "
        v-model="trainerWindow.slide"
        animation="normal"
        :indicators="false"
        :autoplay="false"
        :control-btn="false"
        :speed="1000"
        height="100%"
      >
        <SliderItem v-for="(item, idx) in trainerSlides" :key="idx">
          <div class="slider-item__container">
            <div class="slider-item__content-block">
              <img
                v-if="item.image_url"
                class="slider-item__wrap"
                :src="getLocalizedUrl(item.image_url)"
                dir="ltr"
              />
              <div
                v-else-if="item.video_url"
                class="slider-item__wrap slides__video-wrap"
                @click.prevent="playVideo"
              >
                <video
                  :ref="`video_${idx}`"
                  class="slides__video"
                  :src="getLocalizedUrl(item.video_url)"
                  controls
                  @ended="onVideoEnds"
                ></video>
              </div>
            </div>

            <div class="slider-item__branding">
              <AppBranding />
            </div>
          </div>
        </SliderItem>
      </Slider>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Slider, SliderItem } from 'vue-easy-slider'
import scaleTable from '../mixins/scaleTable'
import LoaderIndicator from './LoaderIndicator'
import debug from './../utils/debug'
import AppBranding from './AppBranding.vue'

export default {
  components: {
    Slider,
    SliderItem,
    LoaderIndicator,
    AppBranding
  },

  mixins: [scaleTable],
  data() {
    return {
      typeInfo: false,
      videoBtnPlay: true,
      videoStatus: false
    }
  },

  computed: {
    ...mapGetters({
      bonusTable: 'session/bonusTable',
      bonusMessages: 'session/bonusMessages',
      currentLocale: 'localizations/currentLocale',
      trainerSlides: 'session/trainerSlides',
      trainerWindow: 'session/trainerWindow',
      isInfoConsoleGame: 'session/isInfoConsoleGame',
      branding: 'session/branding',
      slidesVisibility: 'session/slidesVisibility',
      currency: 'session/currency'
    }),

    isShowTrainerView() {
      return (
        this.checkSlidesVisibility &&
        this.checkSliderForInfoConsole &&
        this.trainerWindow?.show
      )
    },

    checkSlidesVisibility() {
      return (
        this.checkSliderBonusesForPlayer ||
        this.slidesVisibilityForAll ||
        this.slidesVisibilityForPlayers ||
        this.slidesVisibilityForInfoconsole
      )
    },

    slidesVisibilityForAll() {
      return this.slidesVisibility === 'ALL'
    },

    slidesVisibilityForPlayers() {
      return this.slidesVisibility === 'PLAYERS' && !this.isInfoConsoleGame
    },

    slidesVisibilityForInfoconsole() {
      return this.slidesVisibility === 'INFOCONSOLE' && this.isInfoConsoleGame
    },

    checkSliderForInfoConsole() {
      return !(this.isInfoConsoleGame && this.isBonuses)
    },

    checkSliderBonusesForPlayer() {
      return !this.isInfoConsoleGame && this.isBonuses
    },

    isBonuses() {
      return (
        this.trainerWindow.type == 'bonus_results' ||
        this.trainerWindow.type == 'bonus_slides'
      )
    },

    hasBonuses() {
      return this.bonusTable?.length > 0
    },

    currentSlide() {
      const { trainerSlides, trainerWindow } = this
      return trainerSlides && trainerSlides[trainerWindow.slide]
    },

    bonusLines() {
      return this.bonusTable
        .map(({ bonusMessageId, revenue }) => ({ bonusMessageId, revenue }))
        .filter(({ bonusMessageId }) => !!this.bonusMessages[bonusMessageId])
    }
  },

  watch: {
    'trainerWindow.slide': {
      handler(p, c) {
        debug.slider.info('trainerWindow.slide', p, c)
        this.visibleBtnPlay = true
        this.videoStatus = false
      },

      deep: true
    }
  },

  methods: {
    playVideo: async function () {
      const refIndex = this.trainerSlides.findIndex(
        item => item === this.currentSlide
      )
      const videoComponent = this.$refs[`video_${refIndex}`][0]

      if (this.videoStatus === false) {
        this.videoBtnPlay = false
        this.videoStatus = true
        videoComponent.play()
      } else if (this.videoStatus === true) {
        this.videoBtnPlay = true
        this.videoStatus = false
        videoComponent.pause()
      }
    },

    onVideoEnds() {
      this.videoBtnPlay = true
      this.videoStatus = false
    },

    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },

    setBlockScale() {
      let sizeW = 1280
      let sizeH = 720
      let winH = window.innerHeight
      let winW = window.innerWidth

      let margin = 60

      let factorW = 1
      let factorH = 1
      if (winW - sizeW < margin) {
        factorW = (winW - margin) / sizeW
      }
      if (winH - sizeH < margin) {
        factorH = (winH - margin) / sizeH
      }

      this.scaleTable = Math.min(factorH, factorW)
    },

    getLocalizedUrl(urlObject) {
      return urlObject[this.currentLocale] || urlObject.default
    }
  }
}
</script>

<style lang="scss">
$branding-height: 70px;

.content_container {
  background: white;
  border: 1px solid black;

  &--video {
    background: black;
  }
}

.slides {
  &__container {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  &__content {
    height: 100%;
    width: 100%;

    &_headline {
      position: absolute;
      top: 46px;
      left: 96px;
      font-weight: bold;
      font-size: 28px;
      line-height: 39px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
    }
    &_small {
      position: absolute;
      padding: 135px 142px 0px 142px;
    }
  }

  &__video-wrap {
    position: relative;
    min-height: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  &__video {
    min-height: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  &__video-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
  }
}
.bonus_result {
  &__container {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 97px 231px 0px 231px;
    overflow-y: scroll;
    scrollbar-color: #b99166 #eab183;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: #eab183;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #b99166;
    }

    &_no_bonus_case {
      font-size: 47px;
      font-weight: 600;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__header {
    position: relative;
    height: 39px;
    margin-bottom: 23px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 28px;
    line-height: 39px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: black;
  }
  &__line_visual {
    width: 100%;
    height: 1px;
    opacity: 0.1;
    background: black;
    position: absolute;
    bottom: 0px;
    left: 0px;

    &_relative {
      height: 1px;
      position: relative;
    }
  }
  &__line {
    position: relative;
    display: flex;
    justify-content: space-between;
    color: black;
    padding: 15px 0px;

    &__header {
      font-weight: normal;
      font-size: 18px;
      position: relative;
      max-width: 85%;
    }

    &__money {
      font-weight: bold;
      font-size: 18px;
      text-align: right;
    }
  }
}
.trainer-view {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  &__wrap {
    background: linear-gradient(180deg, #eab183 49.99%, #b99166 50%);
    border: 2px solid #000000;
    border-radius: 2px;
    padding: 8px;
    position: absolute;
    width: 1280px;
    height: 720px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    &-substrate {
      position: absolute;
      top: 8px;
      right: 8px;
      bottom: 8px;
      left: 8px;
      background-color: #d2a175;
      display: flex;
      justify-content: center;
      align-items: center;

      &-loader {
        width: 100px;
        height: 100px;
      }
    }

    &-decor {
      position: absolute;
      width: 100%;
      height: 100%;

      div {
        width: 100%;
        height: 100%;
        position: relative;

        &:before,
        &:after {
          position: absolute;
          content: '';
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          top: 0;
        }

        &:before {
          background-image: url(../assets/wires_left.svg);
          background-position: center left;
          left: -55.5%;
        }

        &:after {
          background-position: center right;
          background-image: url(../assets/wires_right.svg);
          right: -54.2%;
        }
      }
    }
  }
}

.slider .slider-item .slider-item__wrap {
  width: 90%;
  height: 100%;

  &[dir='ltr'] {
    transform: scaleX(1);
  }
}

.branding {
  &__dev-info {
    margin-bottom: 12px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 231%;
    text-align: center;
    color: #006c5b;
  }

  &__logos {
    display: flex;
    align-items: center;
    justify-content: space-around;

    img {
      margin: 0;
    }

    &-x {
      display: block;
      margin: 0 24px;
    }
  }

  &__white-label-logo {
    margin: 0 auto;
  }

  &__logo {
    margin: 0 auto;
    width: 160px;
    height: 30px;
  }

  &__image[dir='ltr'] {
    transform: scaleX(1);
  }
}

.slider-item {
  background: white;

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
  }

  &__content-block {
    display: flex;
    justify-content: center;
    height: calc(100% - $branding-height);
  }

  &__branding {
    display: flex;
    justify-content: flex-end;
    height: $branding-height;
  }

  .branding__image {
    height: $branding-height;
  }
}
</style>
