<template>
  <div class="enter-email-popup">
    <div class="enter-email-popup__content" :style="getScaleStyle()">
      <div class="enter-email-popup__content-container">
        <p class="enter-email-popup__greetings">
          {{ $lui('get-credentials.title') }}
        </p>

        <div class="enter-email-popup__input-box">
          <input
            class="enter-email-popup__input"
            type="text"
            v-model="email"
            @keypress.enter="submit"
          />
          <img
            class="enter-email-popup__input-bg"
            src="../assets/input-start-game.png"
            alt=""
          />
        </div>

        <div class="enter-email-popup__error">
          <span v-if="httpCodeOfError">
            {{ $lui(httpCodeErrorMap[httpCodeOfError] || defaultError) }}
          </span>
        </div>

        <div class="enter-email-popup__bottom-section">
          <button class="enter-email-popup__start-btn" @click="submit">
            {{ $lui('get-credentials.submit') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import scaleTable from '../mixins/scaleTable'

export default {
  name: 'EnterEmailPopup',
  mixins: [scaleTable],

  props: ['httpCodeOfError'],
  data() {
    return {
      email: '',
      httpCodeErrorMap: {
        400: 'get-credentials.error-400',
        401: 'get-credentials.error-401'
      },

      defaultError: 'get-credentials.error-default'
    }
  },

  computed: mapGetters({
    initializationStatus: 'session/initializationStatus',
    teamName: 'session/teamName',
    selectedLang: 'lang/selectedLang' /// fixme use me
  }),

  methods: {
    setBlockScale() {
      let resultHeight = (window.innerHeight - 70 - 67) * 0.97
      let resultScaleH = resultHeight / 750

      let resultWidth = window.innerWidth * 0.85
      let resultScaleW = resultWidth / 550

      this.scaleTable = Math.min(resultScaleH, resultScaleW)
    },

    submit() {
      this.$emit('submit', this.email)
    }
  }
}
</script>

<style lang="scss" scoped>
.enter-email-popup {
  width: 100%;
  height: 100vh;
  background-color: #19232c;

  &__content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 560px;
    height: 265px;
  }

  &__content-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 31px;
    width: 560px;
    height: 265px;
    background-image: url(../assets/start-game-info-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;
  }

  &__greetings {
    width: 253px;
    margin: 0 auto 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #542720;
  }

  &__input-box {
    position: relative;
    width: 333px;
    height: 51px;
    margin: 0 auto 10px;
  }

  &__input {
    position: absolute;
    width: 330px;
    height: 47px;
    padding: 6px 9px;
    z-index: 9;
    border: none;
    background: transparent;
    outline: none;
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 25px;
    color: #000000;
  }

  &__input-bg {
    position: absolute;
    width: 329px;
    height: 51px;
  }

  &__error {
    color: darkred;
    margin: 0 auto 10px;
    text-align: center;
    width: 320px;
    min-height: 24px;
    font-weight: bold;
  }

  &__bottom-section {
    display: flex;
    justify-content: space-around;
    width: 326px;
    margin: 0 auto;
  }

  &__start-btn {
    width: 179px;
    height: 54px;
    padding: 0;
    padding-bottom: 5px;
    border: none;
    background: transparent;
    background-image: url(../assets/start-game-btn.png);
    background-repeat: no-repeat;
    background-size: cover;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    opacity: 0.8;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
