<template>
  <div class="loader-indicator__wrapper">
    <div class="loader-indicator"></div>
  </div>
</template>

<script>
export default {
  name: 'LoaderIndicator'
}
</script>

<style lang="scss" scoped>
.loader-indicator {
  width: 100%;
  height: 100%;

  border: 10px solid #7e6142;
  border-bottom-color: #eab183;
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: rotation 1s linear infinite;
  -moz-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;

  &__wrapper {
    width: 100%;
    height: 100%;
  }
}

@-moz-keyframes rotation {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes rotation {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotation {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
