import localStorageService from './localStorage'
import * as notificationsPinResetLogService from './notificationsPinResetLog'

const LAST_PLAYED_TEAM_ID = 'last_played_team_id'

const get = () => localStorageService.getItem(LAST_PLAYED_TEAM_ID)
const set = value => localStorageService.setItem(LAST_PLAYED_TEAM_ID, value)

export const initialize = teamId => {
  const localCacheTeamId = get()
  if (localCacheTeamId !== teamId) {
    set(teamId)
    notificationsPinResetLogService.clear()
  } else {
    notificationsPinResetLogService.initializeFromCache()
  }
}
