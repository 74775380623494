export const getLastElement = arr => arr[arr.length - 1]

export const arrayFindLastIndex = (arr, cb = () => {}) => {
  let result = -1

  for (let i = arr.length - 1; i >= 0; i--) {
    if (cb(arr[i], i, arr)) {
      result = i
      break
    }
  }

  return result
}

export const getFromObjectByKey = (obj, key) => obj[key]

export const getFromObjectByKeyPath = (obj, keyPath) => {
  const path = keyPath.split('.')

  let cur = obj
  while (path.length) {
    if (!cur) {
      return cur
    }

    cur = cur[path.shift()]
  }

  return cur
}

export const renderParameterizedString = (template, data = {}) => {
  return template.replace(/({{\S+}})/g, match => {
    const key = match.replace(/^{{|}}$/g, '')
    return data[key] || ''
  })
}
