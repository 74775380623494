import gameServerClient from '../../services/RemoteClient'
import { compareByField } from '../../utils/compare'
import debug from './../../utils/debug'

const CARD_TYPE_INFO = 'info'

function GetUIUsedCardsTables({ cardsLibrary, usedCards, infoCards }) {
  let returnArray = [[], [], [...infoCards.map(id => cardsLibrary[id])]]

  usedCards.forEach(element => {
    let card = { ...cardsLibrary[element] }
    if (card) {
      if (card.type == 'one_time') {
        returnArray[0].push(card)
      }
    }
  })

  let checkLength = 24 - returnArray[0].length

  for (let index = 0; index < checkLength; index++) {
    let newCard = {
      cardEmpty: true,
      cardColor: 'green'
    }
    returnArray[0].push(newCard)
  }

  return returnArray
}

function GetUIAvailableCards({
  cardsLibrary,
  availableCards,
  usedCards,
  cardsInHand,
  newCards,
  money,
  timeUnit,
  gameState,
  choiceIsDone
}) {
  let returnArray = []

  availableCards.forEach(element => {
    let newCard = { ...cardsLibrary[element] }
    if (
      newCard &&
      (newCard.type != 'one_time' || !usedCards.includes(element))
    ) {
      if (cardsInHand.includes(element)) {
        newCard.hide = true
      } else {
        if (
          newCard.cost > money ||
          newCard.timeUnit > timeUnit ||
          choiceIsDone ||
          gameState != 'phase_action'
        ) {
          newCard.block = true
        }
      }
      newCard.availableToHand = true
      if (newCards.includes(element)) {
        newCard.new = true
      }
      returnArray.push(newCard)
    }
  })

  returnArray.sort(compareByField('cardNumber'))

  return returnArray
}

function GetUICardsInHand({ cardsLibrary, cardsInHand, newCards }) {
  let returnArray = []

  cardsInHand.forEach(element => {
    let newCard = { ...cardsLibrary[element] }
    if (newCard) {
      newCard.availableToHand = true
      if (newCards.includes(element)) {
        newCard.new = true
      }
      returnArray.push(newCard)
    }
  })

  return returnArray
}

function checkUICards({
  cardsLibrary,
  availableCards,
  usedCards,
  cardsInHand,
  newCards,
  infoCards,
  money,
  timeUnit,
  gameState,
  choiceIsDone
}) {
  return {
    UIUsedCardsTables: GetUIUsedCardsTables({
      cardsLibrary,
      usedCards,
      infoCards
    }),
    UIAvailableCards: GetUIAvailableCards({
      cardsLibrary,
      availableCards,
      usedCards,
      cardsInHand,
      newCards,
      money,
      timeUnit,
      gameState,
      choiceIsDone
    }),
    UICardsInHand: GetUICardsInHand({ cardsLibrary, cardsInHand, newCards })
  }
}

function LogCheck(...params) {
  debug.game.info(...params)
}

export default {
  namespaced: true,

  state: {
    cardsLibrary: {},
    usedCards: [],
    availableCards: [],
    newCards: [],
    cardsInHand: [],
    infoCards: [],
    choiceIsDone: false,
    UIUsedCardsTables: [[], [], []],
    UIAvailableCards: [],
    UICardsInHand: []
  },

  getters: {
    inHand(state) {
      return state.cardsInHand
    },
    used(state) {
      return state.usedCards
    },
    available(state) {
      return state.availableCards
    },
    UICardsAvailable(state) {
      return state.UIAvailableCards
    },
    UICardsUsed(state) {
      return state.UIUsedCardsTables
    },
    UICardsInHand(state) {
      return state.UICardsInHand
    },
    cardsLibrary(state) {
      return state.cardsLibrary
    },
    choiceIsDone(state) {
      return state.choiceIsDone
    }
  },

  actions: {
    updateCardUI({ commit, state, rootGetters }) {
      setTimeout(() => {
        const {
          cardsLibrary,
          availableCards,
          usedCards,
          cardsInHand,
          newCards,
          infoCards,
          choiceIsDone
        } = state
        const { money, timeUnit } = rootGetters['session/getResources']
        const gameState = rootGetters['session/gameState']

        const UICards = checkUICards({
          cardsLibrary,
          availableCards,
          usedCards,
          cardsInHand,
          newCards,
          infoCards,
          money,
          timeUnit,
          gameState,
          choiceIsDone
        })

        commit('SET_UI_CARDS', UICards)
      }, 100)
    },
    setCardData({ state, commit, dispatch }, dataVarialabe) {
      let key = dataVarialabe.key
      let data = dataVarialabe.data

      if (key && data) {
        switch (key) {
          case 'cardsLibrary':
            commit('SET_CARDS_LIBRARY', data)
            break
          case 'usedCards':
            commit('SET_CARDS_USED', data)
            break
          case 'availableCards': {
            commit('SET_CARDS_AVAILABLE', [
              ...new Set(
                data.filter(
                  id => state.cardsLibrary[id].type !== CARD_TYPE_INFO
                )
              )
            ])
            commit('SET_INFO_CARDS', [
              ...new Set(
                data.filter(
                  id => state.cardsLibrary[id].type === CARD_TYPE_INFO
                )
              )
            ])
            break
          }
          case 'cardsInHand':
            commit('SET_CARDS_HAND', [...new Set(data)])
            break
          case 'newCards':
            commit('SET_NEW_CARDS', [...new Set(data)])
            break
        }
      }

      dispatch('updateCardUI')
    },

    syncWithCardsInHandFromServer(
      { state, commit, dispatch, rootGetters },
      cardsInHandFromServer
    ) {
      const { cardsInHand, cardsLibrary } = state
      const newCardsInHand = cardsInHandFromServer.filter(
        cid => !cardsInHand.includes(cid)
      )
      const cardsRemovedFromHand = cardsInHand.filter(
        cid => !cardsInHandFromServer.includes(cid)
      )

      let { money, timeUnit } = rootGetters['session/getResources']
      newCardsInHand.forEach(cid => {
        const card = Object.assign({}, cardsLibrary[cid])
        money -= card.cost
        timeUnit -= card.timeUnit
      })
      cardsRemovedFromHand.forEach(cid => {
        const card = Object.assign({}, cardsLibrary[cid])
        money += card.cost
        timeUnit += card.timeUnit
      })

      commit('SET_CARDS_HAND', cardsInHandFromServer)
      commit('session/UPDATE_MONEY_COUNT', money, { root: true })
      commit('session/UPDATE_TIME_COUNT', timeUnit, { root: true })

      dispatch('updateCardUI')
    },

    async addCardToHand({ state, commit, dispatch, rootGetters }, id) {
      debug.game.info('addCardToHand')
      let canAdd = true
      let cardsInHand = state.cardsInHand
      let newCardsInHand = []

      cardsInHand.forEach(element => {
        newCardsInHand.push(element)
      })

      canAdd = !cardsInHand.includes(id)

      if (canAdd) {
        let card = { ...state.cardsLibrary[id] }
        if (card) {
          let { money, timeUnit } = rootGetters['session/getResources']
          if (card.cost <= money && card.timeUnit <= timeUnit) {
            newCardsInHand.push(id)
            money -= card.cost
            timeUnit -= card.timeUnit

            commit('session/UPDATE_MONEY_COUNT', money, { root: true })
            commit('session/UPDATE_TIME_COUNT', timeUnit, { root: true })
            commit('SET_CARDS_HAND', newCardsInHand)
            dispatch('updateCardUI')

            gameServerClient.playCardsDebounced(
              newCardsInHand,
              state.choiceIsDone
            )
            /// FIXME maybe drop debounce Maybe this is not a good idea due to conflict with nonDebounced version
          }
        }
      }
    },
    async removeCardFromHand({ state, commit, dispatch, rootGetters }, id) {
      const { cardsInHand } = state
      const card = { ...state.cardsLibrary[id] }
      if (!cardsInHand.includes(id) || !card) {
        return
      }

      const newCardsInHand = cardsInHand.filter(cid => cid != id)

      let { money, timeUnit } = rootGetters['session/getResources']
      money += card.cost
      timeUnit += card.timeUnit

      commit('session/UPDATE_MONEY_COUNT', money, { root: true })
      commit('session/UPDATE_TIME_COUNT', timeUnit, { root: true })
      commit('SET_CARDS_HAND', newCardsInHand)
      dispatch('updateCardUI')

      gameServerClient.playCardsDebounced(newCardsInHand, state.choiceIsDone)
      /// FIXME maybe drop debounce Maybe this is not a good idea due to conflict with nonDebounced version
    },

    async setChoiceIsDone({ state, commit }, choiceIsDone) {
      await gameServerClient.playCards(state.cardsInHand, choiceIsDone)
      commit('SET_CHOOSE_STATE', choiceIsDone)
    }
  },

  mutations: {
    SET_UI_CARDS(state, data) {
      debug.game.storage('SET_UI_CARDS', data)
      state.UIUsedCardsTables = data.UIUsedCardsTables
      state.UIAvailableCards = data.UIAvailableCards
      state.UICardsInHand = data.UICardsInHand
    },
    SET_CARDS_LIBRARY(state, data) {
      LogCheck('cards/SET_CARDS_LIBRARY > ', data)
      state.cardsLibrary = data
    },
    SET_CARDS_USED(state, data) {
      LogCheck('cards/SET_CARDS_USED > ', data)
      state.usedCards = data
    },
    SET_CARDS_AVAILABLE(state, data) {
      LogCheck('cards/SET_CARDS_AVAILABLE > ', data)
      state.availableCards = data
    },
    SET_INFO_CARDS(state, data) {
      LogCheck('cards/SET_INFO_CARDS > ', data)
      state.infoCards = data
    },
    SET_CARDS_HAND(state, data) {
      LogCheck('cards/SET_CARDS_HAND > ', data)
      state.cardsInHand = data
    },
    SET_NEW_CARDS(state, data) {
      LogCheck('cards/SET_NEW_CARDS > ', data)
      state.newCards = data
    },
    SET_CHOOSE_STATE(state, data) {
      LogCheck('cards/SET_CHOOSE_STATE > ', data)
      state.choiceIsDone = data
    }
  }
}
