export const NAV_PAGES = {
  MAIN: 'main',
  CARDS: 'cards',
  NEWS: 'news',
  STATS: 'stats'
}

export const CURRENCY_DISPLAY_MODES = {
  EVERYWHERE: 'everywhere',
  EXCLUDE_HEADER: 'exclude_header'
}
