var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout",class:{
    'all-game': _vm.gameState !== 'rewarding',
    'finish-game': _vm.gameState === 'rewarding'
  }},[_c('HeaderComponent',{attrs:{"star-status":"","transparent":_vm.transparent}}),_c('div',{class:{
      nav: _vm.allGameLayout,
      'finish-nav': !_vm.allGameLayout
    }},[(
        !_vm.isInfoConsoleGame &&
        _vm.gameState != 'finished' &&
        _vm.gameState != 'none' &&
        _vm.gameState !== 'new'
      )?_c('NavMenuComponent',{ref:"navigation",staticClass:"navigation"}):_vm._e()],1),(!_vm.isInfoConsoleGame)?_c('FooterComponent',{ref:"footerComp",staticClass:"footer",attrs:{"transparent":_vm.transparent}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }