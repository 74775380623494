<template>
  <div class="result-wrap">
    <div class="result-wrap__diagram">
      <ResultDiagram ref="diagram" />
    </div>
    <ResultTableComponent class="result-wrap__table" />
  </div>
</template>

<script>
import ResultTableComponent from './ResultTableComponent.vue'
import ResultDiagram from './ResultDiagram.vue'

export default {
  components: { ResultTableComponent, ResultDiagram },
  methods: {
    rebuildDiagram() {
      this.$refs.diagram.createDiagramNow()
    }
  }
}
</script>

<style lang="scss">
.result-wrap {
  display: flex;
  align-items: center;
  width: 986px;
  height: 484px;
  background: #354657;
  box-shadow: 1px 1px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 16px;

  &__diagram {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 437px;
    height: 433px;
    margin-left: 55px;
    margin-right: 25px;
    background-image: url(../../assets/infographics-bg.png);
    background-repeat: no-repeat;
    background-size: 363px 430px;
    background-position-x: right;
    position: relative;
    z-index: 10;
  }

  &__table {
    width: 449px;
    min-height: 442px;
  }
}
</style>
