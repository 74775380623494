export default {
  namespaced: true,
  state: {
    enabled: false,
    room: null,
    canPublish: true,
    muted: false,
    connected: false,
    useRelay: false
  },
  getters: {
    currentRoom(state) {
      return state.room
    },
    canPublish(state) {
      return state.canPublish
    },
    muted(state) {
      return state.muted
    },
    connected(state) {
      return state.connected
    },
    enabled(state) {
      return state.enabled
    },
    useRelay(state) {
      return state.useRelay
    }
  },
  actions: {
    joinTeamChat({ commit, rootState }) {
      const room = rootState.session.team?.id || null
      commit('SET_ROOM', room)
      commit('SET_CAN_PUBLISH', true)
    },
    joinGameChat({ commit, rootState }) {
      const room = rootState.session.gameId
      commit('SET_ROOM', room)
      commit('SET_CAN_PUBLISH', false)
    },

    toggleMute({ commit, state }) {
      commit('SET_MUTED', !state.muted)
    },

    enable({ commit }) {
      commit('SET_ENABLED', true)
    },

    disable({ commit }) {
      commit('SET_ENABLED', false)
    },

    toggleEnabled({ commit, state }) {
      commit('SET_ENABLED', !state.enabled)
    },

    toggleUseRelay({ commit, state }) {
      commit('SET_USE_RELAY', !state.useRelay)
    }
  },
  mutations: {
    SET_ROOM(state, value) {
      state.room = value
    },
    SET_CAN_PUBLISH(state, value) {
      state.canPublish = value
    },
    SET_MUTED(state, value) {
      state.muted = value
    },
    SET_CONNECTED(state, value) {
      state.connected = value
    },
    SET_ENABLED(state, value) {
      state.enabled = value
    },
    SET_USE_RELAY(state, value) {
      state.useRelay = value
    }
  }
}
