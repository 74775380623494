<template>
  <div class="app-loader">
    <div class="app-loader__indicator">
      <LoaderIndicator />
    </div>
  </div>
</template>

<script>
import LoaderIndicator from './LoaderIndicator'

export default {
  name: 'AppLoader',
  components: {
    LoaderIndicator
  }
}
</script>

<style lang="scss" scoped>
.app-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 500;
  background-color: #19232c;

  &__indicator {
    position: fixed;
    width: 128px;
    height: 128px;
    top: calc(50% - 64px);
    left: calc(50% - 64px);
  }
}
</style>
