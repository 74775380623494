import enLocale from '../assets/ui_locales/en.json'
import ruLocale from '../assets/ui_locales/ru.json'

const LOCALES = {
  en: {
    title: 'English',
    uiTexts: enLocale
  },

  ru: {
    title: 'Русский',
    uiTexts: ruLocale
  },

  tr: {
    title: 'Turkish',
    uiTexts: null
  },

  de: {
    title: 'Deutsch',
    uiTexts: null
  },

  sp: {
    title: 'Spanish-EU',
    uiTexts: null
  },

  jp: {
    title: 'Japanese',
    uiTexts: null
  },

  pt: {
    title: 'Portugese',
    uiTexts: null
  },

  mx: {
    title: 'Spanish-LA',
    uiTexts: null
  },

  it: {
    title: 'Italian',
    uiTexts: null
  },

  fr: {
    title: 'French',
    uiTexts: null
  },

  cn: {
    title: 'Chinese',
    uiTexts: null
  },

  ar: {
    title: 'Arabic',
    uiTexts: null
  },

  'en-jm': {
    title: 'Arabic-En',
    uiTexts: null
  },

  nl: {
    title: 'Nederlands',
    uiTexts: null
  },

  es: {
    title: 'Spanish',
    uiTexts: null
  },

  ptl: {
    title: 'Portugese-LA',
    uiTexts: null
  }
}

Object.keys(LOCALES).forEach(slug => Object.assign(LOCALES[slug], { slug }))

export const DEFAULT_LOCALE = LOCALES.en

export const SAVED_LOCALE_KEY = 'locale'

export default LOCALES
