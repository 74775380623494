<template>
  <div class="results-table">
    <div class="results-table__titles-board">
      <p class="results-table__title results-table__title-hash">#</p>
      <p class="results-table__title results-table__title-team">
        {{ $lui('results.team') }}
      </p>
      <p class="results-table__title">{{ $lui('results.result') }}</p>
      <p v-if="isInfoConsoleGame" class="results-table__title">
        {{ $lui('results.view') }}
      </p>
    </div>
    <div class="results-table__table-rows-wrap">
      <ResultsRowComponent
        class="results-table__table-row"
        v-for="result in getTableInfo()"
        :result="result"
        :key="result.id"
      />
    </div>
  </div>
</template>

<script>
import ResultsRowComponent from './ResultsRowComponent.vue'
import { mapGetters } from 'vuex'

export default {
  components: { ResultsRowComponent },
  computed: mapGetters({
    resultsData: 'session/getResults',
    isInfoConsoleGame: 'session/isInfoConsoleGame'
  }),

  methods: {
    getTableInfo() {
      return this.resultsData.tableData
    }
  }
}
</script>

<style lang="scss" scoped>
.results-table {
  max-height: 100%;
  overflow-y: scroll;
  scrollbar-color: #5d7083 #354657;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #354657;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #5d7083;
  }

  background: #fef9f4;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 1px 1px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  &__title {
    margin-right: auto;
    margin-left: auto;

    &:last-child {
      margin-left: auto;
      margin-right: 20px;
    }
  }

  &__titles-board {
    display: flex;
    padding: 15px 26px;
    justify-content: space-between;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #000000;
    border-bottom: 1px solid #000000;
  }

  &__title-team {
    margin-right: auto;
    margin-left: 33px;
  }

  &__title-hash {
    margin-left: 0;
    margin-right: 0;
  }

  &__table-rows-wrap {
    margin: 4px auto;
  }
}
</style>
