<template>
  <div class="footer-info-block">
    <div class="footer-money-block">
      <div class="footer-money-block__data-wrap">
        <span class="footer-money-block__money-icon">{{ currency }}</span>
        <span>{{ getSessionMoney() }}</span>
      </div>
    </div>
    <div class="dots">
      <img width="7" height="7" src="../assets/footer/footer-dot.png" alt="" />
      <img width="7" height="7" src="../assets/footer/footer-dot.png" alt="" />
    </div>
    <div class="footer-time-block">
      <div class="footer-time-block__data-wrap">
        <img
          class="footer-time-block__time-icon"
          src="../assets/footer/hourglass-icon.png"
          alt=""
        />
        <span>{{ getSessionTimeUnit() }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    footerData: {
      type: String,
      default: ''
    }
  },

  computed: mapGetters({
    dataCurrentSession: 'session/dataCurrentSession',
    currency: 'session/currency'
  }),

  methods: {
    formatSumValue(value) {
      if (value < 1000) {
        return value + ''
      } else if (value < 99999) {
        return Math.floor(value / 10) / 100 + 'K'
      } else {
        return Math.floor(value / 100) / 10 + 'K'
      }
    },

    getSessionMoney() {
      let money = this.formatSumValue(this.dataCurrentSession.money)
      //money = Math.max(0,money);
      return money
    },

    getSessionTimeUnit() {
      return Math.max(0, this.dataCurrentSession.timeUnit)
    }
  }
}
</script>

<style lang="scss">
.footer-info-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-money-block {
  position: relative;
  top: 4px;
  width: 147px;
  height: 51px;
  background-image: url(../assets/footer/footer-long-frame.png);
  background-repeat: no-repeat;
  background-size: 147px 51px;

  &__data-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-34%, -61%);
    width: 100%;
    display: flex;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    color: #ffffff;
    text-shadow: -1px 1px 0px #000000;
  }

  &__money-icon {
    margin-right: 9px;
  }
}

.footer-time-block {
  position: relative;
  top: 4px;
  width: 106px;
  height: 51px;
  background-image: url(../assets/footer/footer-short-frame.png);
  background-repeat: no-repeat;
  background-size: 106px 51px;

  &__data-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-34%, -61%);
    width: 100%;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    color: #ffffff;
    text-shadow: -1px 1px 0px #000000;
  }

  &__time-icon {
    width: 14px;
    height: 20px;
    margin-right: 9px;
  }
}

.dots {
  display: flex;
  width: 7px;
  height: 36px;
  flex-direction: column;
  justify-content: space-between;
}
</style>
