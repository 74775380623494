<template>
  <div
    v-if="info"
    class="popup__container"
    :class="{ popup__container_showing: visible, clickable: chekClickable() }"
    @click="openSubPage"
  >
    <p class="popup__title" v-html="getTitle()"></p>
    <p class="popup__text" v-html="getText()"></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PhaseChangePopupComponent',
  data() {
    return {
      visible: false
    }
  },

  props: ['info'],
  components: {},
  computed: mapGetters({
    isCaptain: 'session/getIsCaptain'
  }),

  mounted() {
    setTimeout(() => {
      this.visible = true
    }, 100)
    setTimeout(() => {
      this.visible = false
    }, 7000)
  },

  methods: {
    getTitle() {
      const {
        info: { phase, turn },
        $lui
      } = this
      const keyForPhaseTitle = `header.phase-notifications.${phase}-title`
      return `${$lui('header.step')} ${turn} ${$lui(keyForPhaseTitle)}`
    },

    getText() {
      return this.$lui(`header.phase-notifications.${this.info.phase}-text`)
    },

    chekClickable() {
      /*
      1: "new",
      2: "game_intro",
      3: "phase_news",
      4: "phase_action_begin",
      5: "phase_action",
      6: "phase_action_end",
      7: "phase_reporting",
      8: "after_bonus_slides",
      9: "after_bonus_result",
      10: "after_unveil",
      11: "after_lesson_begin",
      12: "after_lesson",
      13: "after_ideal",
      14: "after_poll",
      15: "finished",
      */

      switch (this.info.phase) {
        case 'phase_news':
          return true
        case 'phase_action_begin':
          return true
        case 'phase_action':
          return true
        case 'phase_action_end':
          return true
        case 'phase_reporting':
          return true
      }
      return false
    },

    openSubPage() {
      if (this.chekClickable()) {
        let subPage = ''
        switch (this.info.phase) {
          case 'phase_news':
            subPage = 'news'
            break
          case 'phase_action_begin':
            subPage = 'cards'
            break
          case 'phase_action':
            subPage = 'cards'
            break
          case 'phase_action_end':
            subPage = 'main'
            break
          case 'phase_reporting':
            subPage = 'news'
            break
          case 'phase_reporting_last':
            subPage = 'stats'
            break
        }
        if (subPage != '') {
          this.$store.commit('session/SET_CURRENT_SUB_PAGE', subPage)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.clickable {
  cursor: pointer;
}

.popup {
  &__container {
    width: fit-content;
    height: auto;
    background: #f1c299;
    border: solid 1px #000;
    border-radius: 6px;
    overflow: hidden;
    padding: 8px;
    margin-bottom: 6px;
    box-shadow: inset 5px 6px 0px #e9c6a7, inset -5px -5px 0px #c1946b,
      rgba(0, 0, 0, 0.3) 0px 2px 0px 1px;
    opacity: 0;
    transition: opacity 0.5s ease-in;

    &_showing {
      opacity: 1;
      transition: opacity 0.5s ease-in;
    }
  }

  &__title {
    line-height: 13px;
    font-size: 14px;
    font-weight: bold;
    color: #1c1c1c;
    margin-bottom: 4px;
  }

  &__text {
    line-height: 10px;
    font-size: 11px;
    color: #323232;
  }
}
</style>
