const GAME_SERVER_HTTP = window.__ENV__.VUE_APP_GAME_SERVER_HTTP
const GAME_SERVER_WS = window.__ENV__.VUE_APP_GAME_SERVER_WS
const ION_ENDPOINT = window.__ENV__.VUE_APP_ION_ENDPOINT
const ION_ICE_SERVERS = window.__ENV__.VUE_APP_ION_ICE_SERVERS

export default {
  GAME_SERVER_HTTP,
  GAME_SERVER_WS,
  ION_ENDPOINT,
  ION_ICE_SERVERS
}
