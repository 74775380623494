<template>
  <div class="grade-comp">
    <div class="grade" ref="gradeTable" :style="getScaleStyle()">
      <div
        class="grade__wrapper"
        ref="gradeWrapper"
        @touchmove="e => handleMoveEvent(e.touches[0])"
        @mousemove="e => handleMoveEvent(e)"
        @mouseenter="onMouseenterGradeWrapper"
        @mouseleave="endDragIfDragging"
      >
        <img
          v-for="(stick, i) in votesObject.other"
          :key="i"
          draggable="false"
          ondragstart="return false"
          src="../assets/grade.svg"
          class="grade__card grade__card_other"
          v-show="stick.visible"
          :style="convertDecartIntoCss(stick)"
          alt=""
        />
        <img
          v-show="!isCaptain"
          class="grade__card"
          :style="convertDecartIntoCss(mainVote)"
          draggable="false"
          ondragstart="return false"
          src="../assets/grade.svg"
          alt=""
        />
        <img
          v-show="isCaptain"
          ref="gradeCard"
          :class="{
            grade__card: true,
            'grade__card--captain': true,
            grade__card_drag: inDrag
          }"
          :style="convertDecartIntoCss(mainVote)"
          draggable="false"
          ondragstart="return false"
          src="../assets/grade.svg"
          alt=""
          @mousedown="startDrag"
          @mousemove="e => handleMoveEvent(e)"
          @mouseup="endDrag"
          @touchstart.prevent="startDrag"
          @touchmove.prevent="e => handleMoveEvent(e.touches[0])"
          @touchend.prevent="endDrag"
        />
        <div
          class="grade__axis-tag grade__axis-tag--funny"
          v-html="$lui('grade-game.funny')"
        ></div>
        <div
          class="grade__axis-tag grade__axis-tag--useful"
          v-html="$lui('grade-game.useful')"
        ></div>
        <div
          class="grade__axis-tag grade__axis-tag--boring"
          v-html="$lui('grade-game.boring')"
        ></div>
        <div
          class="grade__axis-tag grade__axis-tag--useless"
          v-html="$lui('grade-game.useless')"
        ></div>
        <div class="grade__axis grade__axis--useful"></div>
        <div class="grade__axis grade__axis--funny"></div>

        <p
          class="grade__clue"
          v-if="showTips"
          v-html="$lui('grade-game.tip')"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
const DRAG_THROTTLE_TIME = 500

import { mapGetters } from 'vuex'
import scaleTable from '../mixins/scaleTable'
import lodash from 'lodash'
import debug from './../utils/debug'

export default {
  name: 'GradeGameComponent',
  mixins: [scaleTable],

  data() {
    return {
      showTips: true,
      inDrag: false,
      throttleEndDrag: lodash.throttle(this.endDrag, DRAG_THROTTLE_TIME, {
        leading: false
      }),

      mainVote: {
        x: 0,
        y: 0
      }
    }
  },

  computed: mapGetters({
    votesObject: 'session/getVotes',
    isCaptain: 'session/getIsCaptain'
  }),

  watch: {
    'votesObject.mainVote': {
      immediate: true,
      deep: true,
      handler(vote) {
        Object.assign(this.mainVote, {
          x: vote.x,
          y: vote.y
        })
        if (this.showTips && (vote.x !== 0 || vote.y !== 0)) {
          this.showTips = false
        }
      }
    }
  },

  methods: {
    convertDecartIntoCss({ x, y }) {
      return {
        left: 50 + x * 50 + '%',
        top: 50 - y * 50 + '%'
      }
    },

    setBlockScale() {
      let resultHeight = (window.innerHeight - 70 - 67) * 0.9
      let resultScaleH = resultHeight / 750

      let resultWidth = window.innerWidth * 0.85
      let resultScaleW = resultWidth / 1023

      this.scaleTable = Math.min(resultScaleH, resultScaleW)
    },

    handleMoveEvent(moveEvent) {
      const { clientX: x, clientY: y } = moveEvent
      this.handleMoveResult({ x, y })
    },

    handleMoveResult({ x, y }) {
      if (this.inDrag) {
        let resultCoords = { x: 0, y: 0 }

        let mainRect = this.$refs.gradeWrapper.getBoundingClientRect()

        if (!mainRect) {
          this.mainVote = resultCoords
          return
        }

        let mouseVec = { x, y }
        mouseVec.x = mouseVec.x - mainRect.x
        mouseVec.y = mouseVec.y - mainRect.y

        const widthNorm = mainRect.width / 2
        const heightNorm = mainRect.height / 2

        resultCoords.x = (mouseVec.x - widthNorm) / widthNorm
        resultCoords.y = (-1 * (mouseVec.y - heightNorm)) / heightNorm

        this.mainVote = resultCoords

        if (this.showTips) {
          this.showTips = false
        }

        this.checkMainPosing()
      }
    },

    onMouseenterGradeWrapper() {
      this.throttleEndDrag.cancel()
    },

    endDragIfDragging() {
      if (this.inDrag) {
        this.throttleEndDrag()
      }
    },

    startDrag() {
      this.inDrag = true
      debug.game.action('drag:start')
    },

    endDrag() {
      if (this.inDrag) {
        this.inDrag = false
        this.checkMainPosing()
        debug.game.action('drag:end')
        this.sendResult()
      }
    },

    sendResult() {
      debug.game.info('vote: ', this.mainVote)
      this.$emit('change', this.mainVote.x, this.mainVote.y)
    },

    checkMainPosing() {
      let mainVoteNew = { x: 0, y: 0 }

      mainVoteNew.x = Math.max(Math.min(this.mainVote.x, 1), -1)
      mainVoteNew.y = Math.max(Math.min(this.mainVote.y, 1), -1)

      this.mainVote = mainVoteNew
    }
  }
}
</script>

<style lang="scss" scoped>
.grade {
  position: fixed;
  top: 50%;
  left: 50%;

  &__wrapper {
    position: relative;
    width: 1023px;
    height: 803px;
    display: flex;

    &-cont {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  &__card {
    width: 110px;
    height: 110px;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 250;

    &_other {
      z-index: 249;
      filter: grayscale(1);
      cursor: default;
    }

    &--captain {
      cursor: pointer;
    }
  }

  &__card_drag {
    width: 200px;
    height: 200px;
  }

  &__stranger-card {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    background: rgb(21, 255, 204);
    transform: translate(-50%, -50%);
    margin: 0;
    filter: grayscale(1);
  }

  &__clue {
    position: absolute;
    top: 35%;
    left: 35%;
    padding: 18px 62px;
    width: 300px;
    height: 75px;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    background: #151e26;
    border-radius: 3px;
  }

  &__axis {
    position: absolute;
    background-color: #000;

    &--funny {
      top: 20px;
      bottom: 20px;
      width: 2px;
      left: 50%;
    }

    &--useful {
      left: 20px;
      right: 20px;
      height: 2px;
      top: 50%;
    }

    &-tag {
      position: absolute;
      color: #fff;
      font-family: Arial;
      font-weight: 700;
      font-size: 17px;
      line-height: 19.55px;
      text-align: center;
      width: 174px;
      height: 37px;
      text-transform: uppercase;
      text-shadow: -1px 1px 1px #000000;
      padding: 8px 5px;
      z-index: 10;

      &--useful,
      &--useless {
        width: 150px;
        height: 60px;
        padding: 20px 5px;
      }

      &--funny {
        background-image: url('../assets/grade/funny_tag.svg');
        top: 0;
        left: calc(50% - 87px);
      }

      &--useful {
        background-image: url('../assets/grade/useful_tag.svg');
        right: 0;
        top: calc(50% - 30px);
      }

      &--boring {
        background-image: url('../assets/grade/boring_tag.svg');
        bottom: 0;
        left: calc(50% - 87px);
      }

      &--useless {
        background-image: url('../assets/grade/useless_tag.svg');
        left: 0;
        top: calc(50% - 30px);
      }
    }
  }
}

.grade-comp {
  background-color: #4b647c;
}
</style>
