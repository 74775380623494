<template>
  <div class="header-info-block-wrap">
    <div class="header-info-block">
      <div
        v-bind:class="{
          'header-info-block__data-frame': dataFrame,
          'header-info-block__data-frame2': !dataFrame
        }"
      >
        <p class="header-info-block__data-frame-text">
          <slot name="infoData">{{ infoData }}</slot>
        </p>
      </div>
      <p class="header-info-block__data-frame-title">
        <slot name="infoTitle">{{ infoTitle }}</slot>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderInfoBlockComponent',
  props: {
    // eslint-disable-next-line vue/no-boolean-default
    dataFrame: { type: Boolean, default: true },
    infoData: { type: String, default: '' },
    infoTitle: { type: String, default: '' }
  }
}
</script>

<style lang="scss" scoped>
.header-info-block-wrap {
  width: 119px;
  height: 44px;
}

.header-info-block {
  width: 119px;
  height: 30px;

  &__data-frame {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/header/info-block-bg.png);
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__data-frame2 {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/header/info-block-bg-green.png);
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__data-frame-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -53%);
    text-align: center;
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    color: #ffffff;
    text-shadow: -1px 1px 0px #000000;
  }

  &__data-frame-title {
    text-align: center;
    width: 118px;
    margin: 0 auto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #000000;
  }
}
</style>
