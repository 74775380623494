import * as navigationService from '../../services/navigation'
import * as notificationsPinResetLog from '../../services/notificationsPinResetLog'
import GameStates from '../../constants/gameStates'
import gameServerClient from '../../services/RemoteClient'
import Vue from 'vue'
import debug from './../../utils/debug'
import { map } from 'lodash'
import { NAV_PAGES, CURRENCY_DISPLAY_MODES } from '../../constants/ui'

const SHOW_PHASE_INFO_POPUP = {
  phase_news: true,
  phase_action: true,
  phase_reporting: true
}

export default {
  namespaced: true,

  state: {
    gameId: null,
    modal: {
      current: null,
      settings: {}
    },
    phaseInfo: [],
    captainId: null,
    notificationPoints: {
      main: false,
      cards: false,
      news: false,
      stats: false
    },
    sessionName: '',
    initializationStatus: 'loading', // get_credentials | terminal_state_connection_error | ready
    gameState: 'none', //new, phase_news, phase_action, phase_action_lock, phase_reporting, after_bonus, after_unveil, after_lesson, after_ideal, after_poll, finished
    currentSubPage: NAV_PAGES.MAIN,
    bonusTable: [],
    trainerSlides: [],
    trainerWindow: {
      type: 'bonus_results',
      slide: 0,
      show: false
    },
    baseUrl: '',
    dataCurrentSession: {
      memberCertificate: true,
      step: 0,
      isCaptain: false,
      playerId: null,
      isInfoConsole: false,
      money: 0,
      timeUnit: 0,
      profitCurrentStep: 0,
      totalProfitGame: 0,
      teamName: '',
      currentPlayerNickname: '',
      id: -1,
      idHash: '',
      votes: {
        mainVote: {
          x: 0,
          y: 0
        },
        other: []
      }
    },
    dataSessionTimer: {
      time: 0
    },
    resultDiagramData: [],
    resultTableData: [],
    messagesLibrary: {},
    bonusMessages: {},
    newsMessages: [],
    gameBoard: {
      images: {},
      infrastructure: {}
    },
    team: {},
    teams: [],
    sharing: [],
    branding: {
      type: '',
      company_logo: '',
      company_title: ''
    },
    currency: null,
    slidesVisibility: '',
    hasChat: false,
    statistic: [],
    currencyDisplayMode: CURRENCY_DISPLAY_MODES.EVERYWHERE
  },

  getters: {
    getVotes(state) {
      return state.dataCurrentSession.votes
    },
    getIsCaptain(state) {
      if (!state.dataCurrentSession) {
        return false
      }
      return state.dataCurrentSession.isCaptain
    },
    isInfoConsole(state) {
      return state.dataCurrentSession.isInfoConsole
    },
    dataCurrentSession(state) {
      return state.dataCurrentSession
    },
    getResults(state) {
      let data = {
        diagramData: state.resultDiagramData,
        tableData: state.resultTableData
      }
      return data
    },
    getNews(state) {
      return state.news
    },
    getNewNews(state) {
      return state.newsMessages
    },
    gameState(state) {
      return state.gameState
    },
    initializationStatus(state) {
      return state.initializationStatus
    },
    currentSubPage(state) {
      return state.currentSubPage
    },
    notificationPoints(state) {
      return state.notificationPoints
    },
    captainId(state) {
      return state.captainId
    },
    sessionName(state) {
      return state.sessionName
    },
    statistic(state) {
      return state.statistic
    },
    getResources(state) {
      const { money, timeUnit } = state.dataCurrentSession
      return { money, timeUnit }
    },
    getPhaseInfoList(state) {
      return state.phaseInfo
    },
    getBaseUrl(state) {
      return state.baseUrl
    },
    teamName(state) {
      return state.dataCurrentSession.teamName
    },
    currentPlayerNickname(state) {
      return state.dataCurrentSession.currentPlayerNickname
    },
    trainerWindow(state) {
      return state.trainerWindow
    },
    bonusTable(state) {
      return state.bonusTable
    },
    bonusMessages(state) {
      return state.bonusMessages
    },
    trainerSlides(state) {
      return state.trainerSlides
    },
    timerTime(state) {
      return state.dataSessionTimer.time
    },
    currentModal(state) {
      return state.modal.current
    },
    modalSettings(state) {
      return state.modal.settings
    },
    gameBoard(state) {
      return state.gameBoard
    },
    team(state) {
      return state.team
    },
    isInfoConsoleGame(state) {
      return state.team.is_info_console
    },
    canEditPlayerName(state) {
      return (
        state.gameState === GameStates[GameStates.New] &&
        !state.dataCurrentSession.isInfoConsole
      )
    },
    canEditTeamName(state) {
      return (
        state.dataCurrentSession.isCaptain &&
        state.gameState === GameStates[GameStates.New]
      )
    },
    sharingSocApps({ sharing }) {
      return sharing
    },
    branding(state) {
      return state.branding
    },
    slidesVisibility(state) {
      return state.slidesVisibility
    },
    gameId(state) {
      return state.gameId
    },
    hasChat(state) {
      return state.hasChat
    },
    currency(state) {
      return state.currency || '$'
    },
    headerCurrencyHidden(state) {
      return state.currencyDisplayMode === CURRENCY_DISPLAY_MODES.EXCLUDE_HEADER
    }
  },

  actions: {
    addGameId({ commit }, data) {
      commit('UPDATE_ID', data)
    },
    addPhaseInfo({ state, commit, dispatch }, data) {
      if (!SHOW_PHASE_INFO_POPUP[data.phase]) {
        return
      }
      debug.session.info('addPhaseInfo', data)

      let newPhaseInfoItem = {
        phase: data.phase,
        turn: data.turn
      }

      let newList = [newPhaseInfoItem, ...state.phaseInfo]
      dispatch('startPhaseInfoTimer')
      commit('UPDATE_PHASE_INFO_LIST', newList)
    },
    startPhaseInfoTimer({ state, dispatch, commit }) {
      let phaseInfo = state.phaseInfo
      if (phaseInfo.length == 0) {
        setTimeout(() => dispatch('startPhaseInfoTimer'), 8000)
      } else if (phaseInfo.length > 0) {
        if (phaseInfo.length > 1) {
          setTimeout(() => dispatch('startPhaseInfoTimer'), 8000)
        }
        let newList = phaseInfo
        newList.pop()

        commit('UPDATE_PHASE_INFO_LIST', newList)
      }
    },
    setTrainerWindow({ commit }, data) {
      commit('UPDATE_TRAINER_WINDOW', data)
    },

    setCurrentSubPage({ commit }, data) {
      commit('SET_CURRENT_SUB_PAGE', data)
    },

    updateSessionActiveMessages({ state, commit, rootGetters }, data) {
      debug.session.update('updateSessionActiveMessages', data)
      let stepActions = data.stepActions
      let cardsList = data.cardsList

      function parseMessage(messageId) {
        let libraryMessage = state.messagesLibrary[messageId]

        if (libraryMessage) {
          let parsedType = null
          let parsedMessage = {
            weight: libraryMessage.weight
          }

          switch (libraryMessage.messageType) {
            case 'super_news':
              parsedType = 'newsWithoutTitle'
              parsedMessage.newsText = libraryMessage.message
              break
            case 'organization_report':
              parsedType = 'report'
              parsedMessage.newsTitle = libraryMessage.header
              parsedMessage.newsText = libraryMessage.message
              break
            case 'news':
              parsedType = 'report'
              parsedMessage.newsTitle = libraryMessage.header
              parsedMessage.newsText = libraryMessage.message
              if (libraryMessage.image) {
                parsedMessage.newsImg =
                  state.baseUrl + '/' + libraryMessage.image
                parsedType = 'newsWithPictureAndTitle'
              } else if (libraryMessage.video) {
                parsedMessage.newsVideo =
                  state.baseUrl + '/' + libraryMessage.video
                parsedType = 'newsWithVideo'
              }
              break
            case 'video':
              parsedType = 'report'
              parsedMessage.newsTitle = libraryMessage.header
              parsedMessage.newsText = libraryMessage.message
              break
            case 'email': {
              parsedType = 'letterFromSupport'
              const message = rootGetters['localizations/localizeContent'](
                libraryMessage.message
              ).replaceAll('\r', '') /// $lc

              if (message.startsWith('to:') || message.startsWith('To:')) {
                const HEADERS_BODY_SEPARATOR = '\n\n'
                const separatorIndex = message.indexOf(HEADERS_BODY_SEPARATOR)
                if (separatorIndex === -1) {
                  debug.session.warn('Unknown email format', message)
                  return false
                }

                parsedMessage.headers = message
                  .slice(0, separatorIndex)
                  .split('\n')
                  .map(headerLine => {
                    const hMatch = headerLine.match(/^\s*(\S*):\s*(.*)$/)
                    if (!hMatch) {
                      return
                    }

                    return {
                      header: hMatch[1].toLowerCase(),
                      value: hMatch[2]
                    }
                  })
                  .filter(v => v)

                parsedMessage.body = message.slice(
                  separatorIndex + HEADERS_BODY_SEPARATOR.length
                )
              } else {
                /// FALLBACK TODO DROP me onetime
                let lines = message.split('\n')
                parsedMessage.headers = [
                  { header: 'to', value: lines[0] },
                  { header: 'from', value: lines[1] },
                  { header: 'subject', value: lines[2] }
                ]
                parsedMessage.body = lines[3]
              }
              break
            }
            case 'auto_report_red':
              parsedType = 'urgentNotification'
              parsedMessage.newsTitle = libraryMessage.header
              parsedMessage.newsText = libraryMessage.message
              break
            case 'auto_report_yellow':
              parsedType = 'notification'
              parsedMessage.newsTitle = libraryMessage.header
              parsedMessage.newsText = libraryMessage.message
              break
            case 'auto_report_green':
              parsedType = 'positiveNotification'
              parsedMessage.newsTitle = libraryMessage.header
              parsedMessage.newsText = libraryMessage.message
              break
            case 'official_notification':
              parsedType = 'official_notification'

              if (libraryMessage.parent) {
                parsedMessage.parent = libraryMessage.parent
              } else {
                parsedMessage.id = libraryMessage.id
              }

              parsedMessage.newsTitle = libraryMessage.header
              parsedMessage.newsText = libraryMessage.message

              break
          }

          if (parsedType) {
            parsedMessage.type = parsedType
            return parsedMessage
          } else {
            return false
          }
        }
      }

      const newsMessages = []
      let stepId = 0
      stepActions.forEach(step => {
        let newStep = {
          id: stepId,
          info: [],
          results: []
        }
        stepId += 1
        if (step.move && step.move.length > 0) {
          let message = {
            type: 'decisions',
            newsText: "You've chosen the following actions:",
            newsTitle: 'DECISIONS',
            cardsOnStep: []
          }

          step.move.forEach(card => {
            let libraryCard = cardsList[card]
            let newCard = {
              disabled: false,
              id: libraryCard.id,
              cardNumber: libraryCard.cardNumber,
              timeCost: libraryCard.timeUnit,
              cost: libraryCard.cost,
              title: libraryCard.title,
              reusable: libraryCard.type != 'one_time',
              locked: false
            }
            message.cardsOnStep.push(newCard)
          })

          newStep.info.push(message)
        }
        if (step.action_messages && step.action_messages.length > 0) {
          step.action_messages.forEach(action_message_id => {
            const message = parseMessage(action_message_id)

            if (message) {
              newStep.info.push(message)
            }
          })
        }
        if (step.reporting_messages && step.reporting_messages.length > 0) {
          step.reporting_messages.forEach(reporting_message_id => {
            const message = parseMessage(reporting_message_id)
            if (message) {
              newStep.results.push(message)
            }
          })

          const parentsId = []
          const childrenMessages = {}

          newStep.results.forEach(message => {
            if (message.parent && !parentsId.includes(message.parent)) {
              parentsId.push(message.parent)
            }
          })

          parentsId.forEach(parentId => {
            newStep.results.forEach(message => {
              if (message.parent && message.parent === parentId) {
                if (!childrenMessages[parentId]) {
                  childrenMessages[parentId] = []
                }

                childrenMessages[parentId].push(message)
              }
            })
          })

          // add child message to parent array
          newStep.results = newStep.results.map(message => {
            if (
              message.id &&
              Object.keys(childrenMessages).includes(message.id.toString())
            ) {
              message.children = childrenMessages[message.id]
            }

            return message
          })

          // remove child message (duplicates)
          newStep.results = newStep.results.filter(message => {
            if (!message.parent) {
              return message
            }
          })
        }

        newsMessages.push(newStep)
      })

      const sortNullToEnd = (arr, key) => {
        return [...arr].sort((a, b) => {
          const aValue = a[key]
          const bValue = b[key]
          return aValue === null ? 1 : bValue === null ? -1 : aValue - bValue
        })
      }

      for (const news of newsMessages) {
        news.results = sortNullToEnd(news.results, 'weight')
        news.info = sortNullToEnd(news.info, 'weight')
      }

      commit('UPDATE_SESSION_ACTIVE_MESSAGES', newsMessages)
    },

    async changeTeamData(
      {
        commit,
        rootGetters,
        getters: {
          canEditTeamName,
          canEditPlayerName,
          currentPlayerNickname,
          teamName: currentTeamName
        }
      },
      { playerName, teamName = null }
    ) {
      const shouldChangePlayerName =
        canEditPlayerName && playerName && playerName !== currentPlayerNickname
      const shouldChangeTeamName =
        canEditTeamName && teamName && teamName !== currentTeamName
      const errors = Object.create(null)

      if (shouldChangeTeamName) {
        try {
          await gameServerClient.setTeamName(teamName)
        } catch (e) {
          errors.teamName = rootGetters['localizations/localizeUI'](
            'team-label.teamname--error'
          )
        }
      }

      if (shouldChangePlayerName) {
        try {
          await gameServerClient.setPlayerNickname(playerName)
          commit('SET_CURRENT_PLAYER_NAME', playerName)
        } catch (e) {
          errors.playerName = rootGetters['localizations/localizeUI'](
            'team-label.nickname--error'
          )
        }
      }

      return errors
    },

    onCaptainChange({ state, commit }, newCaptainId) {
      commit(
        'UPDATE_SESSION_CAPTAIN_STATUS',
        state.dataCurrentSession.playerId === newCaptainId
      )
      commit(
        'UPDATE_PLAYER_LIST',
        map(state.team.players, player => {
          player.is_captain = player.id === newCaptainId

          return player
        })
      )
      commit('UPDATE_CAPTAIN_ID', newCaptainId)
    }
  },

  mutations: {
    UPDATE_MONEY_PROFIT(state, data) {
      if (data.length === 0) {
        state.dataCurrentSession.profitCurrentStep = 0
      }

      for (let index = data.length - 1; index > -1; index--) {
        const element = data[index]

        if (element.revenue !== null) {
          index = -1
          state.dataCurrentSession.profitCurrentStep = element.revenue
        }
      }
    },

    UPDATE_CAPTAIN_ID(state, data) {
      state.captainId = data
    },

    UPDATE_VOTES(state, [sessionId, vote]) {
      if (sessionId == state.dataCurrentSession.id) {
        state.dataCurrentSession.votes.mainVote = vote
      } else {
        if (state.dataCurrentSession.votes.other.length <= sessionId) {
          let otherList = []

          for (let index = 0; index < sessionId + 1; index++) {
            //// FIXME this not pretty logic after   MAke me more declarative
            const element = state.dataCurrentSession.votes.other[index]
            if (element) {
              otherList[index] = element
              ///otherList[index].visible = true;
            } else {
              otherList[index] = {
                x: 0,
                y: 0,
                visible: false
              }
            }
          }

          otherList[sessionId] = vote
          otherList[sessionId].visible = true

          state.dataCurrentSession.votes.other = otherList
        } else {
          Object.assign(state.dataCurrentSession.votes.other[sessionId], vote, {
            visible: true
          })
        }
      }

      debug.session.info(state.dataCurrentSession.votes)
    },
    UPDATE_ID(state, value) {
      state.gameId = value
    },
    UPDATE_MONEY_TOTAL_PROFIT(state, value) {
      state.dataCurrentSession.totalProfitGame = value
    },
    UPDATE_MONEY_COUNT(state, value) {
      state.dataCurrentSession.money = value
    },
    UPDATE_TIME_COUNT(state, value) {
      state.dataCurrentSession.timeUnit = value
    },
    UPDATE_SESSION_MESSAGES_LIST(state, data) {
      state.messagesLibrary = data.news
      state.bonusMessages = data.bonus
    },
    UPDATE_RESULTS(state, { diagramData, tableData }) {
      state.resultDiagramData = diagramData
      state.resultTableData = tableData
    },
    UPDATE_SESSION_ACTIVE_MESSAGES(state, data) {
      debug.session.update('UPDATE_SESSION_ACTIVE_MESSAGES', data)
      state.newsMessages = data
    },
    UPDATE_SESSION_TIMER(state, time) {
      state.dataSessionTimer.time = time
    },
    UPDATE_SECTION_DATA(state, newSectionData) {
      let newData = { ...state.dataCurrentSession, ...newSectionData }
      state.dataCurrentSession = newData
    },
    SET_MODAL(state, { name, settings = {} }) {
      state.modal = { current: name, settings }
    },
    HIDE_MODAL(state) {
      state.modal = { current: null, settings: {} }
    },
    UPDATE_SESSION_CAPTAIN_STATUS(state, data) {
      state.dataCurrentSession.isCaptain = data
    },
    SET_CURRENT_PLAYER_NAME(state, data) {
      state.dataCurrentSession.currentPlayerNickname = data
    },
    SET_SESSION_PLAYER_DATA(
      state,
      { isCaptain, playerId, nickname, isInfoConsole }
    ) {
      state.dataCurrentSession.playerId = playerId
      state.dataCurrentSession.isCaptain = isCaptain
      state.dataCurrentSession.currentPlayerNickname = nickname
      state.dataCurrentSession.isInfoConsole = isInfoConsole
    },
    UPDATE_BASE_URL(state, data) {
      state.baseUrl = data
    },

    SET_INITIALIZATION_STATUS(state, value) {
      debug.session.storage('SET_INITIALIZATION_STATUS', value)
      state.initializationStatus = value
    },
    SET_GAME_STATE(state, value) {
      debug.session.storage('SET_GAME_STATE', value)
      state.gameState = value
    },
    SET_STATISTIC(state, value) {
      state.statistic = value
    },
    SET_CURRENT_SUB_PAGE(state, subPage) {
      debug.session.storage('SET_CURRENT_SUB_PAGE', subPage)
      navigationService.set(subPage)
      state.currentSubPage = subPage
      state.notificationPoints[subPage] = false
      notificationsPinResetLog.resetPin(
        subPage,
        state.dataCurrentSession.step,
        state.gameState
      )
    },
    SET_NOTIFICATION_POINTS(state, { key, value }) {
      debug.session.storage('SET_NOTIFICATION_POINTS', key, value)
      Object.assign(state.notificationPoints, { [key]: value })
    },

    SET_GAMEBOARD_DATA(state, data) {
      debug.session.storage('SET_GAMEBOARD_DATA', data)
      state.gameBoard = data
    },

    SET_GAMEBOARD_MARKINGS_STATUSES(state, data) {
      debug.session.storage('SET_GAMEBOARD_MARKINGS_STATUSES', data)

      if (!state.gameBoard.infrastructure) {
        return
      }

      Object.keys(state.gameBoard.infrastructure).forEach(key => {
        if (key in data) {
          Vue.set(state.gameBoard.infrastructure[key], 'status', data[key])
        }
      })
    },

    UPDATE_SESSION_NAME(state, data) {
      state.sessionName = data
    },
    UPDATE_PHASE_INFO_LIST(state, data) {
      state.phaseInfo = data
    },
    UPDATE_BONUS_TABLE(state, data) {
      state.bonusTable = data
    },
    UPDATE_TRAINER_SLIDES(state, data) {
      debug.session.update('UPDATE_TRAINER_SLIDES', data)

      const attachBaseUrl = obj => {
        return obj
          ? Object.fromEntries(
              Object.entries(obj).map(([localeKey, partialUrl]) => [
                localeKey,
                partialUrl ? `${state.baseUrl}${partialUrl}` : null
              ])
            )
          : {}
      }

      data.slides.forEach(element => {
        element.image_url = attachBaseUrl(element.image_url)
        element.video_url = attachBaseUrl(element.video_url)
      })

      state.trainerSlides = data.slides
    },
    UPDATE_TRAINER_WINDOW(state, data) {
      debug.session.update('UPDATE_TRAINER_WINDOW', data)
      state.trainerWindow = {
        type: data.type,
        slide: data.slide,
        show: data.show
      }
    },
    UPDATE_PLAYER_LIST(state, players) {
      state.team.players = players
    },
    SET_TEAM(state, team) {
      state.team = team
    },
    SET_SHARING_SOC_APPS(state, socAppSlugs) {
      state.sharing = socAppSlugs
    },
    SET_BRANDING(state, branding) {
      state.branding.type = branding.type
      state.branding.company_logo = branding.company_logo
      state.branding.company_title = branding.company_title
    },
    SET_SLIDES_VISIBILITY(state, type) {
      state.slidesVisibility = type
    },
    SET_GAME_ID(state, value) {
      state.gameId = value
    },
    SET_CHAT_STATE(state, value) {
      state.hasChat = !!value
    },
    SET_CURRENCY(state, value) {
      state.currency = value
    },
    SET_CURRENCY_DISPLAY_MODE(state, value) {
      state.currencyDisplayMode = value || CURRENCY_DISPLAY_MODES.EVERYWHERE
    }
  }
}
