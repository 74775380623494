export const compareNumbers = (a, b) => a - b

export const compareStrings = (a, b) => (a === b ? 0 : (a < b && -1) || 1)

export const compareByField =
  (field, compareFunction = compareStrings) =>
  (aObj, bObj) => {
    return compareFunction(aObj[field], bObj[field])
  }

export const invertCompareFunction = compareFunction => (a, b) =>
  -1 * compareFunction(a, b)

export const compareByMany =
  (...compareFunctions) =>
  (a, b) => {
    let result = 0

    for (let i = 0; result === 0 && i < compareFunctions.length; i++) {
      const compareFunction = compareFunctions[i]
      result = compareFunction(a, b)
    }

    return result
  }
