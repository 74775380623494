<template>
  <div class="footer-wrap" :class="{ 'footer-wrap--transparent': transparent }">
    <div
      v-show="gameState !== 'rewarding'"
      :class="{
        footer: allGameFooter,
        'finish-game-footer': !allGameFooter
      }"
    >
      <div class="footer__left-part">
        <img height="70" src="../assets/footer/left-part.png" alt="" />
        <div
          v-if="room && enabled"
          class="footer__chat-btn"
          :class="{
            'footer__chat-btn-disabled': !canPublish || muted || !connected
          }"
          @click="toggleMute"
        >
          <div class="footer__chat-btn-icon"></div>
        </div>

        <button
          v-if="room"
          class="footer__audio-chat-icon footer__active-btn"
          @click="toggleChatEnabled"
        >
          <img v-if="enabled" src="../assets/footer/sound-on.svg" />
          <img v-else src="../assets/footer/sound-off.svg" />
        </button>
        <FooterInfoBlockComponent class="footer__info-block" />
      </div>
      <img
        class="footer__wires"
        height="80"
        width="58"
        src="../assets/footer/wires.png"
        alt=""
      />
      <div class="footer__right-part">
        <img
          class="footer__first"
          src="../assets/footer/left-long-part.png"
          alt=""
        />
        <div class="footer__middle">
          <img src="../assets/footer/middle-long-part.png" alt="" />
        </div>
        <div class="footer__last-part">
          <img
            class="footer__last"
            src="../assets/footer/right-long-part.png"
            alt=""
            dir="ltr"
          />
          <div
            class="footer__btn"
            :class="getFooterButtonModificatorClasses('footer__btn')"
            @click="chooseCards"
          >
            <div
              class="footer__btn_border"
              :class="getFooterButtonModificatorClasses('footer__btn_border')"
            >
              {{ choiceIsDone ? $lui('footer.revert') : $lui('footer.send') }}
            </div>
          </div>
        </div>
      </div>
      <CardsInHand
        :class="currentSubPage == 'cards' ? 'open' : ''"
        class="footer__cards-in-hand"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FooterInfoBlockComponent from '../components/FooterInfoBlockComponent.vue'
import CardsInHand from './Cards/CardsContainerInHand.vue'

const widthLeftPartFootersLongLine = 126
const widthRightPartFootersLongLine = 347
const widthMiddlePartFootersLongLine = 162

export default {
  name: 'FooterComponent',
  components: {
    FooterInfoBlockComponent,
    CardsInHand
  },

  props: {
    // eslint-disable-next-line vue/no-boolean-default
    allGameFooter: { type: Boolean, default: true },
    transparent: { type: Boolean, default: false }
  },

  data() {
    return {
      LongPart_MiddleElementWidth:
        screen.width -
        widthLeftPartFootersLongLine -
        widthRightPartFootersLongLine -
        widthMiddlePartFootersLongLine +
        'px',

      playChoicePending: false
    }
  },

  computed: mapGetters({
    gameState: 'session/gameState',
    currentSubPage: 'session/currentSubPage',
    cardsInHand: 'cards/UICardsInHand',
    choiceIsDone: 'cards/choiceIsDone',
    isCaptain: 'session/getIsCaptain',
    canPublish: 'audioChat/canPublish',
    room: 'audioChat/currentRoom',
    muted: 'audioChat/muted',
    connected: 'audioChat/connected',
    enabled: 'audioChat/enabled'
  }),

  created() {
    window.addEventListener('resize', this.resizeHandler)
    this.LongPart_SetMiddleElementWidth()
  },

  unmounted() {
    window.removeEventListener('resize', this.resizeHandler)
  },

  methods: {
    resizeHandler() {
      this.LongPart_SetMiddleElementWidth()
    },

    LongPart_SetMiddleElementWidth() {
      if (window) {
        this.LongPart_MiddleElementWidth =
          window.innerWidth -
          widthLeftPartFootersLongLine -
          widthRightPartFootersLongLine -
          widthMiddlePartFootersLongLine +
          'px'
      } else {
        this.LongPart_MiddleElementWidth = '100px'
      }
    },

    getFooterButtonModificatorClasses(baseClass) {
      const {
        isCaptain,
        cardsInHand,
        gameState,
        choiceIsDone,
        playChoicePending
      } = this

      return {
        [`${baseClass}_red`]:
          isCaptain &&
          cardsInHand.length > 0 &&
          gameState == 'phase_action' &&
          choiceIsDone,

        [`${baseClass}__enabled`]:
          isCaptain &&
          cardsInHand.length > 0 &&
          gameState == 'phase_action' &&
          !choiceIsDone,

        [`${baseClass}--pending`]: playChoicePending
      }
    },

    async chooseCards() {
      if (
        this.isCaptain &&
        this.cardsInHand.length > 0 &&
        this.gameState === 'phase_action' &&
        this.playChoicePending === false
      ) {
        try {
          this.playChoicePending = true

          await this.$store.dispatch(
            'cards/setChoiceIsDone',
            !this.choiceIsDone
          )
        } finally {
          this.playChoicePending = false
        }
      }
    },

    toggleMute() {
      if (this.canPublish) {
        this.$store.dispatch('audioChat/toggleMute')
      }
    },

    toggleChatEnabled() {
      this.$store.dispatch('audioChat/toggleEnabled')
    }
  }
}
</script>

<style lang="scss">
.footer-wrap {
  @media screen and (max-width: 1551px) {
    height: 83px !important;
    background: linear-gradient(360deg, #000000 7.87%, rgba(0, 0, 0, 0) 100%);
    margin-top: 0;
  }
}

$b: '.footer';

#{$b} {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 67px;
  z-index: 200;
  width: 100%;

  &__audio-chat-icon {
    display: flex;
    padding: 0;
    outline: none;
    justify-content: center;
    align-items: center;

    img {
      visibility: visible !important;
      display: block;
      margin-top: -5px;
      margin-left: -4px;
    }
  }

  &__active-btn {
    position: absolute;
    top: 20px;
    left: 12px;
    width: 47px;
    height: 47px;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    background-image: url(../assets/footer/footer-active-btn.png);
    background-size: 44px 44px;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  &__chat-btn {
    position: absolute;
    top: 20px;
    left: 60px;
    width: 47px;
    height: 47px;
    background-image: url(../assets/header/header-active-btn.png);
    background-size: 44px 44px;
    background-repeat: no-repeat;

    &-disabled {
      .footer__chat-btn-icon {
        background-image: url(../assets/mic-off.svg);
        transform: translate(-73%, -60%);
      }
    }

    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-58%, -60%);
      width: 20px;
      height: 20px;
      background-image: url(../assets/footer/microphone.svg);
      background-repeat: no-repeat;
    }

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }

  &__left-part {
    z-index: 2;
    position: relative;
  }

  &__right-part {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 67px;
    margin-left: -19px;
    z-index: 1;
    width: 100%;
  }

  &__info-block {
    position: absolute;
    top: 20%;
    left: 27%;
    width: 267px;
    height: 51px;
  }

  &__wires {
    margin: 40px 0 0 -19px;
    position: relative;
    top: 42%;
    z-index: 0;
  }

  &__last {
    &[dir='ltr'] {
      transform: scaleX(1) !important;
    }

    &-part {
      position: relative;
    }
  }

  &__btn {
    position: absolute;
    top: 17%;
    right: 4%;
    width: 143px;
    height: 46px;
    margin-bottom: 6px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: white;
    transition: opacity 0.5s ease;
    background: #9d9fa2;
    border: 2px solid #000000;
    border-radius: 50px;
    padding: 3px;
    opacity: 1;

    &:hover,
    &:focus {
      opacity: 0.8;
      transition: opacity 0.5s ease;
    }

    &_red {
      background: #f51d21 !important;
      cursor: pointer;
    }

    &__enabled {
      background: #598961;
      cursor: pointer;
    }

    &--pending {
      filter: grayscale(80%);
      cursor: default;
    }

    &_border {
      background: #9d9fa2;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border: 1px solid #000000;
      border-radius: 50px;
      box-shadow: inset -5px -6px 0px #d0d1d2, inset 5px 5px 0px #6c6c6c;

      &_red {
        background: #f51d21 !important;
        box-shadow: inset -5px -6px 0px #fc676a, inset 5px 5px 0px #c2181b !important;
      }

      &__enabled {
        background: #598961;
        box-shadow: inset -5px -6px 0px #34694b, inset 5px 5px 0px #6fa277;
      }
    }
  }

  &__first,
  &__middle img,
  &__last {
    height: 50px;
  }

  &__last {
    height: 60px;
    justify-content: flex-end;
  }

  &__first {
    justify-content: start;
  }

  &__middle img,
  &__middle {
    width: 100%;
  }

  &__middle {
    margin-left: -5px;
    margin-right: -5px;
  }

  &__cards-in-hand {
    position: absolute;
    top: -15px;
    left: 465px;
    z-index: 1;
    opacity: 0;

    &.open {
      transition: opacity 0.3s ease-in;
      opacity: 1;
    }
  }
}

.finish-game-footer {
  display: none;
}

.footer-wrap--transparent {
  background: none;
  .footer__wires,
  .footer__right-part,
  .footer-info-block {
    display: none;
  }

  .footer__left-part img {
    visibility: hidden;
  }
}
</style>
